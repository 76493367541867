@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/*font  sizes*/
/*backgroud colors*/
/*sky*/
/*grey*/
/*light blue*/
/*
    This is for margin and padding
    eg.
    .m-0 to 35{margin:0px to 35px;}  
    .p-0 to 35{padding:0px to 35px;}  

    t for top
    "": "", // for all
    t for "top",
    b for "bottom",
    l for "left",
    r for "right",

    .pt-30{padding-top: 30px;}
    .mb-30{margin-bottom: 30px;}
*/
@import url(~@ng-select/ng-select/themes/default.theme.css);
@import url(~ngx-toastr/toastr.css);
.m-0 {
  margin: 0px; }

.p-0 {
  padding: 0px; }

.m-1 {
  margin: 1px; }

.p-1 {
  padding: 1px; }

.m-2 {
  margin: 2px; }

.p-2 {
  padding: 2px; }

.m-3 {
  margin: 3px; }

.p-3 {
  padding: 3px; }

.m-4 {
  margin: 4px; }

.p-4 {
  padding: 4px; }

.m-5 {
  margin: 5px; }

.p-5 {
  padding: 5px; }

.m-6 {
  margin: 6px; }

.p-6 {
  padding: 6px; }

.m-7 {
  margin: 7px; }

.p-7 {
  padding: 7px; }

.m-8 {
  margin: 8px; }

.p-8 {
  padding: 8px; }

.m-9 {
  margin: 9px; }

.p-9 {
  padding: 9px; }

.m-10 {
  margin: 10px; }

.p-10 {
  padding: 10px; }

.m-11 {
  margin: 11px; }

.p-11 {
  padding: 11px; }

.m-12 {
  margin: 12px; }

.p-12 {
  padding: 12px; }

.m-13 {
  margin: 13px; }

.p-13 {
  padding: 13px; }

.m-14 {
  margin: 14px; }

.p-14 {
  padding: 14px; }

.m-15 {
  margin: 15px; }

.p-15 {
  padding: 15px; }

.m-16 {
  margin: 16px; }

.p-16 {
  padding: 16px; }

.m-17 {
  margin: 17px; }

.p-17 {
  padding: 17px; }

.m-18 {
  margin: 18px; }

.p-18 {
  padding: 18px; }

.m-19 {
  margin: 19px; }

.p-19 {
  padding: 19px; }

.m-20 {
  margin: 20px; }

.p-20 {
  padding: 20px; }

.m-21 {
  margin: 21px; }

.p-21 {
  padding: 21px; }

.m-22 {
  margin: 22px; }

.p-22 {
  padding: 22px; }

.m-23 {
  margin: 23px; }

.p-23 {
  padding: 23px; }

.m-24 {
  margin: 24px; }

.p-24 {
  padding: 24px; }

.m-25 {
  margin: 25px; }

.p-25 {
  padding: 25px; }

.m-26 {
  margin: 26px; }

.p-26 {
  padding: 26px; }

.m-27 {
  margin: 27px; }

.p-27 {
  padding: 27px; }

.m-28 {
  margin: 28px; }

.p-28 {
  padding: 28px; }

.m-29 {
  margin: 29px; }

.p-29 {
  padding: 29px; }

.m-30 {
  margin: 30px; }

.p-30 {
  padding: 30px; }

.m-31 {
  margin: 31px; }

.p-31 {
  padding: 31px; }

.m-32 {
  margin: 32px; }

.p-32 {
  padding: 32px; }

.m-33 {
  margin: 33px; }

.p-33 {
  padding: 33px; }

.m-34 {
  margin: 34px; }

.p-34 {
  padding: 34px; }

.m-35 {
  margin: 35px; }

.p-35 {
  padding: 35px; }

.m-36 {
  margin: 36px; }

.p-36 {
  padding: 36px; }

.m-37 {
  margin: 37px; }

.p-37 {
  padding: 37px; }

.m-38 {
  margin: 38px; }

.p-38 {
  padding: 38px; }

.m-39 {
  margin: 39px; }

.p-39 {
  padding: 39px; }

.m-40 {
  margin: 40px; }

.p-40 {
  padding: 40px; }

.m-41 {
  margin: 41px; }

.p-41 {
  padding: 41px; }

.m-42 {
  margin: 42px; }

.p-42 {
  padding: 42px; }

.m-43 {
  margin: 43px; }

.p-43 {
  padding: 43px; }

.m-44 {
  margin: 44px; }

.p-44 {
  padding: 44px; }

.m-45 {
  margin: 45px; }

.p-45 {
  padding: 45px; }

.m-46 {
  margin: 46px; }

.p-46 {
  padding: 46px; }

.m-47 {
  margin: 47px; }

.p-47 {
  padding: 47px; }

.m-48 {
  margin: 48px; }

.p-48 {
  padding: 48px; }

.m-49 {
  margin: 49px; }

.p-49 {
  padding: 49px; }

.m-50 {
  margin: 50px; }

.p-50 {
  padding: 50px; }

.mt-0 {
  margin-top: 0px; }

.pt-0 {
  padding-top: 0px; }

.mt-1 {
  margin-top: 1px; }

.pt-1 {
  padding-top: 1px; }

.mt-2 {
  margin-top: 2px; }

.pt-2 {
  padding-top: 2px; }

.mt-3 {
  margin-top: 3px; }

.pt-3 {
  padding-top: 3px; }

.mt-4 {
  margin-top: 4px; }

.pt-4 {
  padding-top: 4px; }

.mt-5 {
  margin-top: 5px; }

.pt-5 {
  padding-top: 5px; }

.mt-6 {
  margin-top: 6px; }

.pt-6 {
  padding-top: 6px; }

.mt-7 {
  margin-top: 7px; }

.pt-7 {
  padding-top: 7px; }

.mt-8 {
  margin-top: 8px; }

.pt-8 {
  padding-top: 8px; }

.mt-9 {
  margin-top: 9px; }

.pt-9 {
  padding-top: 9px; }

.mt-10 {
  margin-top: 10px; }

.pt-10 {
  padding-top: 10px; }

.mt-11 {
  margin-top: 11px; }

.pt-11 {
  padding-top: 11px; }

.mt-12 {
  margin-top: 12px; }

.pt-12 {
  padding-top: 12px; }

.mt-13 {
  margin-top: 13px; }

.pt-13 {
  padding-top: 13px; }

.mt-14 {
  margin-top: 14px; }

.pt-14 {
  padding-top: 14px; }

.mt-15 {
  margin-top: 15px; }

.pt-15 {
  padding-top: 15px; }

.mt-16 {
  margin-top: 16px; }

.pt-16 {
  padding-top: 16px; }

.mt-17 {
  margin-top: 17px; }

.pt-17 {
  padding-top: 17px; }

.mt-18 {
  margin-top: 18px; }

.pt-18 {
  padding-top: 18px; }

.mt-19 {
  margin-top: 19px; }

.pt-19 {
  padding-top: 19px; }

.mt-20 {
  margin-top: 20px; }

.pt-20 {
  padding-top: 20px; }

.mt-21 {
  margin-top: 21px; }

.pt-21 {
  padding-top: 21px; }

.mt-22 {
  margin-top: 22px; }

.pt-22 {
  padding-top: 22px; }

.mt-23 {
  margin-top: 23px; }

.pt-23 {
  padding-top: 23px; }

.mt-24 {
  margin-top: 24px; }

.pt-24 {
  padding-top: 24px; }

.mt-25 {
  margin-top: 25px; }

.pt-25 {
  padding-top: 25px; }

.mt-26 {
  margin-top: 26px; }

.pt-26 {
  padding-top: 26px; }

.mt-27 {
  margin-top: 27px; }

.pt-27 {
  padding-top: 27px; }

.mt-28 {
  margin-top: 28px; }

.pt-28 {
  padding-top: 28px; }

.mt-29 {
  margin-top: 29px; }

.pt-29 {
  padding-top: 29px; }

.mt-30 {
  margin-top: 30px; }

.pt-30 {
  padding-top: 30px; }

.mt-31 {
  margin-top: 31px; }

.pt-31 {
  padding-top: 31px; }

.mt-32 {
  margin-top: 32px; }

.pt-32 {
  padding-top: 32px; }

.mt-33 {
  margin-top: 33px; }

.pt-33 {
  padding-top: 33px; }

.mt-34 {
  margin-top: 34px; }

.pt-34 {
  padding-top: 34px; }

.mt-35 {
  margin-top: 35px; }

.pt-35 {
  padding-top: 35px; }

.mt-36 {
  margin-top: 36px; }

.pt-36 {
  padding-top: 36px; }

.mt-37 {
  margin-top: 37px; }

.pt-37 {
  padding-top: 37px; }

.mt-38 {
  margin-top: 38px; }

.pt-38 {
  padding-top: 38px; }

.mt-39 {
  margin-top: 39px; }

.pt-39 {
  padding-top: 39px; }

.mt-40 {
  margin-top: 40px; }

.pt-40 {
  padding-top: 40px; }

.mt-41 {
  margin-top: 41px; }

.pt-41 {
  padding-top: 41px; }

.mt-42 {
  margin-top: 42px; }

.pt-42 {
  padding-top: 42px; }

.mt-43 {
  margin-top: 43px; }

.pt-43 {
  padding-top: 43px; }

.mt-44 {
  margin-top: 44px; }

.pt-44 {
  padding-top: 44px; }

.mt-45 {
  margin-top: 45px; }

.pt-45 {
  padding-top: 45px; }

.mt-46 {
  margin-top: 46px; }

.pt-46 {
  padding-top: 46px; }

.mt-47 {
  margin-top: 47px; }

.pt-47 {
  padding-top: 47px; }

.mt-48 {
  margin-top: 48px; }

.pt-48 {
  padding-top: 48px; }

.mt-49 {
  margin-top: 49px; }

.pt-49 {
  padding-top: 49px; }

.mt-50 {
  margin-top: 50px; }

.pt-50 {
  padding-top: 50px; }

.mb-0 {
  margin-bottom: 0px; }

.pb-0 {
  padding-bottom: 0px; }

.mb-1 {
  margin-bottom: 1px; }

.pb-1 {
  padding-bottom: 1px; }

.mb-2 {
  margin-bottom: 2px; }

.pb-2 {
  padding-bottom: 2px; }

.mb-3 {
  margin-bottom: 3px; }

.pb-3 {
  padding-bottom: 3px; }

.mb-4 {
  margin-bottom: 4px; }

.pb-4 {
  padding-bottom: 4px; }

.mb-5 {
  margin-bottom: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.mb-6 {
  margin-bottom: 6px; }

.pb-6 {
  padding-bottom: 6px; }

.mb-7 {
  margin-bottom: 7px; }

.pb-7 {
  padding-bottom: 7px; }

.mb-8 {
  margin-bottom: 8px; }

.pb-8 {
  padding-bottom: 8px; }

.mb-9 {
  margin-bottom: 9px; }

.pb-9 {
  padding-bottom: 9px; }

.mb-10 {
  margin-bottom: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.mb-11 {
  margin-bottom: 11px; }

.pb-11 {
  padding-bottom: 11px; }

.mb-12 {
  margin-bottom: 12px; }

.pb-12 {
  padding-bottom: 12px; }

.mb-13 {
  margin-bottom: 13px; }

.pb-13 {
  padding-bottom: 13px; }

.mb-14 {
  margin-bottom: 14px; }

.pb-14 {
  padding-bottom: 14px; }

.mb-15 {
  margin-bottom: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.mb-16 {
  margin-bottom: 16px; }

.pb-16 {
  padding-bottom: 16px; }

.mb-17 {
  margin-bottom: 17px; }

.pb-17 {
  padding-bottom: 17px; }

.mb-18 {
  margin-bottom: 18px; }

.pb-18 {
  padding-bottom: 18px; }

.mb-19 {
  margin-bottom: 19px; }

.pb-19 {
  padding-bottom: 19px; }

.mb-20 {
  margin-bottom: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.mb-21 {
  margin-bottom: 21px; }

.pb-21 {
  padding-bottom: 21px; }

.mb-22 {
  margin-bottom: 22px; }

.pb-22 {
  padding-bottom: 22px; }

.mb-23 {
  margin-bottom: 23px; }

.pb-23 {
  padding-bottom: 23px; }

.mb-24 {
  margin-bottom: 24px; }

.pb-24 {
  padding-bottom: 24px; }

.mb-25 {
  margin-bottom: 25px; }

.pb-25 {
  padding-bottom: 25px; }

.mb-26 {
  margin-bottom: 26px; }

.pb-26 {
  padding-bottom: 26px; }

.mb-27 {
  margin-bottom: 27px; }

.pb-27 {
  padding-bottom: 27px; }

.mb-28 {
  margin-bottom: 28px; }

.pb-28 {
  padding-bottom: 28px; }

.mb-29 {
  margin-bottom: 29px; }

.pb-29 {
  padding-bottom: 29px; }

.mb-30 {
  margin-bottom: 30px; }

.pb-30 {
  padding-bottom: 30px; }

.mb-31 {
  margin-bottom: 31px; }

.pb-31 {
  padding-bottom: 31px; }

.mb-32 {
  margin-bottom: 32px; }

.pb-32 {
  padding-bottom: 32px; }

.mb-33 {
  margin-bottom: 33px; }

.pb-33 {
  padding-bottom: 33px; }

.mb-34 {
  margin-bottom: 34px; }

.pb-34 {
  padding-bottom: 34px; }

.mb-35 {
  margin-bottom: 35px; }

.pb-35 {
  padding-bottom: 35px; }

.mb-36 {
  margin-bottom: 36px; }

.pb-36 {
  padding-bottom: 36px; }

.mb-37 {
  margin-bottom: 37px; }

.pb-37 {
  padding-bottom: 37px; }

.mb-38 {
  margin-bottom: 38px; }

.pb-38 {
  padding-bottom: 38px; }

.mb-39 {
  margin-bottom: 39px; }

.pb-39 {
  padding-bottom: 39px; }

.mb-40 {
  margin-bottom: 40px; }

.pb-40 {
  padding-bottom: 40px; }

.mb-41 {
  margin-bottom: 41px; }

.pb-41 {
  padding-bottom: 41px; }

.mb-42 {
  margin-bottom: 42px; }

.pb-42 {
  padding-bottom: 42px; }

.mb-43 {
  margin-bottom: 43px; }

.pb-43 {
  padding-bottom: 43px; }

.mb-44 {
  margin-bottom: 44px; }

.pb-44 {
  padding-bottom: 44px; }

.mb-45 {
  margin-bottom: 45px; }

.pb-45 {
  padding-bottom: 45px; }

.mb-46 {
  margin-bottom: 46px; }

.pb-46 {
  padding-bottom: 46px; }

.mb-47 {
  margin-bottom: 47px; }

.pb-47 {
  padding-bottom: 47px; }

.mb-48 {
  margin-bottom: 48px; }

.pb-48 {
  padding-bottom: 48px; }

.mb-49 {
  margin-bottom: 49px; }

.pb-49 {
  padding-bottom: 49px; }

.mb-50 {
  margin-bottom: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.ml-0 {
  margin-left: 0px; }

.pl-0 {
  padding-left: 0px; }

.ml-1 {
  margin-left: 1px; }

.pl-1 {
  padding-left: 1px; }

.ml-2 {
  margin-left: 2px; }

.pl-2 {
  padding-left: 2px; }

.ml-3 {
  margin-left: 3px; }

.pl-3 {
  padding-left: 3px; }

.ml-4 {
  margin-left: 4px; }

.pl-4 {
  padding-left: 4px; }

.ml-5 {
  margin-left: 5px; }

.pl-5 {
  padding-left: 5px; }

.ml-6 {
  margin-left: 6px; }

.pl-6 {
  padding-left: 6px; }

.ml-7 {
  margin-left: 7px; }

.pl-7 {
  padding-left: 7px; }

.ml-8 {
  margin-left: 8px; }

.pl-8 {
  padding-left: 8px; }

.ml-9 {
  margin-left: 9px; }

.pl-9 {
  padding-left: 9px; }

.ml-10 {
  margin-left: 10px; }

.pl-10 {
  padding-left: 10px; }

.ml-11 {
  margin-left: 11px; }

.pl-11 {
  padding-left: 11px; }

.ml-12 {
  margin-left: 12px; }

.pl-12 {
  padding-left: 12px; }

.ml-13 {
  margin-left: 13px; }

.pl-13 {
  padding-left: 13px; }

.ml-14 {
  margin-left: 14px; }

.pl-14 {
  padding-left: 14px; }

.ml-15 {
  margin-left: 15px; }

.pl-15 {
  padding-left: 15px; }

.ml-16 {
  margin-left: 16px; }

.pl-16 {
  padding-left: 16px; }

.ml-17 {
  margin-left: 17px; }

.pl-17 {
  padding-left: 17px; }

.ml-18 {
  margin-left: 18px; }

.pl-18 {
  padding-left: 18px; }

.ml-19 {
  margin-left: 19px; }

.pl-19 {
  padding-left: 19px; }

.ml-20 {
  margin-left: 20px; }

.pl-20 {
  padding-left: 20px; }

.ml-21 {
  margin-left: 21px; }

.pl-21 {
  padding-left: 21px; }

.ml-22 {
  margin-left: 22px; }

.pl-22 {
  padding-left: 22px; }

.ml-23 {
  margin-left: 23px; }

.pl-23 {
  padding-left: 23px; }

.ml-24 {
  margin-left: 24px; }

.pl-24 {
  padding-left: 24px; }

.ml-25 {
  margin-left: 25px; }

.pl-25 {
  padding-left: 25px; }

.ml-26 {
  margin-left: 26px; }

.pl-26 {
  padding-left: 26px; }

.ml-27 {
  margin-left: 27px; }

.pl-27 {
  padding-left: 27px; }

.ml-28 {
  margin-left: 28px; }

.pl-28 {
  padding-left: 28px; }

.ml-29 {
  margin-left: 29px; }

.pl-29 {
  padding-left: 29px; }

.ml-30 {
  margin-left: 30px; }

.pl-30 {
  padding-left: 30px; }

.ml-31 {
  margin-left: 31px; }

.pl-31 {
  padding-left: 31px; }

.ml-32 {
  margin-left: 32px; }

.pl-32 {
  padding-left: 32px; }

.ml-33 {
  margin-left: 33px; }

.pl-33 {
  padding-left: 33px; }

.ml-34 {
  margin-left: 34px; }

.pl-34 {
  padding-left: 34px; }

.ml-35 {
  margin-left: 35px; }

.pl-35 {
  padding-left: 35px; }

.ml-36 {
  margin-left: 36px; }

.pl-36 {
  padding-left: 36px; }

.ml-37 {
  margin-left: 37px; }

.pl-37 {
  padding-left: 37px; }

.ml-38 {
  margin-left: 38px; }

.pl-38 {
  padding-left: 38px; }

.ml-39 {
  margin-left: 39px; }

.pl-39 {
  padding-left: 39px; }

.ml-40 {
  margin-left: 40px; }

.pl-40 {
  padding-left: 40px; }

.ml-41 {
  margin-left: 41px; }

.pl-41 {
  padding-left: 41px; }

.ml-42 {
  margin-left: 42px; }

.pl-42 {
  padding-left: 42px; }

.ml-43 {
  margin-left: 43px; }

.pl-43 {
  padding-left: 43px; }

.ml-44 {
  margin-left: 44px; }

.pl-44 {
  padding-left: 44px; }

.ml-45 {
  margin-left: 45px; }

.pl-45 {
  padding-left: 45px; }

.ml-46 {
  margin-left: 46px; }

.pl-46 {
  padding-left: 46px; }

.ml-47 {
  margin-left: 47px; }

.pl-47 {
  padding-left: 47px; }

.ml-48 {
  margin-left: 48px; }

.pl-48 {
  padding-left: 48px; }

.ml-49 {
  margin-left: 49px; }

.pl-49 {
  padding-left: 49px; }

.ml-50 {
  margin-left: 50px; }

.pl-50 {
  padding-left: 50px; }

.mr-0 {
  margin-right: 0px; }

.pr-0 {
  padding-right: 0px; }

.mr-1 {
  margin-right: 1px; }

.pr-1 {
  padding-right: 1px; }

.mr-2 {
  margin-right: 2px; }

.pr-2 {
  padding-right: 2px; }

.mr-3 {
  margin-right: 3px; }

.pr-3 {
  padding-right: 3px; }

.mr-4 {
  margin-right: 4px; }

.pr-4 {
  padding-right: 4px; }

.mr-5 {
  margin-right: 5px; }

.pr-5 {
  padding-right: 5px; }

.mr-6 {
  margin-right: 6px; }

.pr-6 {
  padding-right: 6px; }

.mr-7 {
  margin-right: 7px; }

.pr-7 {
  padding-right: 7px; }

.mr-8 {
  margin-right: 8px; }

.pr-8 {
  padding-right: 8px; }

.mr-9 {
  margin-right: 9px; }

.pr-9 {
  padding-right: 9px; }

.mr-10 {
  margin-right: 10px; }

.pr-10 {
  padding-right: 10px; }

.mr-11 {
  margin-right: 11px; }

.pr-11 {
  padding-right: 11px; }

.mr-12 {
  margin-right: 12px; }

.pr-12 {
  padding-right: 12px; }

.mr-13 {
  margin-right: 13px; }

.pr-13 {
  padding-right: 13px; }

.mr-14 {
  margin-right: 14px; }

.pr-14 {
  padding-right: 14px; }

.mr-15 {
  margin-right: 15px; }

.pr-15 {
  padding-right: 15px; }

.mr-16 {
  margin-right: 16px; }

.pr-16 {
  padding-right: 16px; }

.mr-17 {
  margin-right: 17px; }

.pr-17 {
  padding-right: 17px; }

.mr-18 {
  margin-right: 18px; }

.pr-18 {
  padding-right: 18px; }

.mr-19 {
  margin-right: 19px; }

.pr-19 {
  padding-right: 19px; }

.mr-20 {
  margin-right: 20px; }

.pr-20 {
  padding-right: 20px; }

.mr-21 {
  margin-right: 21px; }

.pr-21 {
  padding-right: 21px; }

.mr-22 {
  margin-right: 22px; }

.pr-22 {
  padding-right: 22px; }

.mr-23 {
  margin-right: 23px; }

.pr-23 {
  padding-right: 23px; }

.mr-24 {
  margin-right: 24px; }

.pr-24 {
  padding-right: 24px; }

.mr-25 {
  margin-right: 25px; }

.pr-25 {
  padding-right: 25px; }

.mr-26 {
  margin-right: 26px; }

.pr-26 {
  padding-right: 26px; }

.mr-27 {
  margin-right: 27px; }

.pr-27 {
  padding-right: 27px; }

.mr-28 {
  margin-right: 28px; }

.pr-28 {
  padding-right: 28px; }

.mr-29 {
  margin-right: 29px; }

.pr-29 {
  padding-right: 29px; }

.mr-30 {
  margin-right: 30px; }

.pr-30 {
  padding-right: 30px; }

.mr-31 {
  margin-right: 31px; }

.pr-31 {
  padding-right: 31px; }

.mr-32 {
  margin-right: 32px; }

.pr-32 {
  padding-right: 32px; }

.mr-33 {
  margin-right: 33px; }

.pr-33 {
  padding-right: 33px; }

.mr-34 {
  margin-right: 34px; }

.pr-34 {
  padding-right: 34px; }

.mr-35 {
  margin-right: 35px; }

.pr-35 {
  padding-right: 35px; }

.mr-36 {
  margin-right: 36px; }

.pr-36 {
  padding-right: 36px; }

.mr-37 {
  margin-right: 37px; }

.pr-37 {
  padding-right: 37px; }

.mr-38 {
  margin-right: 38px; }

.pr-38 {
  padding-right: 38px; }

.mr-39 {
  margin-right: 39px; }

.pr-39 {
  padding-right: 39px; }

.mr-40 {
  margin-right: 40px; }

.pr-40 {
  padding-right: 40px; }

.mr-41 {
  margin-right: 41px; }

.pr-41 {
  padding-right: 41px; }

.mr-42 {
  margin-right: 42px; }

.pr-42 {
  padding-right: 42px; }

.mr-43 {
  margin-right: 43px; }

.pr-43 {
  padding-right: 43px; }

.mr-44 {
  margin-right: 44px; }

.pr-44 {
  padding-right: 44px; }

.mr-45 {
  margin-right: 45px; }

.pr-45 {
  padding-right: 45px; }

.mr-46 {
  margin-right: 46px; }

.pr-46 {
  padding-right: 46px; }

.mr-47 {
  margin-right: 47px; }

.pr-47 {
  padding-right: 47px; }

.mr-48 {
  margin-right: 48px; }

.pr-48 {
  padding-right: 48px; }

.mr-49 {
  margin-right: 49px; }

.pr-49 {
  padding-right: 49px; }

.mr-50 {
  margin-right: 50px; }

.pr-50 {
  padding-right: 50px; }

@media only screen and (max-width: 576px) {
  .m-xs-0 {
    margin: 0px; }
  .p-xs-0 {
    padding: 0px; } }

@media only screen and (max-width: 576px) {
  .m-xs-1 {
    margin: 1px; }
  .p-xs-1 {
    padding: 1px; } }

@media only screen and (max-width: 576px) {
  .m-xs-2 {
    margin: 2px; }
  .p-xs-2 {
    padding: 2px; } }

@media only screen and (max-width: 576px) {
  .m-xs-3 {
    margin: 3px; }
  .p-xs-3 {
    padding: 3px; } }

@media only screen and (max-width: 576px) {
  .m-xs-4 {
    margin: 4px; }
  .p-xs-4 {
    padding: 4px; } }

@media only screen and (max-width: 576px) {
  .m-xs-5 {
    margin: 5px; }
  .p-xs-5 {
    padding: 5px; } }

@media only screen and (max-width: 576px) {
  .m-xs-6 {
    margin: 6px; }
  .p-xs-6 {
    padding: 6px; } }

@media only screen and (max-width: 576px) {
  .m-xs-7 {
    margin: 7px; }
  .p-xs-7 {
    padding: 7px; } }

@media only screen and (max-width: 576px) {
  .m-xs-8 {
    margin: 8px; }
  .p-xs-8 {
    padding: 8px; } }

@media only screen and (max-width: 576px) {
  .m-xs-9 {
    margin: 9px; }
  .p-xs-9 {
    padding: 9px; } }

@media only screen and (max-width: 576px) {
  .m-xs-10 {
    margin: 10px; }
  .p-xs-10 {
    padding: 10px; } }

@media only screen and (max-width: 576px) {
  .m-xs-11 {
    margin: 11px; }
  .p-xs-11 {
    padding: 11px; } }

@media only screen and (max-width: 576px) {
  .m-xs-12 {
    margin: 12px; }
  .p-xs-12 {
    padding: 12px; } }

@media only screen and (max-width: 576px) {
  .m-xs-13 {
    margin: 13px; }
  .p-xs-13 {
    padding: 13px; } }

@media only screen and (max-width: 576px) {
  .m-xs-14 {
    margin: 14px; }
  .p-xs-14 {
    padding: 14px; } }

@media only screen and (max-width: 576px) {
  .m-xs-15 {
    margin: 15px; }
  .p-xs-15 {
    padding: 15px; } }

@media only screen and (max-width: 576px) {
  .m-xs-16 {
    margin: 16px; }
  .p-xs-16 {
    padding: 16px; } }

@media only screen and (max-width: 576px) {
  .m-xs-17 {
    margin: 17px; }
  .p-xs-17 {
    padding: 17px; } }

@media only screen and (max-width: 576px) {
  .m-xs-18 {
    margin: 18px; }
  .p-xs-18 {
    padding: 18px; } }

@media only screen and (max-width: 576px) {
  .m-xs-19 {
    margin: 19px; }
  .p-xs-19 {
    padding: 19px; } }

@media only screen and (max-width: 576px) {
  .m-xs-20 {
    margin: 20px; }
  .p-xs-20 {
    padding: 20px; } }

@media only screen and (max-width: 576px) {
  .m-xs-21 {
    margin: 21px; }
  .p-xs-21 {
    padding: 21px; } }

@media only screen and (max-width: 576px) {
  .m-xs-22 {
    margin: 22px; }
  .p-xs-22 {
    padding: 22px; } }

@media only screen and (max-width: 576px) {
  .m-xs-23 {
    margin: 23px; }
  .p-xs-23 {
    padding: 23px; } }

@media only screen and (max-width: 576px) {
  .m-xs-24 {
    margin: 24px; }
  .p-xs-24 {
    padding: 24px; } }

@media only screen and (max-width: 576px) {
  .m-xs-25 {
    margin: 25px; }
  .p-xs-25 {
    padding: 25px; } }

@media only screen and (max-width: 576px) {
  .m-xs-26 {
    margin: 26px; }
  .p-xs-26 {
    padding: 26px; } }

@media only screen and (max-width: 576px) {
  .m-xs-27 {
    margin: 27px; }
  .p-xs-27 {
    padding: 27px; } }

@media only screen and (max-width: 576px) {
  .m-xs-28 {
    margin: 28px; }
  .p-xs-28 {
    padding: 28px; } }

@media only screen and (max-width: 576px) {
  .m-xs-29 {
    margin: 29px; }
  .p-xs-29 {
    padding: 29px; } }

@media only screen and (max-width: 576px) {
  .m-xs-30 {
    margin: 30px; }
  .p-xs-30 {
    padding: 30px; } }

@media only screen and (max-width: 576px) {
  .m-xs-31 {
    margin: 31px; }
  .p-xs-31 {
    padding: 31px; } }

@media only screen and (max-width: 576px) {
  .m-xs-32 {
    margin: 32px; }
  .p-xs-32 {
    padding: 32px; } }

@media only screen and (max-width: 576px) {
  .m-xs-33 {
    margin: 33px; }
  .p-xs-33 {
    padding: 33px; } }

@media only screen and (max-width: 576px) {
  .m-xs-34 {
    margin: 34px; }
  .p-xs-34 {
    padding: 34px; } }

@media only screen and (max-width: 576px) {
  .m-xs-35 {
    margin: 35px; }
  .p-xs-35 {
    padding: 35px; } }

@media only screen and (max-width: 576px) {
  .m-xs-36 {
    margin: 36px; }
  .p-xs-36 {
    padding: 36px; } }

@media only screen and (max-width: 576px) {
  .m-xs-37 {
    margin: 37px; }
  .p-xs-37 {
    padding: 37px; } }

@media only screen and (max-width: 576px) {
  .m-xs-38 {
    margin: 38px; }
  .p-xs-38 {
    padding: 38px; } }

@media only screen and (max-width: 576px) {
  .m-xs-39 {
    margin: 39px; }
  .p-xs-39 {
    padding: 39px; } }

@media only screen and (max-width: 576px) {
  .m-xs-40 {
    margin: 40px; }
  .p-xs-40 {
    padding: 40px; } }

@media only screen and (max-width: 576px) {
  .m-xs-41 {
    margin: 41px; }
  .p-xs-41 {
    padding: 41px; } }

@media only screen and (max-width: 576px) {
  .m-xs-42 {
    margin: 42px; }
  .p-xs-42 {
    padding: 42px; } }

@media only screen and (max-width: 576px) {
  .m-xs-43 {
    margin: 43px; }
  .p-xs-43 {
    padding: 43px; } }

@media only screen and (max-width: 576px) {
  .m-xs-44 {
    margin: 44px; }
  .p-xs-44 {
    padding: 44px; } }

@media only screen and (max-width: 576px) {
  .m-xs-45 {
    margin: 45px; }
  .p-xs-45 {
    padding: 45px; } }

@media only screen and (max-width: 576px) {
  .m-xs-46 {
    margin: 46px; }
  .p-xs-46 {
    padding: 46px; } }

@media only screen and (max-width: 576px) {
  .m-xs-47 {
    margin: 47px; }
  .p-xs-47 {
    padding: 47px; } }

@media only screen and (max-width: 576px) {
  .m-xs-48 {
    margin: 48px; }
  .p-xs-48 {
    padding: 48px; } }

@media only screen and (max-width: 576px) {
  .m-xs-49 {
    margin: 49px; }
  .p-xs-49 {
    padding: 49px; } }

@media only screen and (max-width: 576px) {
  .m-xs-50 {
    margin: 50px; }
  .p-xs-50 {
    padding: 50px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-0 {
    margin-top: 0px; }
  .pt-xs-0 {
    padding-top: 0px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-1 {
    margin-top: 1px; }
  .pt-xs-1 {
    padding-top: 1px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-2 {
    margin-top: 2px; }
  .pt-xs-2 {
    padding-top: 2px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-3 {
    margin-top: 3px; }
  .pt-xs-3 {
    padding-top: 3px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-4 {
    margin-top: 4px; }
  .pt-xs-4 {
    padding-top: 4px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-5 {
    margin-top: 5px; }
  .pt-xs-5 {
    padding-top: 5px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-6 {
    margin-top: 6px; }
  .pt-xs-6 {
    padding-top: 6px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-7 {
    margin-top: 7px; }
  .pt-xs-7 {
    padding-top: 7px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-8 {
    margin-top: 8px; }
  .pt-xs-8 {
    padding-top: 8px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-9 {
    margin-top: 9px; }
  .pt-xs-9 {
    padding-top: 9px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-10 {
    margin-top: 10px; }
  .pt-xs-10 {
    padding-top: 10px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-11 {
    margin-top: 11px; }
  .pt-xs-11 {
    padding-top: 11px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-12 {
    margin-top: 12px; }
  .pt-xs-12 {
    padding-top: 12px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-13 {
    margin-top: 13px; }
  .pt-xs-13 {
    padding-top: 13px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-14 {
    margin-top: 14px; }
  .pt-xs-14 {
    padding-top: 14px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-15 {
    margin-top: 15px; }
  .pt-xs-15 {
    padding-top: 15px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-16 {
    margin-top: 16px; }
  .pt-xs-16 {
    padding-top: 16px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-17 {
    margin-top: 17px; }
  .pt-xs-17 {
    padding-top: 17px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-18 {
    margin-top: 18px; }
  .pt-xs-18 {
    padding-top: 18px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-19 {
    margin-top: 19px; }
  .pt-xs-19 {
    padding-top: 19px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-20 {
    margin-top: 20px; }
  .pt-xs-20 {
    padding-top: 20px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-21 {
    margin-top: 21px; }
  .pt-xs-21 {
    padding-top: 21px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-22 {
    margin-top: 22px; }
  .pt-xs-22 {
    padding-top: 22px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-23 {
    margin-top: 23px; }
  .pt-xs-23 {
    padding-top: 23px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-24 {
    margin-top: 24px; }
  .pt-xs-24 {
    padding-top: 24px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-25 {
    margin-top: 25px; }
  .pt-xs-25 {
    padding-top: 25px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-26 {
    margin-top: 26px; }
  .pt-xs-26 {
    padding-top: 26px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-27 {
    margin-top: 27px; }
  .pt-xs-27 {
    padding-top: 27px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-28 {
    margin-top: 28px; }
  .pt-xs-28 {
    padding-top: 28px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-29 {
    margin-top: 29px; }
  .pt-xs-29 {
    padding-top: 29px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-30 {
    margin-top: 30px; }
  .pt-xs-30 {
    padding-top: 30px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-31 {
    margin-top: 31px; }
  .pt-xs-31 {
    padding-top: 31px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-32 {
    margin-top: 32px; }
  .pt-xs-32 {
    padding-top: 32px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-33 {
    margin-top: 33px; }
  .pt-xs-33 {
    padding-top: 33px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-34 {
    margin-top: 34px; }
  .pt-xs-34 {
    padding-top: 34px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-35 {
    margin-top: 35px; }
  .pt-xs-35 {
    padding-top: 35px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-36 {
    margin-top: 36px; }
  .pt-xs-36 {
    padding-top: 36px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-37 {
    margin-top: 37px; }
  .pt-xs-37 {
    padding-top: 37px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-38 {
    margin-top: 38px; }
  .pt-xs-38 {
    padding-top: 38px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-39 {
    margin-top: 39px; }
  .pt-xs-39 {
    padding-top: 39px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-40 {
    margin-top: 40px; }
  .pt-xs-40 {
    padding-top: 40px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-41 {
    margin-top: 41px; }
  .pt-xs-41 {
    padding-top: 41px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-42 {
    margin-top: 42px; }
  .pt-xs-42 {
    padding-top: 42px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-43 {
    margin-top: 43px; }
  .pt-xs-43 {
    padding-top: 43px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-44 {
    margin-top: 44px; }
  .pt-xs-44 {
    padding-top: 44px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-45 {
    margin-top: 45px; }
  .pt-xs-45 {
    padding-top: 45px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-46 {
    margin-top: 46px; }
  .pt-xs-46 {
    padding-top: 46px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-47 {
    margin-top: 47px; }
  .pt-xs-47 {
    padding-top: 47px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-48 {
    margin-top: 48px; }
  .pt-xs-48 {
    padding-top: 48px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-49 {
    margin-top: 49px; }
  .pt-xs-49 {
    padding-top: 49px; } }

@media only screen and (max-width: 576px) {
  .mt-xs-50 {
    margin-top: 50px; }
  .pt-xs-50 {
    padding-top: 50px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-0 {
    margin-bottom: 0px; }
  .pb-xs-0 {
    padding-bottom: 0px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-1 {
    margin-bottom: 1px; }
  .pb-xs-1 {
    padding-bottom: 1px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-2 {
    margin-bottom: 2px; }
  .pb-xs-2 {
    padding-bottom: 2px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-3 {
    margin-bottom: 3px; }
  .pb-xs-3 {
    padding-bottom: 3px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-4 {
    margin-bottom: 4px; }
  .pb-xs-4 {
    padding-bottom: 4px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-5 {
    margin-bottom: 5px; }
  .pb-xs-5 {
    padding-bottom: 5px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-6 {
    margin-bottom: 6px; }
  .pb-xs-6 {
    padding-bottom: 6px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-7 {
    margin-bottom: 7px; }
  .pb-xs-7 {
    padding-bottom: 7px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-8 {
    margin-bottom: 8px; }
  .pb-xs-8 {
    padding-bottom: 8px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-9 {
    margin-bottom: 9px; }
  .pb-xs-9 {
    padding-bottom: 9px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-10 {
    margin-bottom: 10px; }
  .pb-xs-10 {
    padding-bottom: 10px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-11 {
    margin-bottom: 11px; }
  .pb-xs-11 {
    padding-bottom: 11px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-12 {
    margin-bottom: 12px; }
  .pb-xs-12 {
    padding-bottom: 12px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-13 {
    margin-bottom: 13px; }
  .pb-xs-13 {
    padding-bottom: 13px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-14 {
    margin-bottom: 14px; }
  .pb-xs-14 {
    padding-bottom: 14px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-15 {
    margin-bottom: 15px; }
  .pb-xs-15 {
    padding-bottom: 15px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-16 {
    margin-bottom: 16px; }
  .pb-xs-16 {
    padding-bottom: 16px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-17 {
    margin-bottom: 17px; }
  .pb-xs-17 {
    padding-bottom: 17px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-18 {
    margin-bottom: 18px; }
  .pb-xs-18 {
    padding-bottom: 18px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-19 {
    margin-bottom: 19px; }
  .pb-xs-19 {
    padding-bottom: 19px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-20 {
    margin-bottom: 20px; }
  .pb-xs-20 {
    padding-bottom: 20px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-21 {
    margin-bottom: 21px; }
  .pb-xs-21 {
    padding-bottom: 21px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-22 {
    margin-bottom: 22px; }
  .pb-xs-22 {
    padding-bottom: 22px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-23 {
    margin-bottom: 23px; }
  .pb-xs-23 {
    padding-bottom: 23px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-24 {
    margin-bottom: 24px; }
  .pb-xs-24 {
    padding-bottom: 24px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-25 {
    margin-bottom: 25px; }
  .pb-xs-25 {
    padding-bottom: 25px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-26 {
    margin-bottom: 26px; }
  .pb-xs-26 {
    padding-bottom: 26px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-27 {
    margin-bottom: 27px; }
  .pb-xs-27 {
    padding-bottom: 27px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-28 {
    margin-bottom: 28px; }
  .pb-xs-28 {
    padding-bottom: 28px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-29 {
    margin-bottom: 29px; }
  .pb-xs-29 {
    padding-bottom: 29px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-30 {
    margin-bottom: 30px; }
  .pb-xs-30 {
    padding-bottom: 30px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-31 {
    margin-bottom: 31px; }
  .pb-xs-31 {
    padding-bottom: 31px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-32 {
    margin-bottom: 32px; }
  .pb-xs-32 {
    padding-bottom: 32px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-33 {
    margin-bottom: 33px; }
  .pb-xs-33 {
    padding-bottom: 33px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-34 {
    margin-bottom: 34px; }
  .pb-xs-34 {
    padding-bottom: 34px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-35 {
    margin-bottom: 35px; }
  .pb-xs-35 {
    padding-bottom: 35px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-36 {
    margin-bottom: 36px; }
  .pb-xs-36 {
    padding-bottom: 36px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-37 {
    margin-bottom: 37px; }
  .pb-xs-37 {
    padding-bottom: 37px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-38 {
    margin-bottom: 38px; }
  .pb-xs-38 {
    padding-bottom: 38px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-39 {
    margin-bottom: 39px; }
  .pb-xs-39 {
    padding-bottom: 39px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-40 {
    margin-bottom: 40px; }
  .pb-xs-40 {
    padding-bottom: 40px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-41 {
    margin-bottom: 41px; }
  .pb-xs-41 {
    padding-bottom: 41px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-42 {
    margin-bottom: 42px; }
  .pb-xs-42 {
    padding-bottom: 42px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-43 {
    margin-bottom: 43px; }
  .pb-xs-43 {
    padding-bottom: 43px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-44 {
    margin-bottom: 44px; }
  .pb-xs-44 {
    padding-bottom: 44px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-45 {
    margin-bottom: 45px; }
  .pb-xs-45 {
    padding-bottom: 45px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-46 {
    margin-bottom: 46px; }
  .pb-xs-46 {
    padding-bottom: 46px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-47 {
    margin-bottom: 47px; }
  .pb-xs-47 {
    padding-bottom: 47px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-48 {
    margin-bottom: 48px; }
  .pb-xs-48 {
    padding-bottom: 48px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-49 {
    margin-bottom: 49px; }
  .pb-xs-49 {
    padding-bottom: 49px; } }

@media only screen and (max-width: 576px) {
  .mb-xs-50 {
    margin-bottom: 50px; }
  .pb-xs-50 {
    padding-bottom: 50px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-0 {
    margin-left: 0px; }
  .pl-xs-0 {
    padding-left: 0px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-1 {
    margin-left: 1px; }
  .pl-xs-1 {
    padding-left: 1px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-2 {
    margin-left: 2px; }
  .pl-xs-2 {
    padding-left: 2px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-3 {
    margin-left: 3px; }
  .pl-xs-3 {
    padding-left: 3px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-4 {
    margin-left: 4px; }
  .pl-xs-4 {
    padding-left: 4px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-5 {
    margin-left: 5px; }
  .pl-xs-5 {
    padding-left: 5px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-6 {
    margin-left: 6px; }
  .pl-xs-6 {
    padding-left: 6px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-7 {
    margin-left: 7px; }
  .pl-xs-7 {
    padding-left: 7px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-8 {
    margin-left: 8px; }
  .pl-xs-8 {
    padding-left: 8px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-9 {
    margin-left: 9px; }
  .pl-xs-9 {
    padding-left: 9px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-10 {
    margin-left: 10px; }
  .pl-xs-10 {
    padding-left: 10px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-11 {
    margin-left: 11px; }
  .pl-xs-11 {
    padding-left: 11px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-12 {
    margin-left: 12px; }
  .pl-xs-12 {
    padding-left: 12px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-13 {
    margin-left: 13px; }
  .pl-xs-13 {
    padding-left: 13px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-14 {
    margin-left: 14px; }
  .pl-xs-14 {
    padding-left: 14px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-15 {
    margin-left: 15px; }
  .pl-xs-15 {
    padding-left: 15px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-16 {
    margin-left: 16px; }
  .pl-xs-16 {
    padding-left: 16px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-17 {
    margin-left: 17px; }
  .pl-xs-17 {
    padding-left: 17px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-18 {
    margin-left: 18px; }
  .pl-xs-18 {
    padding-left: 18px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-19 {
    margin-left: 19px; }
  .pl-xs-19 {
    padding-left: 19px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-20 {
    margin-left: 20px; }
  .pl-xs-20 {
    padding-left: 20px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-21 {
    margin-left: 21px; }
  .pl-xs-21 {
    padding-left: 21px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-22 {
    margin-left: 22px; }
  .pl-xs-22 {
    padding-left: 22px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-23 {
    margin-left: 23px; }
  .pl-xs-23 {
    padding-left: 23px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-24 {
    margin-left: 24px; }
  .pl-xs-24 {
    padding-left: 24px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-25 {
    margin-left: 25px; }
  .pl-xs-25 {
    padding-left: 25px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-26 {
    margin-left: 26px; }
  .pl-xs-26 {
    padding-left: 26px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-27 {
    margin-left: 27px; }
  .pl-xs-27 {
    padding-left: 27px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-28 {
    margin-left: 28px; }
  .pl-xs-28 {
    padding-left: 28px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-29 {
    margin-left: 29px; }
  .pl-xs-29 {
    padding-left: 29px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-30 {
    margin-left: 30px; }
  .pl-xs-30 {
    padding-left: 30px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-31 {
    margin-left: 31px; }
  .pl-xs-31 {
    padding-left: 31px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-32 {
    margin-left: 32px; }
  .pl-xs-32 {
    padding-left: 32px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-33 {
    margin-left: 33px; }
  .pl-xs-33 {
    padding-left: 33px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-34 {
    margin-left: 34px; }
  .pl-xs-34 {
    padding-left: 34px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-35 {
    margin-left: 35px; }
  .pl-xs-35 {
    padding-left: 35px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-36 {
    margin-left: 36px; }
  .pl-xs-36 {
    padding-left: 36px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-37 {
    margin-left: 37px; }
  .pl-xs-37 {
    padding-left: 37px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-38 {
    margin-left: 38px; }
  .pl-xs-38 {
    padding-left: 38px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-39 {
    margin-left: 39px; }
  .pl-xs-39 {
    padding-left: 39px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-40 {
    margin-left: 40px; }
  .pl-xs-40 {
    padding-left: 40px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-41 {
    margin-left: 41px; }
  .pl-xs-41 {
    padding-left: 41px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-42 {
    margin-left: 42px; }
  .pl-xs-42 {
    padding-left: 42px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-43 {
    margin-left: 43px; }
  .pl-xs-43 {
    padding-left: 43px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-44 {
    margin-left: 44px; }
  .pl-xs-44 {
    padding-left: 44px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-45 {
    margin-left: 45px; }
  .pl-xs-45 {
    padding-left: 45px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-46 {
    margin-left: 46px; }
  .pl-xs-46 {
    padding-left: 46px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-47 {
    margin-left: 47px; }
  .pl-xs-47 {
    padding-left: 47px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-48 {
    margin-left: 48px; }
  .pl-xs-48 {
    padding-left: 48px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-49 {
    margin-left: 49px; }
  .pl-xs-49 {
    padding-left: 49px; } }

@media only screen and (max-width: 576px) {
  .ml-xs-50 {
    margin-left: 50px; }
  .pl-xs-50 {
    padding-left: 50px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-0 {
    margin-right: 0px; }
  .pr-xs-0 {
    padding-right: 0px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-1 {
    margin-right: 1px; }
  .pr-xs-1 {
    padding-right: 1px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-2 {
    margin-right: 2px; }
  .pr-xs-2 {
    padding-right: 2px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-3 {
    margin-right: 3px; }
  .pr-xs-3 {
    padding-right: 3px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-4 {
    margin-right: 4px; }
  .pr-xs-4 {
    padding-right: 4px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-5 {
    margin-right: 5px; }
  .pr-xs-5 {
    padding-right: 5px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-6 {
    margin-right: 6px; }
  .pr-xs-6 {
    padding-right: 6px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-7 {
    margin-right: 7px; }
  .pr-xs-7 {
    padding-right: 7px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-8 {
    margin-right: 8px; }
  .pr-xs-8 {
    padding-right: 8px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-9 {
    margin-right: 9px; }
  .pr-xs-9 {
    padding-right: 9px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-10 {
    margin-right: 10px; }
  .pr-xs-10 {
    padding-right: 10px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-11 {
    margin-right: 11px; }
  .pr-xs-11 {
    padding-right: 11px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-12 {
    margin-right: 12px; }
  .pr-xs-12 {
    padding-right: 12px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-13 {
    margin-right: 13px; }
  .pr-xs-13 {
    padding-right: 13px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-14 {
    margin-right: 14px; }
  .pr-xs-14 {
    padding-right: 14px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-15 {
    margin-right: 15px; }
  .pr-xs-15 {
    padding-right: 15px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-16 {
    margin-right: 16px; }
  .pr-xs-16 {
    padding-right: 16px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-17 {
    margin-right: 17px; }
  .pr-xs-17 {
    padding-right: 17px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-18 {
    margin-right: 18px; }
  .pr-xs-18 {
    padding-right: 18px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-19 {
    margin-right: 19px; }
  .pr-xs-19 {
    padding-right: 19px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-20 {
    margin-right: 20px; }
  .pr-xs-20 {
    padding-right: 20px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-21 {
    margin-right: 21px; }
  .pr-xs-21 {
    padding-right: 21px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-22 {
    margin-right: 22px; }
  .pr-xs-22 {
    padding-right: 22px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-23 {
    margin-right: 23px; }
  .pr-xs-23 {
    padding-right: 23px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-24 {
    margin-right: 24px; }
  .pr-xs-24 {
    padding-right: 24px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-25 {
    margin-right: 25px; }
  .pr-xs-25 {
    padding-right: 25px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-26 {
    margin-right: 26px; }
  .pr-xs-26 {
    padding-right: 26px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-27 {
    margin-right: 27px; }
  .pr-xs-27 {
    padding-right: 27px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-28 {
    margin-right: 28px; }
  .pr-xs-28 {
    padding-right: 28px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-29 {
    margin-right: 29px; }
  .pr-xs-29 {
    padding-right: 29px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-30 {
    margin-right: 30px; }
  .pr-xs-30 {
    padding-right: 30px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-31 {
    margin-right: 31px; }
  .pr-xs-31 {
    padding-right: 31px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-32 {
    margin-right: 32px; }
  .pr-xs-32 {
    padding-right: 32px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-33 {
    margin-right: 33px; }
  .pr-xs-33 {
    padding-right: 33px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-34 {
    margin-right: 34px; }
  .pr-xs-34 {
    padding-right: 34px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-35 {
    margin-right: 35px; }
  .pr-xs-35 {
    padding-right: 35px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-36 {
    margin-right: 36px; }
  .pr-xs-36 {
    padding-right: 36px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-37 {
    margin-right: 37px; }
  .pr-xs-37 {
    padding-right: 37px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-38 {
    margin-right: 38px; }
  .pr-xs-38 {
    padding-right: 38px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-39 {
    margin-right: 39px; }
  .pr-xs-39 {
    padding-right: 39px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-40 {
    margin-right: 40px; }
  .pr-xs-40 {
    padding-right: 40px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-41 {
    margin-right: 41px; }
  .pr-xs-41 {
    padding-right: 41px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-42 {
    margin-right: 42px; }
  .pr-xs-42 {
    padding-right: 42px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-43 {
    margin-right: 43px; }
  .pr-xs-43 {
    padding-right: 43px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-44 {
    margin-right: 44px; }
  .pr-xs-44 {
    padding-right: 44px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-45 {
    margin-right: 45px; }
  .pr-xs-45 {
    padding-right: 45px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-46 {
    margin-right: 46px; }
  .pr-xs-46 {
    padding-right: 46px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-47 {
    margin-right: 47px; }
  .pr-xs-47 {
    padding-right: 47px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-48 {
    margin-right: 48px; }
  .pr-xs-48 {
    padding-right: 48px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-49 {
    margin-right: 49px; }
  .pr-xs-49 {
    padding-right: 49px; } }

@media only screen and (max-width: 576px) {
  .mr-xs-50 {
    margin-right: 50px; }
  .pr-xs-50 {
    padding-right: 50px; } }

@media only screen and (max-width: 768px) {
  .m-sm-0 {
    margin: 0px; }
  .p-sm-0 {
    padding: 0px; } }

@media only screen and (max-width: 768px) {
  .m-sm-1 {
    margin: 1px; }
  .p-sm-1 {
    padding: 1px; } }

@media only screen and (max-width: 768px) {
  .m-sm-2 {
    margin: 2px; }
  .p-sm-2 {
    padding: 2px; } }

@media only screen and (max-width: 768px) {
  .m-sm-3 {
    margin: 3px; }
  .p-sm-3 {
    padding: 3px; } }

@media only screen and (max-width: 768px) {
  .m-sm-4 {
    margin: 4px; }
  .p-sm-4 {
    padding: 4px; } }

@media only screen and (max-width: 768px) {
  .m-sm-5 {
    margin: 5px; }
  .p-sm-5 {
    padding: 5px; } }

@media only screen and (max-width: 768px) {
  .m-sm-6 {
    margin: 6px; }
  .p-sm-6 {
    padding: 6px; } }

@media only screen and (max-width: 768px) {
  .m-sm-7 {
    margin: 7px; }
  .p-sm-7 {
    padding: 7px; } }

@media only screen and (max-width: 768px) {
  .m-sm-8 {
    margin: 8px; }
  .p-sm-8 {
    padding: 8px; } }

@media only screen and (max-width: 768px) {
  .m-sm-9 {
    margin: 9px; }
  .p-sm-9 {
    padding: 9px; } }

@media only screen and (max-width: 768px) {
  .m-sm-10 {
    margin: 10px; }
  .p-sm-10 {
    padding: 10px; } }

@media only screen and (max-width: 768px) {
  .m-sm-11 {
    margin: 11px; }
  .p-sm-11 {
    padding: 11px; } }

@media only screen and (max-width: 768px) {
  .m-sm-12 {
    margin: 12px; }
  .p-sm-12 {
    padding: 12px; } }

@media only screen and (max-width: 768px) {
  .m-sm-13 {
    margin: 13px; }
  .p-sm-13 {
    padding: 13px; } }

@media only screen and (max-width: 768px) {
  .m-sm-14 {
    margin: 14px; }
  .p-sm-14 {
    padding: 14px; } }

@media only screen and (max-width: 768px) {
  .m-sm-15 {
    margin: 15px; }
  .p-sm-15 {
    padding: 15px; } }

@media only screen and (max-width: 768px) {
  .m-sm-16 {
    margin: 16px; }
  .p-sm-16 {
    padding: 16px; } }

@media only screen and (max-width: 768px) {
  .m-sm-17 {
    margin: 17px; }
  .p-sm-17 {
    padding: 17px; } }

@media only screen and (max-width: 768px) {
  .m-sm-18 {
    margin: 18px; }
  .p-sm-18 {
    padding: 18px; } }

@media only screen and (max-width: 768px) {
  .m-sm-19 {
    margin: 19px; }
  .p-sm-19 {
    padding: 19px; } }

@media only screen and (max-width: 768px) {
  .m-sm-20 {
    margin: 20px; }
  .p-sm-20 {
    padding: 20px; } }

@media only screen and (max-width: 768px) {
  .m-sm-21 {
    margin: 21px; }
  .p-sm-21 {
    padding: 21px; } }

@media only screen and (max-width: 768px) {
  .m-sm-22 {
    margin: 22px; }
  .p-sm-22 {
    padding: 22px; } }

@media only screen and (max-width: 768px) {
  .m-sm-23 {
    margin: 23px; }
  .p-sm-23 {
    padding: 23px; } }

@media only screen and (max-width: 768px) {
  .m-sm-24 {
    margin: 24px; }
  .p-sm-24 {
    padding: 24px; } }

@media only screen and (max-width: 768px) {
  .m-sm-25 {
    margin: 25px; }
  .p-sm-25 {
    padding: 25px; } }

@media only screen and (max-width: 768px) {
  .m-sm-26 {
    margin: 26px; }
  .p-sm-26 {
    padding: 26px; } }

@media only screen and (max-width: 768px) {
  .m-sm-27 {
    margin: 27px; }
  .p-sm-27 {
    padding: 27px; } }

@media only screen and (max-width: 768px) {
  .m-sm-28 {
    margin: 28px; }
  .p-sm-28 {
    padding: 28px; } }

@media only screen and (max-width: 768px) {
  .m-sm-29 {
    margin: 29px; }
  .p-sm-29 {
    padding: 29px; } }

@media only screen and (max-width: 768px) {
  .m-sm-30 {
    margin: 30px; }
  .p-sm-30 {
    padding: 30px; } }

@media only screen and (max-width: 768px) {
  .m-sm-31 {
    margin: 31px; }
  .p-sm-31 {
    padding: 31px; } }

@media only screen and (max-width: 768px) {
  .m-sm-32 {
    margin: 32px; }
  .p-sm-32 {
    padding: 32px; } }

@media only screen and (max-width: 768px) {
  .m-sm-33 {
    margin: 33px; }
  .p-sm-33 {
    padding: 33px; } }

@media only screen and (max-width: 768px) {
  .m-sm-34 {
    margin: 34px; }
  .p-sm-34 {
    padding: 34px; } }

@media only screen and (max-width: 768px) {
  .m-sm-35 {
    margin: 35px; }
  .p-sm-35 {
    padding: 35px; } }

@media only screen and (max-width: 768px) {
  .m-sm-36 {
    margin: 36px; }
  .p-sm-36 {
    padding: 36px; } }

@media only screen and (max-width: 768px) {
  .m-sm-37 {
    margin: 37px; }
  .p-sm-37 {
    padding: 37px; } }

@media only screen and (max-width: 768px) {
  .m-sm-38 {
    margin: 38px; }
  .p-sm-38 {
    padding: 38px; } }

@media only screen and (max-width: 768px) {
  .m-sm-39 {
    margin: 39px; }
  .p-sm-39 {
    padding: 39px; } }

@media only screen and (max-width: 768px) {
  .m-sm-40 {
    margin: 40px; }
  .p-sm-40 {
    padding: 40px; } }

@media only screen and (max-width: 768px) {
  .m-sm-41 {
    margin: 41px; }
  .p-sm-41 {
    padding: 41px; } }

@media only screen and (max-width: 768px) {
  .m-sm-42 {
    margin: 42px; }
  .p-sm-42 {
    padding: 42px; } }

@media only screen and (max-width: 768px) {
  .m-sm-43 {
    margin: 43px; }
  .p-sm-43 {
    padding: 43px; } }

@media only screen and (max-width: 768px) {
  .m-sm-44 {
    margin: 44px; }
  .p-sm-44 {
    padding: 44px; } }

@media only screen and (max-width: 768px) {
  .m-sm-45 {
    margin: 45px; }
  .p-sm-45 {
    padding: 45px; } }

@media only screen and (max-width: 768px) {
  .m-sm-46 {
    margin: 46px; }
  .p-sm-46 {
    padding: 46px; } }

@media only screen and (max-width: 768px) {
  .m-sm-47 {
    margin: 47px; }
  .p-sm-47 {
    padding: 47px; } }

@media only screen and (max-width: 768px) {
  .m-sm-48 {
    margin: 48px; }
  .p-sm-48 {
    padding: 48px; } }

@media only screen and (max-width: 768px) {
  .m-sm-49 {
    margin: 49px; }
  .p-sm-49 {
    padding: 49px; } }

@media only screen and (max-width: 768px) {
  .m-sm-50 {
    margin: 50px; }
  .p-sm-50 {
    padding: 50px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-0 {
    margin-top: 0px; }
  .pt-sm-0 {
    padding-top: 0px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-1 {
    margin-top: 1px; }
  .pt-sm-1 {
    padding-top: 1px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-2 {
    margin-top: 2px; }
  .pt-sm-2 {
    padding-top: 2px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-3 {
    margin-top: 3px; }
  .pt-sm-3 {
    padding-top: 3px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-4 {
    margin-top: 4px; }
  .pt-sm-4 {
    padding-top: 4px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-5 {
    margin-top: 5px; }
  .pt-sm-5 {
    padding-top: 5px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-6 {
    margin-top: 6px; }
  .pt-sm-6 {
    padding-top: 6px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-7 {
    margin-top: 7px; }
  .pt-sm-7 {
    padding-top: 7px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-8 {
    margin-top: 8px; }
  .pt-sm-8 {
    padding-top: 8px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-9 {
    margin-top: 9px; }
  .pt-sm-9 {
    padding-top: 9px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-10 {
    margin-top: 10px; }
  .pt-sm-10 {
    padding-top: 10px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-11 {
    margin-top: 11px; }
  .pt-sm-11 {
    padding-top: 11px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-12 {
    margin-top: 12px; }
  .pt-sm-12 {
    padding-top: 12px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-13 {
    margin-top: 13px; }
  .pt-sm-13 {
    padding-top: 13px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-14 {
    margin-top: 14px; }
  .pt-sm-14 {
    padding-top: 14px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-15 {
    margin-top: 15px; }
  .pt-sm-15 {
    padding-top: 15px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-16 {
    margin-top: 16px; }
  .pt-sm-16 {
    padding-top: 16px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-17 {
    margin-top: 17px; }
  .pt-sm-17 {
    padding-top: 17px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-18 {
    margin-top: 18px; }
  .pt-sm-18 {
    padding-top: 18px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-19 {
    margin-top: 19px; }
  .pt-sm-19 {
    padding-top: 19px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-20 {
    margin-top: 20px; }
  .pt-sm-20 {
    padding-top: 20px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-21 {
    margin-top: 21px; }
  .pt-sm-21 {
    padding-top: 21px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-22 {
    margin-top: 22px; }
  .pt-sm-22 {
    padding-top: 22px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-23 {
    margin-top: 23px; }
  .pt-sm-23 {
    padding-top: 23px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-24 {
    margin-top: 24px; }
  .pt-sm-24 {
    padding-top: 24px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-25 {
    margin-top: 25px; }
  .pt-sm-25 {
    padding-top: 25px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-26 {
    margin-top: 26px; }
  .pt-sm-26 {
    padding-top: 26px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-27 {
    margin-top: 27px; }
  .pt-sm-27 {
    padding-top: 27px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-28 {
    margin-top: 28px; }
  .pt-sm-28 {
    padding-top: 28px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-29 {
    margin-top: 29px; }
  .pt-sm-29 {
    padding-top: 29px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-30 {
    margin-top: 30px; }
  .pt-sm-30 {
    padding-top: 30px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-31 {
    margin-top: 31px; }
  .pt-sm-31 {
    padding-top: 31px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-32 {
    margin-top: 32px; }
  .pt-sm-32 {
    padding-top: 32px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-33 {
    margin-top: 33px; }
  .pt-sm-33 {
    padding-top: 33px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-34 {
    margin-top: 34px; }
  .pt-sm-34 {
    padding-top: 34px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-35 {
    margin-top: 35px; }
  .pt-sm-35 {
    padding-top: 35px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-36 {
    margin-top: 36px; }
  .pt-sm-36 {
    padding-top: 36px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-37 {
    margin-top: 37px; }
  .pt-sm-37 {
    padding-top: 37px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-38 {
    margin-top: 38px; }
  .pt-sm-38 {
    padding-top: 38px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-39 {
    margin-top: 39px; }
  .pt-sm-39 {
    padding-top: 39px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-40 {
    margin-top: 40px; }
  .pt-sm-40 {
    padding-top: 40px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-41 {
    margin-top: 41px; }
  .pt-sm-41 {
    padding-top: 41px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-42 {
    margin-top: 42px; }
  .pt-sm-42 {
    padding-top: 42px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-43 {
    margin-top: 43px; }
  .pt-sm-43 {
    padding-top: 43px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-44 {
    margin-top: 44px; }
  .pt-sm-44 {
    padding-top: 44px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-45 {
    margin-top: 45px; }
  .pt-sm-45 {
    padding-top: 45px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-46 {
    margin-top: 46px; }
  .pt-sm-46 {
    padding-top: 46px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-47 {
    margin-top: 47px; }
  .pt-sm-47 {
    padding-top: 47px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-48 {
    margin-top: 48px; }
  .pt-sm-48 {
    padding-top: 48px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-49 {
    margin-top: 49px; }
  .pt-sm-49 {
    padding-top: 49px; } }

@media only screen and (max-width: 768px) {
  .mt-sm-50 {
    margin-top: 50px; }
  .pt-sm-50 {
    padding-top: 50px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-0 {
    margin-bottom: 0px; }
  .pb-sm-0 {
    padding-bottom: 0px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-1 {
    margin-bottom: 1px; }
  .pb-sm-1 {
    padding-bottom: 1px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-2 {
    margin-bottom: 2px; }
  .pb-sm-2 {
    padding-bottom: 2px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-3 {
    margin-bottom: 3px; }
  .pb-sm-3 {
    padding-bottom: 3px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-4 {
    margin-bottom: 4px; }
  .pb-sm-4 {
    padding-bottom: 4px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-5 {
    margin-bottom: 5px; }
  .pb-sm-5 {
    padding-bottom: 5px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-6 {
    margin-bottom: 6px; }
  .pb-sm-6 {
    padding-bottom: 6px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-7 {
    margin-bottom: 7px; }
  .pb-sm-7 {
    padding-bottom: 7px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-8 {
    margin-bottom: 8px; }
  .pb-sm-8 {
    padding-bottom: 8px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-9 {
    margin-bottom: 9px; }
  .pb-sm-9 {
    padding-bottom: 9px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-10 {
    margin-bottom: 10px; }
  .pb-sm-10 {
    padding-bottom: 10px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-11 {
    margin-bottom: 11px; }
  .pb-sm-11 {
    padding-bottom: 11px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-12 {
    margin-bottom: 12px; }
  .pb-sm-12 {
    padding-bottom: 12px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-13 {
    margin-bottom: 13px; }
  .pb-sm-13 {
    padding-bottom: 13px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-14 {
    margin-bottom: 14px; }
  .pb-sm-14 {
    padding-bottom: 14px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-15 {
    margin-bottom: 15px; }
  .pb-sm-15 {
    padding-bottom: 15px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-16 {
    margin-bottom: 16px; }
  .pb-sm-16 {
    padding-bottom: 16px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-17 {
    margin-bottom: 17px; }
  .pb-sm-17 {
    padding-bottom: 17px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-18 {
    margin-bottom: 18px; }
  .pb-sm-18 {
    padding-bottom: 18px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-19 {
    margin-bottom: 19px; }
  .pb-sm-19 {
    padding-bottom: 19px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-20 {
    margin-bottom: 20px; }
  .pb-sm-20 {
    padding-bottom: 20px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-21 {
    margin-bottom: 21px; }
  .pb-sm-21 {
    padding-bottom: 21px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-22 {
    margin-bottom: 22px; }
  .pb-sm-22 {
    padding-bottom: 22px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-23 {
    margin-bottom: 23px; }
  .pb-sm-23 {
    padding-bottom: 23px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-24 {
    margin-bottom: 24px; }
  .pb-sm-24 {
    padding-bottom: 24px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-25 {
    margin-bottom: 25px; }
  .pb-sm-25 {
    padding-bottom: 25px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-26 {
    margin-bottom: 26px; }
  .pb-sm-26 {
    padding-bottom: 26px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-27 {
    margin-bottom: 27px; }
  .pb-sm-27 {
    padding-bottom: 27px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-28 {
    margin-bottom: 28px; }
  .pb-sm-28 {
    padding-bottom: 28px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-29 {
    margin-bottom: 29px; }
  .pb-sm-29 {
    padding-bottom: 29px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-30 {
    margin-bottom: 30px; }
  .pb-sm-30 {
    padding-bottom: 30px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-31 {
    margin-bottom: 31px; }
  .pb-sm-31 {
    padding-bottom: 31px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-32 {
    margin-bottom: 32px; }
  .pb-sm-32 {
    padding-bottom: 32px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-33 {
    margin-bottom: 33px; }
  .pb-sm-33 {
    padding-bottom: 33px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-34 {
    margin-bottom: 34px; }
  .pb-sm-34 {
    padding-bottom: 34px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-35 {
    margin-bottom: 35px; }
  .pb-sm-35 {
    padding-bottom: 35px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-36 {
    margin-bottom: 36px; }
  .pb-sm-36 {
    padding-bottom: 36px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-37 {
    margin-bottom: 37px; }
  .pb-sm-37 {
    padding-bottom: 37px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-38 {
    margin-bottom: 38px; }
  .pb-sm-38 {
    padding-bottom: 38px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-39 {
    margin-bottom: 39px; }
  .pb-sm-39 {
    padding-bottom: 39px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-40 {
    margin-bottom: 40px; }
  .pb-sm-40 {
    padding-bottom: 40px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-41 {
    margin-bottom: 41px; }
  .pb-sm-41 {
    padding-bottom: 41px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-42 {
    margin-bottom: 42px; }
  .pb-sm-42 {
    padding-bottom: 42px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-43 {
    margin-bottom: 43px; }
  .pb-sm-43 {
    padding-bottom: 43px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-44 {
    margin-bottom: 44px; }
  .pb-sm-44 {
    padding-bottom: 44px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-45 {
    margin-bottom: 45px; }
  .pb-sm-45 {
    padding-bottom: 45px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-46 {
    margin-bottom: 46px; }
  .pb-sm-46 {
    padding-bottom: 46px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-47 {
    margin-bottom: 47px; }
  .pb-sm-47 {
    padding-bottom: 47px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-48 {
    margin-bottom: 48px; }
  .pb-sm-48 {
    padding-bottom: 48px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-49 {
    margin-bottom: 49px; }
  .pb-sm-49 {
    padding-bottom: 49px; } }

@media only screen and (max-width: 768px) {
  .mb-sm-50 {
    margin-bottom: 50px; }
  .pb-sm-50 {
    padding-bottom: 50px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-0 {
    margin-left: 0px; }
  .pl-sm-0 {
    padding-left: 0px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-1 {
    margin-left: 1px; }
  .pl-sm-1 {
    padding-left: 1px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-2 {
    margin-left: 2px; }
  .pl-sm-2 {
    padding-left: 2px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-3 {
    margin-left: 3px; }
  .pl-sm-3 {
    padding-left: 3px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-4 {
    margin-left: 4px; }
  .pl-sm-4 {
    padding-left: 4px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-5 {
    margin-left: 5px; }
  .pl-sm-5 {
    padding-left: 5px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-6 {
    margin-left: 6px; }
  .pl-sm-6 {
    padding-left: 6px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-7 {
    margin-left: 7px; }
  .pl-sm-7 {
    padding-left: 7px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-8 {
    margin-left: 8px; }
  .pl-sm-8 {
    padding-left: 8px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-9 {
    margin-left: 9px; }
  .pl-sm-9 {
    padding-left: 9px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-10 {
    margin-left: 10px; }
  .pl-sm-10 {
    padding-left: 10px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-11 {
    margin-left: 11px; }
  .pl-sm-11 {
    padding-left: 11px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-12 {
    margin-left: 12px; }
  .pl-sm-12 {
    padding-left: 12px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-13 {
    margin-left: 13px; }
  .pl-sm-13 {
    padding-left: 13px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-14 {
    margin-left: 14px; }
  .pl-sm-14 {
    padding-left: 14px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-15 {
    margin-left: 15px; }
  .pl-sm-15 {
    padding-left: 15px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-16 {
    margin-left: 16px; }
  .pl-sm-16 {
    padding-left: 16px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-17 {
    margin-left: 17px; }
  .pl-sm-17 {
    padding-left: 17px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-18 {
    margin-left: 18px; }
  .pl-sm-18 {
    padding-left: 18px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-19 {
    margin-left: 19px; }
  .pl-sm-19 {
    padding-left: 19px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-20 {
    margin-left: 20px; }
  .pl-sm-20 {
    padding-left: 20px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-21 {
    margin-left: 21px; }
  .pl-sm-21 {
    padding-left: 21px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-22 {
    margin-left: 22px; }
  .pl-sm-22 {
    padding-left: 22px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-23 {
    margin-left: 23px; }
  .pl-sm-23 {
    padding-left: 23px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-24 {
    margin-left: 24px; }
  .pl-sm-24 {
    padding-left: 24px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-25 {
    margin-left: 25px; }
  .pl-sm-25 {
    padding-left: 25px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-26 {
    margin-left: 26px; }
  .pl-sm-26 {
    padding-left: 26px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-27 {
    margin-left: 27px; }
  .pl-sm-27 {
    padding-left: 27px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-28 {
    margin-left: 28px; }
  .pl-sm-28 {
    padding-left: 28px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-29 {
    margin-left: 29px; }
  .pl-sm-29 {
    padding-left: 29px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-30 {
    margin-left: 30px; }
  .pl-sm-30 {
    padding-left: 30px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-31 {
    margin-left: 31px; }
  .pl-sm-31 {
    padding-left: 31px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-32 {
    margin-left: 32px; }
  .pl-sm-32 {
    padding-left: 32px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-33 {
    margin-left: 33px; }
  .pl-sm-33 {
    padding-left: 33px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-34 {
    margin-left: 34px; }
  .pl-sm-34 {
    padding-left: 34px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-35 {
    margin-left: 35px; }
  .pl-sm-35 {
    padding-left: 35px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-36 {
    margin-left: 36px; }
  .pl-sm-36 {
    padding-left: 36px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-37 {
    margin-left: 37px; }
  .pl-sm-37 {
    padding-left: 37px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-38 {
    margin-left: 38px; }
  .pl-sm-38 {
    padding-left: 38px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-39 {
    margin-left: 39px; }
  .pl-sm-39 {
    padding-left: 39px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-40 {
    margin-left: 40px; }
  .pl-sm-40 {
    padding-left: 40px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-41 {
    margin-left: 41px; }
  .pl-sm-41 {
    padding-left: 41px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-42 {
    margin-left: 42px; }
  .pl-sm-42 {
    padding-left: 42px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-43 {
    margin-left: 43px; }
  .pl-sm-43 {
    padding-left: 43px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-44 {
    margin-left: 44px; }
  .pl-sm-44 {
    padding-left: 44px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-45 {
    margin-left: 45px; }
  .pl-sm-45 {
    padding-left: 45px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-46 {
    margin-left: 46px; }
  .pl-sm-46 {
    padding-left: 46px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-47 {
    margin-left: 47px; }
  .pl-sm-47 {
    padding-left: 47px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-48 {
    margin-left: 48px; }
  .pl-sm-48 {
    padding-left: 48px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-49 {
    margin-left: 49px; }
  .pl-sm-49 {
    padding-left: 49px; } }

@media only screen and (max-width: 768px) {
  .ml-sm-50 {
    margin-left: 50px; }
  .pl-sm-50 {
    padding-left: 50px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-0 {
    margin-right: 0px; }
  .pr-sm-0 {
    padding-right: 0px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-1 {
    margin-right: 1px; }
  .pr-sm-1 {
    padding-right: 1px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-2 {
    margin-right: 2px; }
  .pr-sm-2 {
    padding-right: 2px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-3 {
    margin-right: 3px; }
  .pr-sm-3 {
    padding-right: 3px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-4 {
    margin-right: 4px; }
  .pr-sm-4 {
    padding-right: 4px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-5 {
    margin-right: 5px; }
  .pr-sm-5 {
    padding-right: 5px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-6 {
    margin-right: 6px; }
  .pr-sm-6 {
    padding-right: 6px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-7 {
    margin-right: 7px; }
  .pr-sm-7 {
    padding-right: 7px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-8 {
    margin-right: 8px; }
  .pr-sm-8 {
    padding-right: 8px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-9 {
    margin-right: 9px; }
  .pr-sm-9 {
    padding-right: 9px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-10 {
    margin-right: 10px; }
  .pr-sm-10 {
    padding-right: 10px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-11 {
    margin-right: 11px; }
  .pr-sm-11 {
    padding-right: 11px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-12 {
    margin-right: 12px; }
  .pr-sm-12 {
    padding-right: 12px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-13 {
    margin-right: 13px; }
  .pr-sm-13 {
    padding-right: 13px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-14 {
    margin-right: 14px; }
  .pr-sm-14 {
    padding-right: 14px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-15 {
    margin-right: 15px; }
  .pr-sm-15 {
    padding-right: 15px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-16 {
    margin-right: 16px; }
  .pr-sm-16 {
    padding-right: 16px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-17 {
    margin-right: 17px; }
  .pr-sm-17 {
    padding-right: 17px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-18 {
    margin-right: 18px; }
  .pr-sm-18 {
    padding-right: 18px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-19 {
    margin-right: 19px; }
  .pr-sm-19 {
    padding-right: 19px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-20 {
    margin-right: 20px; }
  .pr-sm-20 {
    padding-right: 20px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-21 {
    margin-right: 21px; }
  .pr-sm-21 {
    padding-right: 21px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-22 {
    margin-right: 22px; }
  .pr-sm-22 {
    padding-right: 22px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-23 {
    margin-right: 23px; }
  .pr-sm-23 {
    padding-right: 23px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-24 {
    margin-right: 24px; }
  .pr-sm-24 {
    padding-right: 24px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-25 {
    margin-right: 25px; }
  .pr-sm-25 {
    padding-right: 25px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-26 {
    margin-right: 26px; }
  .pr-sm-26 {
    padding-right: 26px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-27 {
    margin-right: 27px; }
  .pr-sm-27 {
    padding-right: 27px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-28 {
    margin-right: 28px; }
  .pr-sm-28 {
    padding-right: 28px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-29 {
    margin-right: 29px; }
  .pr-sm-29 {
    padding-right: 29px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-30 {
    margin-right: 30px; }
  .pr-sm-30 {
    padding-right: 30px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-31 {
    margin-right: 31px; }
  .pr-sm-31 {
    padding-right: 31px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-32 {
    margin-right: 32px; }
  .pr-sm-32 {
    padding-right: 32px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-33 {
    margin-right: 33px; }
  .pr-sm-33 {
    padding-right: 33px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-34 {
    margin-right: 34px; }
  .pr-sm-34 {
    padding-right: 34px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-35 {
    margin-right: 35px; }
  .pr-sm-35 {
    padding-right: 35px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-36 {
    margin-right: 36px; }
  .pr-sm-36 {
    padding-right: 36px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-37 {
    margin-right: 37px; }
  .pr-sm-37 {
    padding-right: 37px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-38 {
    margin-right: 38px; }
  .pr-sm-38 {
    padding-right: 38px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-39 {
    margin-right: 39px; }
  .pr-sm-39 {
    padding-right: 39px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-40 {
    margin-right: 40px; }
  .pr-sm-40 {
    padding-right: 40px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-41 {
    margin-right: 41px; }
  .pr-sm-41 {
    padding-right: 41px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-42 {
    margin-right: 42px; }
  .pr-sm-42 {
    padding-right: 42px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-43 {
    margin-right: 43px; }
  .pr-sm-43 {
    padding-right: 43px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-44 {
    margin-right: 44px; }
  .pr-sm-44 {
    padding-right: 44px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-45 {
    margin-right: 45px; }
  .pr-sm-45 {
    padding-right: 45px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-46 {
    margin-right: 46px; }
  .pr-sm-46 {
    padding-right: 46px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-47 {
    margin-right: 47px; }
  .pr-sm-47 {
    padding-right: 47px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-48 {
    margin-right: 48px; }
  .pr-sm-48 {
    padding-right: 48px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-49 {
    margin-right: 49px; }
  .pr-sm-49 {
    padding-right: 49px; } }

@media only screen and (max-width: 768px) {
  .mr-sm-50 {
    margin-right: 50px; }
  .pr-sm-50 {
    padding-right: 50px; } }

@media only screen and (max-width: 992px) {
  .m-md-0 {
    margin: 0px; }
  .p-md-0 {
    padding: 0px; } }

@media only screen and (max-width: 992px) {
  .m-md-1 {
    margin: 1px; }
  .p-md-1 {
    padding: 1px; } }

@media only screen and (max-width: 992px) {
  .m-md-2 {
    margin: 2px; }
  .p-md-2 {
    padding: 2px; } }

@media only screen and (max-width: 992px) {
  .m-md-3 {
    margin: 3px; }
  .p-md-3 {
    padding: 3px; } }

@media only screen and (max-width: 992px) {
  .m-md-4 {
    margin: 4px; }
  .p-md-4 {
    padding: 4px; } }

@media only screen and (max-width: 992px) {
  .m-md-5 {
    margin: 5px; }
  .p-md-5 {
    padding: 5px; } }

@media only screen and (max-width: 992px) {
  .m-md-6 {
    margin: 6px; }
  .p-md-6 {
    padding: 6px; } }

@media only screen and (max-width: 992px) {
  .m-md-7 {
    margin: 7px; }
  .p-md-7 {
    padding: 7px; } }

@media only screen and (max-width: 992px) {
  .m-md-8 {
    margin: 8px; }
  .p-md-8 {
    padding: 8px; } }

@media only screen and (max-width: 992px) {
  .m-md-9 {
    margin: 9px; }
  .p-md-9 {
    padding: 9px; } }

@media only screen and (max-width: 992px) {
  .m-md-10 {
    margin: 10px; }
  .p-md-10 {
    padding: 10px; } }

@media only screen and (max-width: 992px) {
  .m-md-11 {
    margin: 11px; }
  .p-md-11 {
    padding: 11px; } }

@media only screen and (max-width: 992px) {
  .m-md-12 {
    margin: 12px; }
  .p-md-12 {
    padding: 12px; } }

@media only screen and (max-width: 992px) {
  .m-md-13 {
    margin: 13px; }
  .p-md-13 {
    padding: 13px; } }

@media only screen and (max-width: 992px) {
  .m-md-14 {
    margin: 14px; }
  .p-md-14 {
    padding: 14px; } }

@media only screen and (max-width: 992px) {
  .m-md-15 {
    margin: 15px; }
  .p-md-15 {
    padding: 15px; } }

@media only screen and (max-width: 992px) {
  .m-md-16 {
    margin: 16px; }
  .p-md-16 {
    padding: 16px; } }

@media only screen and (max-width: 992px) {
  .m-md-17 {
    margin: 17px; }
  .p-md-17 {
    padding: 17px; } }

@media only screen and (max-width: 992px) {
  .m-md-18 {
    margin: 18px; }
  .p-md-18 {
    padding: 18px; } }

@media only screen and (max-width: 992px) {
  .m-md-19 {
    margin: 19px; }
  .p-md-19 {
    padding: 19px; } }

@media only screen and (max-width: 992px) {
  .m-md-20 {
    margin: 20px; }
  .p-md-20 {
    padding: 20px; } }

@media only screen and (max-width: 992px) {
  .m-md-21 {
    margin: 21px; }
  .p-md-21 {
    padding: 21px; } }

@media only screen and (max-width: 992px) {
  .m-md-22 {
    margin: 22px; }
  .p-md-22 {
    padding: 22px; } }

@media only screen and (max-width: 992px) {
  .m-md-23 {
    margin: 23px; }
  .p-md-23 {
    padding: 23px; } }

@media only screen and (max-width: 992px) {
  .m-md-24 {
    margin: 24px; }
  .p-md-24 {
    padding: 24px; } }

@media only screen and (max-width: 992px) {
  .m-md-25 {
    margin: 25px; }
  .p-md-25 {
    padding: 25px; } }

@media only screen and (max-width: 992px) {
  .m-md-26 {
    margin: 26px; }
  .p-md-26 {
    padding: 26px; } }

@media only screen and (max-width: 992px) {
  .m-md-27 {
    margin: 27px; }
  .p-md-27 {
    padding: 27px; } }

@media only screen and (max-width: 992px) {
  .m-md-28 {
    margin: 28px; }
  .p-md-28 {
    padding: 28px; } }

@media only screen and (max-width: 992px) {
  .m-md-29 {
    margin: 29px; }
  .p-md-29 {
    padding: 29px; } }

@media only screen and (max-width: 992px) {
  .m-md-30 {
    margin: 30px; }
  .p-md-30 {
    padding: 30px; } }

@media only screen and (max-width: 992px) {
  .m-md-31 {
    margin: 31px; }
  .p-md-31 {
    padding: 31px; } }

@media only screen and (max-width: 992px) {
  .m-md-32 {
    margin: 32px; }
  .p-md-32 {
    padding: 32px; } }

@media only screen and (max-width: 992px) {
  .m-md-33 {
    margin: 33px; }
  .p-md-33 {
    padding: 33px; } }

@media only screen and (max-width: 992px) {
  .m-md-34 {
    margin: 34px; }
  .p-md-34 {
    padding: 34px; } }

@media only screen and (max-width: 992px) {
  .m-md-35 {
    margin: 35px; }
  .p-md-35 {
    padding: 35px; } }

@media only screen and (max-width: 992px) {
  .m-md-36 {
    margin: 36px; }
  .p-md-36 {
    padding: 36px; } }

@media only screen and (max-width: 992px) {
  .m-md-37 {
    margin: 37px; }
  .p-md-37 {
    padding: 37px; } }

@media only screen and (max-width: 992px) {
  .m-md-38 {
    margin: 38px; }
  .p-md-38 {
    padding: 38px; } }

@media only screen and (max-width: 992px) {
  .m-md-39 {
    margin: 39px; }
  .p-md-39 {
    padding: 39px; } }

@media only screen and (max-width: 992px) {
  .m-md-40 {
    margin: 40px; }
  .p-md-40 {
    padding: 40px; } }

@media only screen and (max-width: 992px) {
  .m-md-41 {
    margin: 41px; }
  .p-md-41 {
    padding: 41px; } }

@media only screen and (max-width: 992px) {
  .m-md-42 {
    margin: 42px; }
  .p-md-42 {
    padding: 42px; } }

@media only screen and (max-width: 992px) {
  .m-md-43 {
    margin: 43px; }
  .p-md-43 {
    padding: 43px; } }

@media only screen and (max-width: 992px) {
  .m-md-44 {
    margin: 44px; }
  .p-md-44 {
    padding: 44px; } }

@media only screen and (max-width: 992px) {
  .m-md-45 {
    margin: 45px; }
  .p-md-45 {
    padding: 45px; } }

@media only screen and (max-width: 992px) {
  .m-md-46 {
    margin: 46px; }
  .p-md-46 {
    padding: 46px; } }

@media only screen and (max-width: 992px) {
  .m-md-47 {
    margin: 47px; }
  .p-md-47 {
    padding: 47px; } }

@media only screen and (max-width: 992px) {
  .m-md-48 {
    margin: 48px; }
  .p-md-48 {
    padding: 48px; } }

@media only screen and (max-width: 992px) {
  .m-md-49 {
    margin: 49px; }
  .p-md-49 {
    padding: 49px; } }

@media only screen and (max-width: 992px) {
  .m-md-50 {
    margin: 50px; }
  .p-md-50 {
    padding: 50px; } }

@media only screen and (max-width: 992px) {
  .mt-md-0 {
    margin-top: 0px; }
  .pt-md-0 {
    padding-top: 0px; } }

@media only screen and (max-width: 992px) {
  .mt-md-1 {
    margin-top: 1px; }
  .pt-md-1 {
    padding-top: 1px; } }

@media only screen and (max-width: 992px) {
  .mt-md-2 {
    margin-top: 2px; }
  .pt-md-2 {
    padding-top: 2px; } }

@media only screen and (max-width: 992px) {
  .mt-md-3 {
    margin-top: 3px; }
  .pt-md-3 {
    padding-top: 3px; } }

@media only screen and (max-width: 992px) {
  .mt-md-4 {
    margin-top: 4px; }
  .pt-md-4 {
    padding-top: 4px; } }

@media only screen and (max-width: 992px) {
  .mt-md-5 {
    margin-top: 5px; }
  .pt-md-5 {
    padding-top: 5px; } }

@media only screen and (max-width: 992px) {
  .mt-md-6 {
    margin-top: 6px; }
  .pt-md-6 {
    padding-top: 6px; } }

@media only screen and (max-width: 992px) {
  .mt-md-7 {
    margin-top: 7px; }
  .pt-md-7 {
    padding-top: 7px; } }

@media only screen and (max-width: 992px) {
  .mt-md-8 {
    margin-top: 8px; }
  .pt-md-8 {
    padding-top: 8px; } }

@media only screen and (max-width: 992px) {
  .mt-md-9 {
    margin-top: 9px; }
  .pt-md-9 {
    padding-top: 9px; } }

@media only screen and (max-width: 992px) {
  .mt-md-10 {
    margin-top: 10px; }
  .pt-md-10 {
    padding-top: 10px; } }

@media only screen and (max-width: 992px) {
  .mt-md-11 {
    margin-top: 11px; }
  .pt-md-11 {
    padding-top: 11px; } }

@media only screen and (max-width: 992px) {
  .mt-md-12 {
    margin-top: 12px; }
  .pt-md-12 {
    padding-top: 12px; } }

@media only screen and (max-width: 992px) {
  .mt-md-13 {
    margin-top: 13px; }
  .pt-md-13 {
    padding-top: 13px; } }

@media only screen and (max-width: 992px) {
  .mt-md-14 {
    margin-top: 14px; }
  .pt-md-14 {
    padding-top: 14px; } }

@media only screen and (max-width: 992px) {
  .mt-md-15 {
    margin-top: 15px; }
  .pt-md-15 {
    padding-top: 15px; } }

@media only screen and (max-width: 992px) {
  .mt-md-16 {
    margin-top: 16px; }
  .pt-md-16 {
    padding-top: 16px; } }

@media only screen and (max-width: 992px) {
  .mt-md-17 {
    margin-top: 17px; }
  .pt-md-17 {
    padding-top: 17px; } }

@media only screen and (max-width: 992px) {
  .mt-md-18 {
    margin-top: 18px; }
  .pt-md-18 {
    padding-top: 18px; } }

@media only screen and (max-width: 992px) {
  .mt-md-19 {
    margin-top: 19px; }
  .pt-md-19 {
    padding-top: 19px; } }

@media only screen and (max-width: 992px) {
  .mt-md-20 {
    margin-top: 20px; }
  .pt-md-20 {
    padding-top: 20px; } }

@media only screen and (max-width: 992px) {
  .mt-md-21 {
    margin-top: 21px; }
  .pt-md-21 {
    padding-top: 21px; } }

@media only screen and (max-width: 992px) {
  .mt-md-22 {
    margin-top: 22px; }
  .pt-md-22 {
    padding-top: 22px; } }

@media only screen and (max-width: 992px) {
  .mt-md-23 {
    margin-top: 23px; }
  .pt-md-23 {
    padding-top: 23px; } }

@media only screen and (max-width: 992px) {
  .mt-md-24 {
    margin-top: 24px; }
  .pt-md-24 {
    padding-top: 24px; } }

@media only screen and (max-width: 992px) {
  .mt-md-25 {
    margin-top: 25px; }
  .pt-md-25 {
    padding-top: 25px; } }

@media only screen and (max-width: 992px) {
  .mt-md-26 {
    margin-top: 26px; }
  .pt-md-26 {
    padding-top: 26px; } }

@media only screen and (max-width: 992px) {
  .mt-md-27 {
    margin-top: 27px; }
  .pt-md-27 {
    padding-top: 27px; } }

@media only screen and (max-width: 992px) {
  .mt-md-28 {
    margin-top: 28px; }
  .pt-md-28 {
    padding-top: 28px; } }

@media only screen and (max-width: 992px) {
  .mt-md-29 {
    margin-top: 29px; }
  .pt-md-29 {
    padding-top: 29px; } }

@media only screen and (max-width: 992px) {
  .mt-md-30 {
    margin-top: 30px; }
  .pt-md-30 {
    padding-top: 30px; } }

@media only screen and (max-width: 992px) {
  .mt-md-31 {
    margin-top: 31px; }
  .pt-md-31 {
    padding-top: 31px; } }

@media only screen and (max-width: 992px) {
  .mt-md-32 {
    margin-top: 32px; }
  .pt-md-32 {
    padding-top: 32px; } }

@media only screen and (max-width: 992px) {
  .mt-md-33 {
    margin-top: 33px; }
  .pt-md-33 {
    padding-top: 33px; } }

@media only screen and (max-width: 992px) {
  .mt-md-34 {
    margin-top: 34px; }
  .pt-md-34 {
    padding-top: 34px; } }

@media only screen and (max-width: 992px) {
  .mt-md-35 {
    margin-top: 35px; }
  .pt-md-35 {
    padding-top: 35px; } }

@media only screen and (max-width: 992px) {
  .mt-md-36 {
    margin-top: 36px; }
  .pt-md-36 {
    padding-top: 36px; } }

@media only screen and (max-width: 992px) {
  .mt-md-37 {
    margin-top: 37px; }
  .pt-md-37 {
    padding-top: 37px; } }

@media only screen and (max-width: 992px) {
  .mt-md-38 {
    margin-top: 38px; }
  .pt-md-38 {
    padding-top: 38px; } }

@media only screen and (max-width: 992px) {
  .mt-md-39 {
    margin-top: 39px; }
  .pt-md-39 {
    padding-top: 39px; } }

@media only screen and (max-width: 992px) {
  .mt-md-40 {
    margin-top: 40px; }
  .pt-md-40 {
    padding-top: 40px; } }

@media only screen and (max-width: 992px) {
  .mt-md-41 {
    margin-top: 41px; }
  .pt-md-41 {
    padding-top: 41px; } }

@media only screen and (max-width: 992px) {
  .mt-md-42 {
    margin-top: 42px; }
  .pt-md-42 {
    padding-top: 42px; } }

@media only screen and (max-width: 992px) {
  .mt-md-43 {
    margin-top: 43px; }
  .pt-md-43 {
    padding-top: 43px; } }

@media only screen and (max-width: 992px) {
  .mt-md-44 {
    margin-top: 44px; }
  .pt-md-44 {
    padding-top: 44px; } }

@media only screen and (max-width: 992px) {
  .mt-md-45 {
    margin-top: 45px; }
  .pt-md-45 {
    padding-top: 45px; } }

@media only screen and (max-width: 992px) {
  .mt-md-46 {
    margin-top: 46px; }
  .pt-md-46 {
    padding-top: 46px; } }

@media only screen and (max-width: 992px) {
  .mt-md-47 {
    margin-top: 47px; }
  .pt-md-47 {
    padding-top: 47px; } }

@media only screen and (max-width: 992px) {
  .mt-md-48 {
    margin-top: 48px; }
  .pt-md-48 {
    padding-top: 48px; } }

@media only screen and (max-width: 992px) {
  .mt-md-49 {
    margin-top: 49px; }
  .pt-md-49 {
    padding-top: 49px; } }

@media only screen and (max-width: 992px) {
  .mt-md-50 {
    margin-top: 50px; }
  .pt-md-50 {
    padding-top: 50px; } }

@media only screen and (max-width: 992px) {
  .mb-md-0 {
    margin-bottom: 0px; }
  .pb-md-0 {
    padding-bottom: 0px; } }

@media only screen and (max-width: 992px) {
  .mb-md-1 {
    margin-bottom: 1px; }
  .pb-md-1 {
    padding-bottom: 1px; } }

@media only screen and (max-width: 992px) {
  .mb-md-2 {
    margin-bottom: 2px; }
  .pb-md-2 {
    padding-bottom: 2px; } }

@media only screen and (max-width: 992px) {
  .mb-md-3 {
    margin-bottom: 3px; }
  .pb-md-3 {
    padding-bottom: 3px; } }

@media only screen and (max-width: 992px) {
  .mb-md-4 {
    margin-bottom: 4px; }
  .pb-md-4 {
    padding-bottom: 4px; } }

@media only screen and (max-width: 992px) {
  .mb-md-5 {
    margin-bottom: 5px; }
  .pb-md-5 {
    padding-bottom: 5px; } }

@media only screen and (max-width: 992px) {
  .mb-md-6 {
    margin-bottom: 6px; }
  .pb-md-6 {
    padding-bottom: 6px; } }

@media only screen and (max-width: 992px) {
  .mb-md-7 {
    margin-bottom: 7px; }
  .pb-md-7 {
    padding-bottom: 7px; } }

@media only screen and (max-width: 992px) {
  .mb-md-8 {
    margin-bottom: 8px; }
  .pb-md-8 {
    padding-bottom: 8px; } }

@media only screen and (max-width: 992px) {
  .mb-md-9 {
    margin-bottom: 9px; }
  .pb-md-9 {
    padding-bottom: 9px; } }

@media only screen and (max-width: 992px) {
  .mb-md-10 {
    margin-bottom: 10px; }
  .pb-md-10 {
    padding-bottom: 10px; } }

@media only screen and (max-width: 992px) {
  .mb-md-11 {
    margin-bottom: 11px; }
  .pb-md-11 {
    padding-bottom: 11px; } }

@media only screen and (max-width: 992px) {
  .mb-md-12 {
    margin-bottom: 12px; }
  .pb-md-12 {
    padding-bottom: 12px; } }

@media only screen and (max-width: 992px) {
  .mb-md-13 {
    margin-bottom: 13px; }
  .pb-md-13 {
    padding-bottom: 13px; } }

@media only screen and (max-width: 992px) {
  .mb-md-14 {
    margin-bottom: 14px; }
  .pb-md-14 {
    padding-bottom: 14px; } }

@media only screen and (max-width: 992px) {
  .mb-md-15 {
    margin-bottom: 15px; }
  .pb-md-15 {
    padding-bottom: 15px; } }

@media only screen and (max-width: 992px) {
  .mb-md-16 {
    margin-bottom: 16px; }
  .pb-md-16 {
    padding-bottom: 16px; } }

@media only screen and (max-width: 992px) {
  .mb-md-17 {
    margin-bottom: 17px; }
  .pb-md-17 {
    padding-bottom: 17px; } }

@media only screen and (max-width: 992px) {
  .mb-md-18 {
    margin-bottom: 18px; }
  .pb-md-18 {
    padding-bottom: 18px; } }

@media only screen and (max-width: 992px) {
  .mb-md-19 {
    margin-bottom: 19px; }
  .pb-md-19 {
    padding-bottom: 19px; } }

@media only screen and (max-width: 992px) {
  .mb-md-20 {
    margin-bottom: 20px; }
  .pb-md-20 {
    padding-bottom: 20px; } }

@media only screen and (max-width: 992px) {
  .mb-md-21 {
    margin-bottom: 21px; }
  .pb-md-21 {
    padding-bottom: 21px; } }

@media only screen and (max-width: 992px) {
  .mb-md-22 {
    margin-bottom: 22px; }
  .pb-md-22 {
    padding-bottom: 22px; } }

@media only screen and (max-width: 992px) {
  .mb-md-23 {
    margin-bottom: 23px; }
  .pb-md-23 {
    padding-bottom: 23px; } }

@media only screen and (max-width: 992px) {
  .mb-md-24 {
    margin-bottom: 24px; }
  .pb-md-24 {
    padding-bottom: 24px; } }

@media only screen and (max-width: 992px) {
  .mb-md-25 {
    margin-bottom: 25px; }
  .pb-md-25 {
    padding-bottom: 25px; } }

@media only screen and (max-width: 992px) {
  .mb-md-26 {
    margin-bottom: 26px; }
  .pb-md-26 {
    padding-bottom: 26px; } }

@media only screen and (max-width: 992px) {
  .mb-md-27 {
    margin-bottom: 27px; }
  .pb-md-27 {
    padding-bottom: 27px; } }

@media only screen and (max-width: 992px) {
  .mb-md-28 {
    margin-bottom: 28px; }
  .pb-md-28 {
    padding-bottom: 28px; } }

@media only screen and (max-width: 992px) {
  .mb-md-29 {
    margin-bottom: 29px; }
  .pb-md-29 {
    padding-bottom: 29px; } }

@media only screen and (max-width: 992px) {
  .mb-md-30 {
    margin-bottom: 30px; }
  .pb-md-30 {
    padding-bottom: 30px; } }

@media only screen and (max-width: 992px) {
  .mb-md-31 {
    margin-bottom: 31px; }
  .pb-md-31 {
    padding-bottom: 31px; } }

@media only screen and (max-width: 992px) {
  .mb-md-32 {
    margin-bottom: 32px; }
  .pb-md-32 {
    padding-bottom: 32px; } }

@media only screen and (max-width: 992px) {
  .mb-md-33 {
    margin-bottom: 33px; }
  .pb-md-33 {
    padding-bottom: 33px; } }

@media only screen and (max-width: 992px) {
  .mb-md-34 {
    margin-bottom: 34px; }
  .pb-md-34 {
    padding-bottom: 34px; } }

@media only screen and (max-width: 992px) {
  .mb-md-35 {
    margin-bottom: 35px; }
  .pb-md-35 {
    padding-bottom: 35px; } }

@media only screen and (max-width: 992px) {
  .mb-md-36 {
    margin-bottom: 36px; }
  .pb-md-36 {
    padding-bottom: 36px; } }

@media only screen and (max-width: 992px) {
  .mb-md-37 {
    margin-bottom: 37px; }
  .pb-md-37 {
    padding-bottom: 37px; } }

@media only screen and (max-width: 992px) {
  .mb-md-38 {
    margin-bottom: 38px; }
  .pb-md-38 {
    padding-bottom: 38px; } }

@media only screen and (max-width: 992px) {
  .mb-md-39 {
    margin-bottom: 39px; }
  .pb-md-39 {
    padding-bottom: 39px; } }

@media only screen and (max-width: 992px) {
  .mb-md-40 {
    margin-bottom: 40px; }
  .pb-md-40 {
    padding-bottom: 40px; } }

@media only screen and (max-width: 992px) {
  .mb-md-41 {
    margin-bottom: 41px; }
  .pb-md-41 {
    padding-bottom: 41px; } }

@media only screen and (max-width: 992px) {
  .mb-md-42 {
    margin-bottom: 42px; }
  .pb-md-42 {
    padding-bottom: 42px; } }

@media only screen and (max-width: 992px) {
  .mb-md-43 {
    margin-bottom: 43px; }
  .pb-md-43 {
    padding-bottom: 43px; } }

@media only screen and (max-width: 992px) {
  .mb-md-44 {
    margin-bottom: 44px; }
  .pb-md-44 {
    padding-bottom: 44px; } }

@media only screen and (max-width: 992px) {
  .mb-md-45 {
    margin-bottom: 45px; }
  .pb-md-45 {
    padding-bottom: 45px; } }

@media only screen and (max-width: 992px) {
  .mb-md-46 {
    margin-bottom: 46px; }
  .pb-md-46 {
    padding-bottom: 46px; } }

@media only screen and (max-width: 992px) {
  .mb-md-47 {
    margin-bottom: 47px; }
  .pb-md-47 {
    padding-bottom: 47px; } }

@media only screen and (max-width: 992px) {
  .mb-md-48 {
    margin-bottom: 48px; }
  .pb-md-48 {
    padding-bottom: 48px; } }

@media only screen and (max-width: 992px) {
  .mb-md-49 {
    margin-bottom: 49px; }
  .pb-md-49 {
    padding-bottom: 49px; } }

@media only screen and (max-width: 992px) {
  .mb-md-50 {
    margin-bottom: 50px; }
  .pb-md-50 {
    padding-bottom: 50px; } }

@media only screen and (max-width: 992px) {
  .ml-md-0 {
    margin-left: 0px; }
  .pl-md-0 {
    padding-left: 0px; } }

@media only screen and (max-width: 992px) {
  .ml-md-1 {
    margin-left: 1px; }
  .pl-md-1 {
    padding-left: 1px; } }

@media only screen and (max-width: 992px) {
  .ml-md-2 {
    margin-left: 2px; }
  .pl-md-2 {
    padding-left: 2px; } }

@media only screen and (max-width: 992px) {
  .ml-md-3 {
    margin-left: 3px; }
  .pl-md-3 {
    padding-left: 3px; } }

@media only screen and (max-width: 992px) {
  .ml-md-4 {
    margin-left: 4px; }
  .pl-md-4 {
    padding-left: 4px; } }

@media only screen and (max-width: 992px) {
  .ml-md-5 {
    margin-left: 5px; }
  .pl-md-5 {
    padding-left: 5px; } }

@media only screen and (max-width: 992px) {
  .ml-md-6 {
    margin-left: 6px; }
  .pl-md-6 {
    padding-left: 6px; } }

@media only screen and (max-width: 992px) {
  .ml-md-7 {
    margin-left: 7px; }
  .pl-md-7 {
    padding-left: 7px; } }

@media only screen and (max-width: 992px) {
  .ml-md-8 {
    margin-left: 8px; }
  .pl-md-8 {
    padding-left: 8px; } }

@media only screen and (max-width: 992px) {
  .ml-md-9 {
    margin-left: 9px; }
  .pl-md-9 {
    padding-left: 9px; } }

@media only screen and (max-width: 992px) {
  .ml-md-10 {
    margin-left: 10px; }
  .pl-md-10 {
    padding-left: 10px; } }

@media only screen and (max-width: 992px) {
  .ml-md-11 {
    margin-left: 11px; }
  .pl-md-11 {
    padding-left: 11px; } }

@media only screen and (max-width: 992px) {
  .ml-md-12 {
    margin-left: 12px; }
  .pl-md-12 {
    padding-left: 12px; } }

@media only screen and (max-width: 992px) {
  .ml-md-13 {
    margin-left: 13px; }
  .pl-md-13 {
    padding-left: 13px; } }

@media only screen and (max-width: 992px) {
  .ml-md-14 {
    margin-left: 14px; }
  .pl-md-14 {
    padding-left: 14px; } }

@media only screen and (max-width: 992px) {
  .ml-md-15 {
    margin-left: 15px; }
  .pl-md-15 {
    padding-left: 15px; } }

@media only screen and (max-width: 992px) {
  .ml-md-16 {
    margin-left: 16px; }
  .pl-md-16 {
    padding-left: 16px; } }

@media only screen and (max-width: 992px) {
  .ml-md-17 {
    margin-left: 17px; }
  .pl-md-17 {
    padding-left: 17px; } }

@media only screen and (max-width: 992px) {
  .ml-md-18 {
    margin-left: 18px; }
  .pl-md-18 {
    padding-left: 18px; } }

@media only screen and (max-width: 992px) {
  .ml-md-19 {
    margin-left: 19px; }
  .pl-md-19 {
    padding-left: 19px; } }

@media only screen and (max-width: 992px) {
  .ml-md-20 {
    margin-left: 20px; }
  .pl-md-20 {
    padding-left: 20px; } }

@media only screen and (max-width: 992px) {
  .ml-md-21 {
    margin-left: 21px; }
  .pl-md-21 {
    padding-left: 21px; } }

@media only screen and (max-width: 992px) {
  .ml-md-22 {
    margin-left: 22px; }
  .pl-md-22 {
    padding-left: 22px; } }

@media only screen and (max-width: 992px) {
  .ml-md-23 {
    margin-left: 23px; }
  .pl-md-23 {
    padding-left: 23px; } }

@media only screen and (max-width: 992px) {
  .ml-md-24 {
    margin-left: 24px; }
  .pl-md-24 {
    padding-left: 24px; } }

@media only screen and (max-width: 992px) {
  .ml-md-25 {
    margin-left: 25px; }
  .pl-md-25 {
    padding-left: 25px; } }

@media only screen and (max-width: 992px) {
  .ml-md-26 {
    margin-left: 26px; }
  .pl-md-26 {
    padding-left: 26px; } }

@media only screen and (max-width: 992px) {
  .ml-md-27 {
    margin-left: 27px; }
  .pl-md-27 {
    padding-left: 27px; } }

@media only screen and (max-width: 992px) {
  .ml-md-28 {
    margin-left: 28px; }
  .pl-md-28 {
    padding-left: 28px; } }

@media only screen and (max-width: 992px) {
  .ml-md-29 {
    margin-left: 29px; }
  .pl-md-29 {
    padding-left: 29px; } }

@media only screen and (max-width: 992px) {
  .ml-md-30 {
    margin-left: 30px; }
  .pl-md-30 {
    padding-left: 30px; } }

@media only screen and (max-width: 992px) {
  .ml-md-31 {
    margin-left: 31px; }
  .pl-md-31 {
    padding-left: 31px; } }

@media only screen and (max-width: 992px) {
  .ml-md-32 {
    margin-left: 32px; }
  .pl-md-32 {
    padding-left: 32px; } }

@media only screen and (max-width: 992px) {
  .ml-md-33 {
    margin-left: 33px; }
  .pl-md-33 {
    padding-left: 33px; } }

@media only screen and (max-width: 992px) {
  .ml-md-34 {
    margin-left: 34px; }
  .pl-md-34 {
    padding-left: 34px; } }

@media only screen and (max-width: 992px) {
  .ml-md-35 {
    margin-left: 35px; }
  .pl-md-35 {
    padding-left: 35px; } }

@media only screen and (max-width: 992px) {
  .ml-md-36 {
    margin-left: 36px; }
  .pl-md-36 {
    padding-left: 36px; } }

@media only screen and (max-width: 992px) {
  .ml-md-37 {
    margin-left: 37px; }
  .pl-md-37 {
    padding-left: 37px; } }

@media only screen and (max-width: 992px) {
  .ml-md-38 {
    margin-left: 38px; }
  .pl-md-38 {
    padding-left: 38px; } }

@media only screen and (max-width: 992px) {
  .ml-md-39 {
    margin-left: 39px; }
  .pl-md-39 {
    padding-left: 39px; } }

@media only screen and (max-width: 992px) {
  .ml-md-40 {
    margin-left: 40px; }
  .pl-md-40 {
    padding-left: 40px; } }

@media only screen and (max-width: 992px) {
  .ml-md-41 {
    margin-left: 41px; }
  .pl-md-41 {
    padding-left: 41px; } }

@media only screen and (max-width: 992px) {
  .ml-md-42 {
    margin-left: 42px; }
  .pl-md-42 {
    padding-left: 42px; } }

@media only screen and (max-width: 992px) {
  .ml-md-43 {
    margin-left: 43px; }
  .pl-md-43 {
    padding-left: 43px; } }

@media only screen and (max-width: 992px) {
  .ml-md-44 {
    margin-left: 44px; }
  .pl-md-44 {
    padding-left: 44px; } }

@media only screen and (max-width: 992px) {
  .ml-md-45 {
    margin-left: 45px; }
  .pl-md-45 {
    padding-left: 45px; } }

@media only screen and (max-width: 992px) {
  .ml-md-46 {
    margin-left: 46px; }
  .pl-md-46 {
    padding-left: 46px; } }

@media only screen and (max-width: 992px) {
  .ml-md-47 {
    margin-left: 47px; }
  .pl-md-47 {
    padding-left: 47px; } }

@media only screen and (max-width: 992px) {
  .ml-md-48 {
    margin-left: 48px; }
  .pl-md-48 {
    padding-left: 48px; } }

@media only screen and (max-width: 992px) {
  .ml-md-49 {
    margin-left: 49px; }
  .pl-md-49 {
    padding-left: 49px; } }

@media only screen and (max-width: 992px) {
  .ml-md-50 {
    margin-left: 50px; }
  .pl-md-50 {
    padding-left: 50px; } }

@media only screen and (max-width: 992px) {
  .mr-md-0 {
    margin-right: 0px; }
  .pr-md-0 {
    padding-right: 0px; } }

@media only screen and (max-width: 992px) {
  .mr-md-1 {
    margin-right: 1px; }
  .pr-md-1 {
    padding-right: 1px; } }

@media only screen and (max-width: 992px) {
  .mr-md-2 {
    margin-right: 2px; }
  .pr-md-2 {
    padding-right: 2px; } }

@media only screen and (max-width: 992px) {
  .mr-md-3 {
    margin-right: 3px; }
  .pr-md-3 {
    padding-right: 3px; } }

@media only screen and (max-width: 992px) {
  .mr-md-4 {
    margin-right: 4px; }
  .pr-md-4 {
    padding-right: 4px; } }

@media only screen and (max-width: 992px) {
  .mr-md-5 {
    margin-right: 5px; }
  .pr-md-5 {
    padding-right: 5px; } }

@media only screen and (max-width: 992px) {
  .mr-md-6 {
    margin-right: 6px; }
  .pr-md-6 {
    padding-right: 6px; } }

@media only screen and (max-width: 992px) {
  .mr-md-7 {
    margin-right: 7px; }
  .pr-md-7 {
    padding-right: 7px; } }

@media only screen and (max-width: 992px) {
  .mr-md-8 {
    margin-right: 8px; }
  .pr-md-8 {
    padding-right: 8px; } }

@media only screen and (max-width: 992px) {
  .mr-md-9 {
    margin-right: 9px; }
  .pr-md-9 {
    padding-right: 9px; } }

@media only screen and (max-width: 992px) {
  .mr-md-10 {
    margin-right: 10px; }
  .pr-md-10 {
    padding-right: 10px; } }

@media only screen and (max-width: 992px) {
  .mr-md-11 {
    margin-right: 11px; }
  .pr-md-11 {
    padding-right: 11px; } }

@media only screen and (max-width: 992px) {
  .mr-md-12 {
    margin-right: 12px; }
  .pr-md-12 {
    padding-right: 12px; } }

@media only screen and (max-width: 992px) {
  .mr-md-13 {
    margin-right: 13px; }
  .pr-md-13 {
    padding-right: 13px; } }

@media only screen and (max-width: 992px) {
  .mr-md-14 {
    margin-right: 14px; }
  .pr-md-14 {
    padding-right: 14px; } }

@media only screen and (max-width: 992px) {
  .mr-md-15 {
    margin-right: 15px; }
  .pr-md-15 {
    padding-right: 15px; } }

@media only screen and (max-width: 992px) {
  .mr-md-16 {
    margin-right: 16px; }
  .pr-md-16 {
    padding-right: 16px; } }

@media only screen and (max-width: 992px) {
  .mr-md-17 {
    margin-right: 17px; }
  .pr-md-17 {
    padding-right: 17px; } }

@media only screen and (max-width: 992px) {
  .mr-md-18 {
    margin-right: 18px; }
  .pr-md-18 {
    padding-right: 18px; } }

@media only screen and (max-width: 992px) {
  .mr-md-19 {
    margin-right: 19px; }
  .pr-md-19 {
    padding-right: 19px; } }

@media only screen and (max-width: 992px) {
  .mr-md-20 {
    margin-right: 20px; }
  .pr-md-20 {
    padding-right: 20px; } }

@media only screen and (max-width: 992px) {
  .mr-md-21 {
    margin-right: 21px; }
  .pr-md-21 {
    padding-right: 21px; } }

@media only screen and (max-width: 992px) {
  .mr-md-22 {
    margin-right: 22px; }
  .pr-md-22 {
    padding-right: 22px; } }

@media only screen and (max-width: 992px) {
  .mr-md-23 {
    margin-right: 23px; }
  .pr-md-23 {
    padding-right: 23px; } }

@media only screen and (max-width: 992px) {
  .mr-md-24 {
    margin-right: 24px; }
  .pr-md-24 {
    padding-right: 24px; } }

@media only screen and (max-width: 992px) {
  .mr-md-25 {
    margin-right: 25px; }
  .pr-md-25 {
    padding-right: 25px; } }

@media only screen and (max-width: 992px) {
  .mr-md-26 {
    margin-right: 26px; }
  .pr-md-26 {
    padding-right: 26px; } }

@media only screen and (max-width: 992px) {
  .mr-md-27 {
    margin-right: 27px; }
  .pr-md-27 {
    padding-right: 27px; } }

@media only screen and (max-width: 992px) {
  .mr-md-28 {
    margin-right: 28px; }
  .pr-md-28 {
    padding-right: 28px; } }

@media only screen and (max-width: 992px) {
  .mr-md-29 {
    margin-right: 29px; }
  .pr-md-29 {
    padding-right: 29px; } }

@media only screen and (max-width: 992px) {
  .mr-md-30 {
    margin-right: 30px; }
  .pr-md-30 {
    padding-right: 30px; } }

@media only screen and (max-width: 992px) {
  .mr-md-31 {
    margin-right: 31px; }
  .pr-md-31 {
    padding-right: 31px; } }

@media only screen and (max-width: 992px) {
  .mr-md-32 {
    margin-right: 32px; }
  .pr-md-32 {
    padding-right: 32px; } }

@media only screen and (max-width: 992px) {
  .mr-md-33 {
    margin-right: 33px; }
  .pr-md-33 {
    padding-right: 33px; } }

@media only screen and (max-width: 992px) {
  .mr-md-34 {
    margin-right: 34px; }
  .pr-md-34 {
    padding-right: 34px; } }

@media only screen and (max-width: 992px) {
  .mr-md-35 {
    margin-right: 35px; }
  .pr-md-35 {
    padding-right: 35px; } }

@media only screen and (max-width: 992px) {
  .mr-md-36 {
    margin-right: 36px; }
  .pr-md-36 {
    padding-right: 36px; } }

@media only screen and (max-width: 992px) {
  .mr-md-37 {
    margin-right: 37px; }
  .pr-md-37 {
    padding-right: 37px; } }

@media only screen and (max-width: 992px) {
  .mr-md-38 {
    margin-right: 38px; }
  .pr-md-38 {
    padding-right: 38px; } }

@media only screen and (max-width: 992px) {
  .mr-md-39 {
    margin-right: 39px; }
  .pr-md-39 {
    padding-right: 39px; } }

@media only screen and (max-width: 992px) {
  .mr-md-40 {
    margin-right: 40px; }
  .pr-md-40 {
    padding-right: 40px; } }

@media only screen and (max-width: 992px) {
  .mr-md-41 {
    margin-right: 41px; }
  .pr-md-41 {
    padding-right: 41px; } }

@media only screen and (max-width: 992px) {
  .mr-md-42 {
    margin-right: 42px; }
  .pr-md-42 {
    padding-right: 42px; } }

@media only screen and (max-width: 992px) {
  .mr-md-43 {
    margin-right: 43px; }
  .pr-md-43 {
    padding-right: 43px; } }

@media only screen and (max-width: 992px) {
  .mr-md-44 {
    margin-right: 44px; }
  .pr-md-44 {
    padding-right: 44px; } }

@media only screen and (max-width: 992px) {
  .mr-md-45 {
    margin-right: 45px; }
  .pr-md-45 {
    padding-right: 45px; } }

@media only screen and (max-width: 992px) {
  .mr-md-46 {
    margin-right: 46px; }
  .pr-md-46 {
    padding-right: 46px; } }

@media only screen and (max-width: 992px) {
  .mr-md-47 {
    margin-right: 47px; }
  .pr-md-47 {
    padding-right: 47px; } }

@media only screen and (max-width: 992px) {
  .mr-md-48 {
    margin-right: 48px; }
  .pr-md-48 {
    padding-right: 48px; } }

@media only screen and (max-width: 992px) {
  .mr-md-49 {
    margin-right: 49px; }
  .pr-md-49 {
    padding-right: 49px; } }

@media only screen and (max-width: 992px) {
  .mr-md-50 {
    margin-right: 50px; }
  .pr-md-50 {
    padding-right: 50px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-0 {
    margin: 0px; }
  .p-lg-0 {
    padding: 0px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-1 {
    margin: 1px; }
  .p-lg-1 {
    padding: 1px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-2 {
    margin: 2px; }
  .p-lg-2 {
    padding: 2px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-3 {
    margin: 3px; }
  .p-lg-3 {
    padding: 3px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-4 {
    margin: 4px; }
  .p-lg-4 {
    padding: 4px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-5 {
    margin: 5px; }
  .p-lg-5 {
    padding: 5px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-6 {
    margin: 6px; }
  .p-lg-6 {
    padding: 6px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-7 {
    margin: 7px; }
  .p-lg-7 {
    padding: 7px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-8 {
    margin: 8px; }
  .p-lg-8 {
    padding: 8px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-9 {
    margin: 9px; }
  .p-lg-9 {
    padding: 9px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-10 {
    margin: 10px; }
  .p-lg-10 {
    padding: 10px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-11 {
    margin: 11px; }
  .p-lg-11 {
    padding: 11px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-12 {
    margin: 12px; }
  .p-lg-12 {
    padding: 12px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-13 {
    margin: 13px; }
  .p-lg-13 {
    padding: 13px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-14 {
    margin: 14px; }
  .p-lg-14 {
    padding: 14px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-15 {
    margin: 15px; }
  .p-lg-15 {
    padding: 15px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-16 {
    margin: 16px; }
  .p-lg-16 {
    padding: 16px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-17 {
    margin: 17px; }
  .p-lg-17 {
    padding: 17px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-18 {
    margin: 18px; }
  .p-lg-18 {
    padding: 18px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-19 {
    margin: 19px; }
  .p-lg-19 {
    padding: 19px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-20 {
    margin: 20px; }
  .p-lg-20 {
    padding: 20px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-21 {
    margin: 21px; }
  .p-lg-21 {
    padding: 21px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-22 {
    margin: 22px; }
  .p-lg-22 {
    padding: 22px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-23 {
    margin: 23px; }
  .p-lg-23 {
    padding: 23px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-24 {
    margin: 24px; }
  .p-lg-24 {
    padding: 24px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-25 {
    margin: 25px; }
  .p-lg-25 {
    padding: 25px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-26 {
    margin: 26px; }
  .p-lg-26 {
    padding: 26px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-27 {
    margin: 27px; }
  .p-lg-27 {
    padding: 27px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-28 {
    margin: 28px; }
  .p-lg-28 {
    padding: 28px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-29 {
    margin: 29px; }
  .p-lg-29 {
    padding: 29px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-30 {
    margin: 30px; }
  .p-lg-30 {
    padding: 30px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-31 {
    margin: 31px; }
  .p-lg-31 {
    padding: 31px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-32 {
    margin: 32px; }
  .p-lg-32 {
    padding: 32px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-33 {
    margin: 33px; }
  .p-lg-33 {
    padding: 33px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-34 {
    margin: 34px; }
  .p-lg-34 {
    padding: 34px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-35 {
    margin: 35px; }
  .p-lg-35 {
    padding: 35px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-36 {
    margin: 36px; }
  .p-lg-36 {
    padding: 36px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-37 {
    margin: 37px; }
  .p-lg-37 {
    padding: 37px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-38 {
    margin: 38px; }
  .p-lg-38 {
    padding: 38px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-39 {
    margin: 39px; }
  .p-lg-39 {
    padding: 39px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-40 {
    margin: 40px; }
  .p-lg-40 {
    padding: 40px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-41 {
    margin: 41px; }
  .p-lg-41 {
    padding: 41px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-42 {
    margin: 42px; }
  .p-lg-42 {
    padding: 42px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-43 {
    margin: 43px; }
  .p-lg-43 {
    padding: 43px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-44 {
    margin: 44px; }
  .p-lg-44 {
    padding: 44px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-45 {
    margin: 45px; }
  .p-lg-45 {
    padding: 45px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-46 {
    margin: 46px; }
  .p-lg-46 {
    padding: 46px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-47 {
    margin: 47px; }
  .p-lg-47 {
    padding: 47px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-48 {
    margin: 48px; }
  .p-lg-48 {
    padding: 48px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-49 {
    margin: 49px; }
  .p-lg-49 {
    padding: 49px; } }

@media only screen and (max-width: 1200px) {
  .m-lg-50 {
    margin: 50px; }
  .p-lg-50 {
    padding: 50px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-0 {
    margin-top: 0px; }
  .pt-lg-0 {
    padding-top: 0px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-1 {
    margin-top: 1px; }
  .pt-lg-1 {
    padding-top: 1px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-2 {
    margin-top: 2px; }
  .pt-lg-2 {
    padding-top: 2px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-3 {
    margin-top: 3px; }
  .pt-lg-3 {
    padding-top: 3px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-4 {
    margin-top: 4px; }
  .pt-lg-4 {
    padding-top: 4px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-5 {
    margin-top: 5px; }
  .pt-lg-5 {
    padding-top: 5px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-6 {
    margin-top: 6px; }
  .pt-lg-6 {
    padding-top: 6px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-7 {
    margin-top: 7px; }
  .pt-lg-7 {
    padding-top: 7px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-8 {
    margin-top: 8px; }
  .pt-lg-8 {
    padding-top: 8px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-9 {
    margin-top: 9px; }
  .pt-lg-9 {
    padding-top: 9px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-10 {
    margin-top: 10px; }
  .pt-lg-10 {
    padding-top: 10px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-11 {
    margin-top: 11px; }
  .pt-lg-11 {
    padding-top: 11px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-12 {
    margin-top: 12px; }
  .pt-lg-12 {
    padding-top: 12px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-13 {
    margin-top: 13px; }
  .pt-lg-13 {
    padding-top: 13px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-14 {
    margin-top: 14px; }
  .pt-lg-14 {
    padding-top: 14px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-15 {
    margin-top: 15px; }
  .pt-lg-15 {
    padding-top: 15px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-16 {
    margin-top: 16px; }
  .pt-lg-16 {
    padding-top: 16px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-17 {
    margin-top: 17px; }
  .pt-lg-17 {
    padding-top: 17px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-18 {
    margin-top: 18px; }
  .pt-lg-18 {
    padding-top: 18px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-19 {
    margin-top: 19px; }
  .pt-lg-19 {
    padding-top: 19px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-20 {
    margin-top: 20px; }
  .pt-lg-20 {
    padding-top: 20px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-21 {
    margin-top: 21px; }
  .pt-lg-21 {
    padding-top: 21px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-22 {
    margin-top: 22px; }
  .pt-lg-22 {
    padding-top: 22px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-23 {
    margin-top: 23px; }
  .pt-lg-23 {
    padding-top: 23px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-24 {
    margin-top: 24px; }
  .pt-lg-24 {
    padding-top: 24px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-25 {
    margin-top: 25px; }
  .pt-lg-25 {
    padding-top: 25px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-26 {
    margin-top: 26px; }
  .pt-lg-26 {
    padding-top: 26px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-27 {
    margin-top: 27px; }
  .pt-lg-27 {
    padding-top: 27px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-28 {
    margin-top: 28px; }
  .pt-lg-28 {
    padding-top: 28px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-29 {
    margin-top: 29px; }
  .pt-lg-29 {
    padding-top: 29px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-30 {
    margin-top: 30px; }
  .pt-lg-30 {
    padding-top: 30px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-31 {
    margin-top: 31px; }
  .pt-lg-31 {
    padding-top: 31px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-32 {
    margin-top: 32px; }
  .pt-lg-32 {
    padding-top: 32px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-33 {
    margin-top: 33px; }
  .pt-lg-33 {
    padding-top: 33px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-34 {
    margin-top: 34px; }
  .pt-lg-34 {
    padding-top: 34px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-35 {
    margin-top: 35px; }
  .pt-lg-35 {
    padding-top: 35px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-36 {
    margin-top: 36px; }
  .pt-lg-36 {
    padding-top: 36px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-37 {
    margin-top: 37px; }
  .pt-lg-37 {
    padding-top: 37px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-38 {
    margin-top: 38px; }
  .pt-lg-38 {
    padding-top: 38px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-39 {
    margin-top: 39px; }
  .pt-lg-39 {
    padding-top: 39px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-40 {
    margin-top: 40px; }
  .pt-lg-40 {
    padding-top: 40px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-41 {
    margin-top: 41px; }
  .pt-lg-41 {
    padding-top: 41px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-42 {
    margin-top: 42px; }
  .pt-lg-42 {
    padding-top: 42px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-43 {
    margin-top: 43px; }
  .pt-lg-43 {
    padding-top: 43px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-44 {
    margin-top: 44px; }
  .pt-lg-44 {
    padding-top: 44px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-45 {
    margin-top: 45px; }
  .pt-lg-45 {
    padding-top: 45px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-46 {
    margin-top: 46px; }
  .pt-lg-46 {
    padding-top: 46px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-47 {
    margin-top: 47px; }
  .pt-lg-47 {
    padding-top: 47px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-48 {
    margin-top: 48px; }
  .pt-lg-48 {
    padding-top: 48px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-49 {
    margin-top: 49px; }
  .pt-lg-49 {
    padding-top: 49px; } }

@media only screen and (max-width: 1200px) {
  .mt-lg-50 {
    margin-top: 50px; }
  .pt-lg-50 {
    padding-top: 50px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-0 {
    margin-bottom: 0px; }
  .pb-lg-0 {
    padding-bottom: 0px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-1 {
    margin-bottom: 1px; }
  .pb-lg-1 {
    padding-bottom: 1px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-2 {
    margin-bottom: 2px; }
  .pb-lg-2 {
    padding-bottom: 2px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-3 {
    margin-bottom: 3px; }
  .pb-lg-3 {
    padding-bottom: 3px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-4 {
    margin-bottom: 4px; }
  .pb-lg-4 {
    padding-bottom: 4px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-5 {
    margin-bottom: 5px; }
  .pb-lg-5 {
    padding-bottom: 5px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-6 {
    margin-bottom: 6px; }
  .pb-lg-6 {
    padding-bottom: 6px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-7 {
    margin-bottom: 7px; }
  .pb-lg-7 {
    padding-bottom: 7px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-8 {
    margin-bottom: 8px; }
  .pb-lg-8 {
    padding-bottom: 8px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-9 {
    margin-bottom: 9px; }
  .pb-lg-9 {
    padding-bottom: 9px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-10 {
    margin-bottom: 10px; }
  .pb-lg-10 {
    padding-bottom: 10px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-11 {
    margin-bottom: 11px; }
  .pb-lg-11 {
    padding-bottom: 11px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-12 {
    margin-bottom: 12px; }
  .pb-lg-12 {
    padding-bottom: 12px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-13 {
    margin-bottom: 13px; }
  .pb-lg-13 {
    padding-bottom: 13px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-14 {
    margin-bottom: 14px; }
  .pb-lg-14 {
    padding-bottom: 14px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-15 {
    margin-bottom: 15px; }
  .pb-lg-15 {
    padding-bottom: 15px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-16 {
    margin-bottom: 16px; }
  .pb-lg-16 {
    padding-bottom: 16px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-17 {
    margin-bottom: 17px; }
  .pb-lg-17 {
    padding-bottom: 17px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-18 {
    margin-bottom: 18px; }
  .pb-lg-18 {
    padding-bottom: 18px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-19 {
    margin-bottom: 19px; }
  .pb-lg-19 {
    padding-bottom: 19px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-20 {
    margin-bottom: 20px; }
  .pb-lg-20 {
    padding-bottom: 20px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-21 {
    margin-bottom: 21px; }
  .pb-lg-21 {
    padding-bottom: 21px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-22 {
    margin-bottom: 22px; }
  .pb-lg-22 {
    padding-bottom: 22px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-23 {
    margin-bottom: 23px; }
  .pb-lg-23 {
    padding-bottom: 23px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-24 {
    margin-bottom: 24px; }
  .pb-lg-24 {
    padding-bottom: 24px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-25 {
    margin-bottom: 25px; }
  .pb-lg-25 {
    padding-bottom: 25px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-26 {
    margin-bottom: 26px; }
  .pb-lg-26 {
    padding-bottom: 26px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-27 {
    margin-bottom: 27px; }
  .pb-lg-27 {
    padding-bottom: 27px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-28 {
    margin-bottom: 28px; }
  .pb-lg-28 {
    padding-bottom: 28px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-29 {
    margin-bottom: 29px; }
  .pb-lg-29 {
    padding-bottom: 29px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-30 {
    margin-bottom: 30px; }
  .pb-lg-30 {
    padding-bottom: 30px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-31 {
    margin-bottom: 31px; }
  .pb-lg-31 {
    padding-bottom: 31px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-32 {
    margin-bottom: 32px; }
  .pb-lg-32 {
    padding-bottom: 32px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-33 {
    margin-bottom: 33px; }
  .pb-lg-33 {
    padding-bottom: 33px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-34 {
    margin-bottom: 34px; }
  .pb-lg-34 {
    padding-bottom: 34px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-35 {
    margin-bottom: 35px; }
  .pb-lg-35 {
    padding-bottom: 35px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-36 {
    margin-bottom: 36px; }
  .pb-lg-36 {
    padding-bottom: 36px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-37 {
    margin-bottom: 37px; }
  .pb-lg-37 {
    padding-bottom: 37px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-38 {
    margin-bottom: 38px; }
  .pb-lg-38 {
    padding-bottom: 38px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-39 {
    margin-bottom: 39px; }
  .pb-lg-39 {
    padding-bottom: 39px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-40 {
    margin-bottom: 40px; }
  .pb-lg-40 {
    padding-bottom: 40px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-41 {
    margin-bottom: 41px; }
  .pb-lg-41 {
    padding-bottom: 41px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-42 {
    margin-bottom: 42px; }
  .pb-lg-42 {
    padding-bottom: 42px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-43 {
    margin-bottom: 43px; }
  .pb-lg-43 {
    padding-bottom: 43px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-44 {
    margin-bottom: 44px; }
  .pb-lg-44 {
    padding-bottom: 44px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-45 {
    margin-bottom: 45px; }
  .pb-lg-45 {
    padding-bottom: 45px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-46 {
    margin-bottom: 46px; }
  .pb-lg-46 {
    padding-bottom: 46px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-47 {
    margin-bottom: 47px; }
  .pb-lg-47 {
    padding-bottom: 47px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-48 {
    margin-bottom: 48px; }
  .pb-lg-48 {
    padding-bottom: 48px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-49 {
    margin-bottom: 49px; }
  .pb-lg-49 {
    padding-bottom: 49px; } }

@media only screen and (max-width: 1200px) {
  .mb-lg-50 {
    margin-bottom: 50px; }
  .pb-lg-50 {
    padding-bottom: 50px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-0 {
    margin-left: 0px; }
  .pl-lg-0 {
    padding-left: 0px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-1 {
    margin-left: 1px; }
  .pl-lg-1 {
    padding-left: 1px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-2 {
    margin-left: 2px; }
  .pl-lg-2 {
    padding-left: 2px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-3 {
    margin-left: 3px; }
  .pl-lg-3 {
    padding-left: 3px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-4 {
    margin-left: 4px; }
  .pl-lg-4 {
    padding-left: 4px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-5 {
    margin-left: 5px; }
  .pl-lg-5 {
    padding-left: 5px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-6 {
    margin-left: 6px; }
  .pl-lg-6 {
    padding-left: 6px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-7 {
    margin-left: 7px; }
  .pl-lg-7 {
    padding-left: 7px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-8 {
    margin-left: 8px; }
  .pl-lg-8 {
    padding-left: 8px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-9 {
    margin-left: 9px; }
  .pl-lg-9 {
    padding-left: 9px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-10 {
    margin-left: 10px; }
  .pl-lg-10 {
    padding-left: 10px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-11 {
    margin-left: 11px; }
  .pl-lg-11 {
    padding-left: 11px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-12 {
    margin-left: 12px; }
  .pl-lg-12 {
    padding-left: 12px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-13 {
    margin-left: 13px; }
  .pl-lg-13 {
    padding-left: 13px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-14 {
    margin-left: 14px; }
  .pl-lg-14 {
    padding-left: 14px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-15 {
    margin-left: 15px; }
  .pl-lg-15 {
    padding-left: 15px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-16 {
    margin-left: 16px; }
  .pl-lg-16 {
    padding-left: 16px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-17 {
    margin-left: 17px; }
  .pl-lg-17 {
    padding-left: 17px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-18 {
    margin-left: 18px; }
  .pl-lg-18 {
    padding-left: 18px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-19 {
    margin-left: 19px; }
  .pl-lg-19 {
    padding-left: 19px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-20 {
    margin-left: 20px; }
  .pl-lg-20 {
    padding-left: 20px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-21 {
    margin-left: 21px; }
  .pl-lg-21 {
    padding-left: 21px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-22 {
    margin-left: 22px; }
  .pl-lg-22 {
    padding-left: 22px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-23 {
    margin-left: 23px; }
  .pl-lg-23 {
    padding-left: 23px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-24 {
    margin-left: 24px; }
  .pl-lg-24 {
    padding-left: 24px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-25 {
    margin-left: 25px; }
  .pl-lg-25 {
    padding-left: 25px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-26 {
    margin-left: 26px; }
  .pl-lg-26 {
    padding-left: 26px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-27 {
    margin-left: 27px; }
  .pl-lg-27 {
    padding-left: 27px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-28 {
    margin-left: 28px; }
  .pl-lg-28 {
    padding-left: 28px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-29 {
    margin-left: 29px; }
  .pl-lg-29 {
    padding-left: 29px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-30 {
    margin-left: 30px; }
  .pl-lg-30 {
    padding-left: 30px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-31 {
    margin-left: 31px; }
  .pl-lg-31 {
    padding-left: 31px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-32 {
    margin-left: 32px; }
  .pl-lg-32 {
    padding-left: 32px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-33 {
    margin-left: 33px; }
  .pl-lg-33 {
    padding-left: 33px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-34 {
    margin-left: 34px; }
  .pl-lg-34 {
    padding-left: 34px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-35 {
    margin-left: 35px; }
  .pl-lg-35 {
    padding-left: 35px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-36 {
    margin-left: 36px; }
  .pl-lg-36 {
    padding-left: 36px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-37 {
    margin-left: 37px; }
  .pl-lg-37 {
    padding-left: 37px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-38 {
    margin-left: 38px; }
  .pl-lg-38 {
    padding-left: 38px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-39 {
    margin-left: 39px; }
  .pl-lg-39 {
    padding-left: 39px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-40 {
    margin-left: 40px; }
  .pl-lg-40 {
    padding-left: 40px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-41 {
    margin-left: 41px; }
  .pl-lg-41 {
    padding-left: 41px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-42 {
    margin-left: 42px; }
  .pl-lg-42 {
    padding-left: 42px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-43 {
    margin-left: 43px; }
  .pl-lg-43 {
    padding-left: 43px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-44 {
    margin-left: 44px; }
  .pl-lg-44 {
    padding-left: 44px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-45 {
    margin-left: 45px; }
  .pl-lg-45 {
    padding-left: 45px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-46 {
    margin-left: 46px; }
  .pl-lg-46 {
    padding-left: 46px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-47 {
    margin-left: 47px; }
  .pl-lg-47 {
    padding-left: 47px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-48 {
    margin-left: 48px; }
  .pl-lg-48 {
    padding-left: 48px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-49 {
    margin-left: 49px; }
  .pl-lg-49 {
    padding-left: 49px; } }

@media only screen and (max-width: 1200px) {
  .ml-lg-50 {
    margin-left: 50px; }
  .pl-lg-50 {
    padding-left: 50px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-0 {
    margin-right: 0px; }
  .pr-lg-0 {
    padding-right: 0px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-1 {
    margin-right: 1px; }
  .pr-lg-1 {
    padding-right: 1px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-2 {
    margin-right: 2px; }
  .pr-lg-2 {
    padding-right: 2px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-3 {
    margin-right: 3px; }
  .pr-lg-3 {
    padding-right: 3px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-4 {
    margin-right: 4px; }
  .pr-lg-4 {
    padding-right: 4px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-5 {
    margin-right: 5px; }
  .pr-lg-5 {
    padding-right: 5px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-6 {
    margin-right: 6px; }
  .pr-lg-6 {
    padding-right: 6px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-7 {
    margin-right: 7px; }
  .pr-lg-7 {
    padding-right: 7px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-8 {
    margin-right: 8px; }
  .pr-lg-8 {
    padding-right: 8px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-9 {
    margin-right: 9px; }
  .pr-lg-9 {
    padding-right: 9px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-10 {
    margin-right: 10px; }
  .pr-lg-10 {
    padding-right: 10px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-11 {
    margin-right: 11px; }
  .pr-lg-11 {
    padding-right: 11px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-12 {
    margin-right: 12px; }
  .pr-lg-12 {
    padding-right: 12px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-13 {
    margin-right: 13px; }
  .pr-lg-13 {
    padding-right: 13px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-14 {
    margin-right: 14px; }
  .pr-lg-14 {
    padding-right: 14px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-15 {
    margin-right: 15px; }
  .pr-lg-15 {
    padding-right: 15px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-16 {
    margin-right: 16px; }
  .pr-lg-16 {
    padding-right: 16px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-17 {
    margin-right: 17px; }
  .pr-lg-17 {
    padding-right: 17px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-18 {
    margin-right: 18px; }
  .pr-lg-18 {
    padding-right: 18px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-19 {
    margin-right: 19px; }
  .pr-lg-19 {
    padding-right: 19px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-20 {
    margin-right: 20px; }
  .pr-lg-20 {
    padding-right: 20px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-21 {
    margin-right: 21px; }
  .pr-lg-21 {
    padding-right: 21px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-22 {
    margin-right: 22px; }
  .pr-lg-22 {
    padding-right: 22px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-23 {
    margin-right: 23px; }
  .pr-lg-23 {
    padding-right: 23px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-24 {
    margin-right: 24px; }
  .pr-lg-24 {
    padding-right: 24px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-25 {
    margin-right: 25px; }
  .pr-lg-25 {
    padding-right: 25px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-26 {
    margin-right: 26px; }
  .pr-lg-26 {
    padding-right: 26px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-27 {
    margin-right: 27px; }
  .pr-lg-27 {
    padding-right: 27px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-28 {
    margin-right: 28px; }
  .pr-lg-28 {
    padding-right: 28px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-29 {
    margin-right: 29px; }
  .pr-lg-29 {
    padding-right: 29px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-30 {
    margin-right: 30px; }
  .pr-lg-30 {
    padding-right: 30px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-31 {
    margin-right: 31px; }
  .pr-lg-31 {
    padding-right: 31px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-32 {
    margin-right: 32px; }
  .pr-lg-32 {
    padding-right: 32px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-33 {
    margin-right: 33px; }
  .pr-lg-33 {
    padding-right: 33px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-34 {
    margin-right: 34px; }
  .pr-lg-34 {
    padding-right: 34px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-35 {
    margin-right: 35px; }
  .pr-lg-35 {
    padding-right: 35px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-36 {
    margin-right: 36px; }
  .pr-lg-36 {
    padding-right: 36px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-37 {
    margin-right: 37px; }
  .pr-lg-37 {
    padding-right: 37px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-38 {
    margin-right: 38px; }
  .pr-lg-38 {
    padding-right: 38px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-39 {
    margin-right: 39px; }
  .pr-lg-39 {
    padding-right: 39px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-40 {
    margin-right: 40px; }
  .pr-lg-40 {
    padding-right: 40px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-41 {
    margin-right: 41px; }
  .pr-lg-41 {
    padding-right: 41px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-42 {
    margin-right: 42px; }
  .pr-lg-42 {
    padding-right: 42px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-43 {
    margin-right: 43px; }
  .pr-lg-43 {
    padding-right: 43px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-44 {
    margin-right: 44px; }
  .pr-lg-44 {
    padding-right: 44px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-45 {
    margin-right: 45px; }
  .pr-lg-45 {
    padding-right: 45px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-46 {
    margin-right: 46px; }
  .pr-lg-46 {
    padding-right: 46px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-47 {
    margin-right: 47px; }
  .pr-lg-47 {
    padding-right: 47px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-48 {
    margin-right: 48px; }
  .pr-lg-48 {
    padding-right: 48px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-49 {
    margin-right: 49px; }
  .pr-lg-49 {
    padding-right: 49px; } }

@media only screen and (max-width: 1200px) {
  .mr-lg-50 {
    margin-right: 50px; }
  .pr-lg-50 {
    padding-right: 50px; } }

/*for minus value */
._m-0 {
  margin: 0px; }

._p-0 {
  padding: 0px; }

._m-1 {
  margin: -1px; }

._p-1 {
  padding: -1px; }

._m-2 {
  margin: -2px; }

._p-2 {
  padding: -2px; }

._m-3 {
  margin: -3px; }

._p-3 {
  padding: -3px; }

._m-4 {
  margin: -4px; }

._p-4 {
  padding: -4px; }

._m-5 {
  margin: -5px; }

._p-5 {
  padding: -5px; }

._m-6 {
  margin: -6px; }

._p-6 {
  padding: -6px; }

._m-7 {
  margin: -7px; }

._p-7 {
  padding: -7px; }

._m-8 {
  margin: -8px; }

._p-8 {
  padding: -8px; }

._m-9 {
  margin: -9px; }

._p-9 {
  padding: -9px; }

._m-10 {
  margin: -10px; }

._p-10 {
  padding: -10px; }

._m-11 {
  margin: -11px; }

._p-11 {
  padding: -11px; }

._m-12 {
  margin: -12px; }

._p-12 {
  padding: -12px; }

._m-13 {
  margin: -13px; }

._p-13 {
  padding: -13px; }

._m-14 {
  margin: -14px; }

._p-14 {
  padding: -14px; }

._m-15 {
  margin: -15px; }

._p-15 {
  padding: -15px; }

._mt-0 {
  margin-top: 0px; }

._pt-0 {
  padding-top: 0px; }

._mt-1 {
  margin-top: -1px; }

._pt-1 {
  padding-top: -1px; }

._mt-2 {
  margin-top: -2px; }

._pt-2 {
  padding-top: -2px; }

._mt-3 {
  margin-top: -3px; }

._pt-3 {
  padding-top: -3px; }

._mt-4 {
  margin-top: -4px; }

._pt-4 {
  padding-top: -4px; }

._mt-5 {
  margin-top: -5px; }

._pt-5 {
  padding-top: -5px; }

._mt-6 {
  margin-top: -6px; }

._pt-6 {
  padding-top: -6px; }

._mt-7 {
  margin-top: -7px; }

._pt-7 {
  padding-top: -7px; }

._mt-8 {
  margin-top: -8px; }

._pt-8 {
  padding-top: -8px; }

._mt-9 {
  margin-top: -9px; }

._pt-9 {
  padding-top: -9px; }

._mt-10 {
  margin-top: -10px; }

._pt-10 {
  padding-top: -10px; }

._mt-11 {
  margin-top: -11px; }

._pt-11 {
  padding-top: -11px; }

._mt-12 {
  margin-top: -12px; }

._pt-12 {
  padding-top: -12px; }

._mt-13 {
  margin-top: -13px; }

._pt-13 {
  padding-top: -13px; }

._mt-14 {
  margin-top: -14px; }

._pt-14 {
  padding-top: -14px; }

._mt-15 {
  margin-top: -15px; }

._pt-15 {
  padding-top: -15px; }

._mb-0 {
  margin-bottom: 0px; }

._pb-0 {
  padding-bottom: 0px; }

._mb-1 {
  margin-bottom: -1px; }

._pb-1 {
  padding-bottom: -1px; }

._mb-2 {
  margin-bottom: -2px; }

._pb-2 {
  padding-bottom: -2px; }

._mb-3 {
  margin-bottom: -3px; }

._pb-3 {
  padding-bottom: -3px; }

._mb-4 {
  margin-bottom: -4px; }

._pb-4 {
  padding-bottom: -4px; }

._mb-5 {
  margin-bottom: -5px; }

._pb-5 {
  padding-bottom: -5px; }

._mb-6 {
  margin-bottom: -6px; }

._pb-6 {
  padding-bottom: -6px; }

._mb-7 {
  margin-bottom: -7px; }

._pb-7 {
  padding-bottom: -7px; }

._mb-8 {
  margin-bottom: -8px; }

._pb-8 {
  padding-bottom: -8px; }

._mb-9 {
  margin-bottom: -9px; }

._pb-9 {
  padding-bottom: -9px; }

._mb-10 {
  margin-bottom: -10px; }

._pb-10 {
  padding-bottom: -10px; }

._mb-11 {
  margin-bottom: -11px; }

._pb-11 {
  padding-bottom: -11px; }

._mb-12 {
  margin-bottom: -12px; }

._pb-12 {
  padding-bottom: -12px; }

._mb-13 {
  margin-bottom: -13px; }

._pb-13 {
  padding-bottom: -13px; }

._mb-14 {
  margin-bottom: -14px; }

._pb-14 {
  padding-bottom: -14px; }

._mb-15 {
  margin-bottom: -15px; }

._pb-15 {
  padding-bottom: -15px; }

._ml-0 {
  margin-left: 0px; }

._pl-0 {
  padding-left: 0px; }

._ml-1 {
  margin-left: -1px; }

._pl-1 {
  padding-left: -1px; }

._ml-2 {
  margin-left: -2px; }

._pl-2 {
  padding-left: -2px; }

._ml-3 {
  margin-left: -3px; }

._pl-3 {
  padding-left: -3px; }

._ml-4 {
  margin-left: -4px; }

._pl-4 {
  padding-left: -4px; }

._ml-5 {
  margin-left: -5px; }

._pl-5 {
  padding-left: -5px; }

._ml-6 {
  margin-left: -6px; }

._pl-6 {
  padding-left: -6px; }

._ml-7 {
  margin-left: -7px; }

._pl-7 {
  padding-left: -7px; }

._ml-8 {
  margin-left: -8px; }

._pl-8 {
  padding-left: -8px; }

._ml-9 {
  margin-left: -9px; }

._pl-9 {
  padding-left: -9px; }

._ml-10 {
  margin-left: -10px; }

._pl-10 {
  padding-left: -10px; }

._ml-11 {
  margin-left: -11px; }

._pl-11 {
  padding-left: -11px; }

._ml-12 {
  margin-left: -12px; }

._pl-12 {
  padding-left: -12px; }

._ml-13 {
  margin-left: -13px; }

._pl-13 {
  padding-left: -13px; }

._ml-14 {
  margin-left: -14px; }

._pl-14 {
  padding-left: -14px; }

._ml-15 {
  margin-left: -15px; }

._pl-15 {
  padding-left: -15px; }

._mr-0 {
  margin-right: 0px; }

._pr-0 {
  padding-right: 0px; }

._mr-1 {
  margin-right: -1px; }

._pr-1 {
  padding-right: -1px; }

._mr-2 {
  margin-right: -2px; }

._pr-2 {
  padding-right: -2px; }

._mr-3 {
  margin-right: -3px; }

._pr-3 {
  padding-right: -3px; }

._mr-4 {
  margin-right: -4px; }

._pr-4 {
  padding-right: -4px; }

._mr-5 {
  margin-right: -5px; }

._pr-5 {
  padding-right: -5px; }

._mr-6 {
  margin-right: -6px; }

._pr-6 {
  padding-right: -6px; }

._mr-7 {
  margin-right: -7px; }

._pr-7 {
  padding-right: -7px; }

._mr-8 {
  margin-right: -8px; }

._pr-8 {
  padding-right: -8px; }

._mr-9 {
  margin-right: -9px; }

._pr-9 {
  padding-right: -9px; }

._mr-10 {
  margin-right: -10px; }

._pr-10 {
  padding-right: -10px; }

._mr-11 {
  margin-right: -11px; }

._pr-11 {
  padding-right: -11px; }

._mr-12 {
  margin-right: -12px; }

._pr-12 {
  padding-right: -12px; }

._mr-13 {
  margin-right: -13px; }

._pr-13 {
  padding-right: -13px; }

._mr-14 {
  margin-right: -14px; }

._pr-14 {
  padding-right: -14px; }

._mr-15 {
  margin-right: -15px; }

._pr-15 {
  padding-right: -15px; }

@media only screen and (max-width: 576px) {
  ._m-xs-0 {
    margin: 0px; }
  ._p-xs-0 {
    padding: 0px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-1 {
    margin: -1px; }
  ._p-xs-1 {
    padding: -1px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-2 {
    margin: -2px; }
  ._p-xs-2 {
    padding: -2px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-3 {
    margin: -3px; }
  ._p-xs-3 {
    padding: -3px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-4 {
    margin: -4px; }
  ._p-xs-4 {
    padding: -4px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-5 {
    margin: -5px; }
  ._p-xs-5 {
    padding: -5px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-6 {
    margin: -6px; }
  ._p-xs-6 {
    padding: -6px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-7 {
    margin: -7px; }
  ._p-xs-7 {
    padding: -7px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-8 {
    margin: -8px; }
  ._p-xs-8 {
    padding: -8px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-9 {
    margin: -9px; }
  ._p-xs-9 {
    padding: -9px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-10 {
    margin: -10px; }
  ._p-xs-10 {
    padding: -10px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-11 {
    margin: -11px; }
  ._p-xs-11 {
    padding: -11px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-12 {
    margin: -12px; }
  ._p-xs-12 {
    padding: -12px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-13 {
    margin: -13px; }
  ._p-xs-13 {
    padding: -13px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-14 {
    margin: -14px; }
  ._p-xs-14 {
    padding: -14px; } }

@media only screen and (max-width: 576px) {
  ._m-xs-15 {
    margin: -15px; }
  ._p-xs-15 {
    padding: -15px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-0 {
    margin-top: 0px; }
  ._pt-xs-0 {
    padding-top: 0px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-1 {
    margin-top: -1px; }
  ._pt-xs-1 {
    padding-top: -1px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-2 {
    margin-top: -2px; }
  ._pt-xs-2 {
    padding-top: -2px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-3 {
    margin-top: -3px; }
  ._pt-xs-3 {
    padding-top: -3px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-4 {
    margin-top: -4px; }
  ._pt-xs-4 {
    padding-top: -4px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-5 {
    margin-top: -5px; }
  ._pt-xs-5 {
    padding-top: -5px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-6 {
    margin-top: -6px; }
  ._pt-xs-6 {
    padding-top: -6px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-7 {
    margin-top: -7px; }
  ._pt-xs-7 {
    padding-top: -7px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-8 {
    margin-top: -8px; }
  ._pt-xs-8 {
    padding-top: -8px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-9 {
    margin-top: -9px; }
  ._pt-xs-9 {
    padding-top: -9px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-10 {
    margin-top: -10px; }
  ._pt-xs-10 {
    padding-top: -10px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-11 {
    margin-top: -11px; }
  ._pt-xs-11 {
    padding-top: -11px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-12 {
    margin-top: -12px; }
  ._pt-xs-12 {
    padding-top: -12px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-13 {
    margin-top: -13px; }
  ._pt-xs-13 {
    padding-top: -13px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-14 {
    margin-top: -14px; }
  ._pt-xs-14 {
    padding-top: -14px; } }

@media only screen and (max-width: 576px) {
  ._mt-xs-15 {
    margin-top: -15px; }
  ._pt-xs-15 {
    padding-top: -15px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-0 {
    margin-bottom: 0px; }
  ._pb-xs-0 {
    padding-bottom: 0px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-1 {
    margin-bottom: -1px; }
  ._pb-xs-1 {
    padding-bottom: -1px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-2 {
    margin-bottom: -2px; }
  ._pb-xs-2 {
    padding-bottom: -2px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-3 {
    margin-bottom: -3px; }
  ._pb-xs-3 {
    padding-bottom: -3px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-4 {
    margin-bottom: -4px; }
  ._pb-xs-4 {
    padding-bottom: -4px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-5 {
    margin-bottom: -5px; }
  ._pb-xs-5 {
    padding-bottom: -5px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-6 {
    margin-bottom: -6px; }
  ._pb-xs-6 {
    padding-bottom: -6px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-7 {
    margin-bottom: -7px; }
  ._pb-xs-7 {
    padding-bottom: -7px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-8 {
    margin-bottom: -8px; }
  ._pb-xs-8 {
    padding-bottom: -8px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-9 {
    margin-bottom: -9px; }
  ._pb-xs-9 {
    padding-bottom: -9px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-10 {
    margin-bottom: -10px; }
  ._pb-xs-10 {
    padding-bottom: -10px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-11 {
    margin-bottom: -11px; }
  ._pb-xs-11 {
    padding-bottom: -11px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-12 {
    margin-bottom: -12px; }
  ._pb-xs-12 {
    padding-bottom: -12px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-13 {
    margin-bottom: -13px; }
  ._pb-xs-13 {
    padding-bottom: -13px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-14 {
    margin-bottom: -14px; }
  ._pb-xs-14 {
    padding-bottom: -14px; } }

@media only screen and (max-width: 576px) {
  ._mb-xs-15 {
    margin-bottom: -15px; }
  ._pb-xs-15 {
    padding-bottom: -15px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-0 {
    margin-left: 0px; }
  ._pl-xs-0 {
    padding-left: 0px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-1 {
    margin-left: -1px; }
  ._pl-xs-1 {
    padding-left: -1px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-2 {
    margin-left: -2px; }
  ._pl-xs-2 {
    padding-left: -2px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-3 {
    margin-left: -3px; }
  ._pl-xs-3 {
    padding-left: -3px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-4 {
    margin-left: -4px; }
  ._pl-xs-4 {
    padding-left: -4px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-5 {
    margin-left: -5px; }
  ._pl-xs-5 {
    padding-left: -5px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-6 {
    margin-left: -6px; }
  ._pl-xs-6 {
    padding-left: -6px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-7 {
    margin-left: -7px; }
  ._pl-xs-7 {
    padding-left: -7px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-8 {
    margin-left: -8px; }
  ._pl-xs-8 {
    padding-left: -8px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-9 {
    margin-left: -9px; }
  ._pl-xs-9 {
    padding-left: -9px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-10 {
    margin-left: -10px; }
  ._pl-xs-10 {
    padding-left: -10px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-11 {
    margin-left: -11px; }
  ._pl-xs-11 {
    padding-left: -11px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-12 {
    margin-left: -12px; }
  ._pl-xs-12 {
    padding-left: -12px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-13 {
    margin-left: -13px; }
  ._pl-xs-13 {
    padding-left: -13px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-14 {
    margin-left: -14px; }
  ._pl-xs-14 {
    padding-left: -14px; } }

@media only screen and (max-width: 576px) {
  ._ml-xs-15 {
    margin-left: -15px; }
  ._pl-xs-15 {
    padding-left: -15px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-0 {
    margin-right: 0px; }
  ._pr-xs-0 {
    padding-right: 0px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-1 {
    margin-right: -1px; }
  ._pr-xs-1 {
    padding-right: -1px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-2 {
    margin-right: -2px; }
  ._pr-xs-2 {
    padding-right: -2px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-3 {
    margin-right: -3px; }
  ._pr-xs-3 {
    padding-right: -3px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-4 {
    margin-right: -4px; }
  ._pr-xs-4 {
    padding-right: -4px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-5 {
    margin-right: -5px; }
  ._pr-xs-5 {
    padding-right: -5px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-6 {
    margin-right: -6px; }
  ._pr-xs-6 {
    padding-right: -6px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-7 {
    margin-right: -7px; }
  ._pr-xs-7 {
    padding-right: -7px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-8 {
    margin-right: -8px; }
  ._pr-xs-8 {
    padding-right: -8px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-9 {
    margin-right: -9px; }
  ._pr-xs-9 {
    padding-right: -9px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-10 {
    margin-right: -10px; }
  ._pr-xs-10 {
    padding-right: -10px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-11 {
    margin-right: -11px; }
  ._pr-xs-11 {
    padding-right: -11px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-12 {
    margin-right: -12px; }
  ._pr-xs-12 {
    padding-right: -12px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-13 {
    margin-right: -13px; }
  ._pr-xs-13 {
    padding-right: -13px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-14 {
    margin-right: -14px; }
  ._pr-xs-14 {
    padding-right: -14px; } }

@media only screen and (max-width: 576px) {
  ._mr-xs-15 {
    margin-right: -15px; }
  ._pr-xs-15 {
    padding-right: -15px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-0 {
    margin: 0px; }
  ._p-sm-0 {
    padding: 0px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-1 {
    margin: -1px; }
  ._p-sm-1 {
    padding: -1px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-2 {
    margin: -2px; }
  ._p-sm-2 {
    padding: -2px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-3 {
    margin: -3px; }
  ._p-sm-3 {
    padding: -3px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-4 {
    margin: -4px; }
  ._p-sm-4 {
    padding: -4px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-5 {
    margin: -5px; }
  ._p-sm-5 {
    padding: -5px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-6 {
    margin: -6px; }
  ._p-sm-6 {
    padding: -6px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-7 {
    margin: -7px; }
  ._p-sm-7 {
    padding: -7px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-8 {
    margin: -8px; }
  ._p-sm-8 {
    padding: -8px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-9 {
    margin: -9px; }
  ._p-sm-9 {
    padding: -9px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-10 {
    margin: -10px; }
  ._p-sm-10 {
    padding: -10px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-11 {
    margin: -11px; }
  ._p-sm-11 {
    padding: -11px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-12 {
    margin: -12px; }
  ._p-sm-12 {
    padding: -12px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-13 {
    margin: -13px; }
  ._p-sm-13 {
    padding: -13px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-14 {
    margin: -14px; }
  ._p-sm-14 {
    padding: -14px; } }

@media only screen and (max-width: 768px) {
  ._m-sm-15 {
    margin: -15px; }
  ._p-sm-15 {
    padding: -15px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-0 {
    margin-top: 0px; }
  ._pt-sm-0 {
    padding-top: 0px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-1 {
    margin-top: -1px; }
  ._pt-sm-1 {
    padding-top: -1px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-2 {
    margin-top: -2px; }
  ._pt-sm-2 {
    padding-top: -2px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-3 {
    margin-top: -3px; }
  ._pt-sm-3 {
    padding-top: -3px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-4 {
    margin-top: -4px; }
  ._pt-sm-4 {
    padding-top: -4px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-5 {
    margin-top: -5px; }
  ._pt-sm-5 {
    padding-top: -5px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-6 {
    margin-top: -6px; }
  ._pt-sm-6 {
    padding-top: -6px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-7 {
    margin-top: -7px; }
  ._pt-sm-7 {
    padding-top: -7px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-8 {
    margin-top: -8px; }
  ._pt-sm-8 {
    padding-top: -8px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-9 {
    margin-top: -9px; }
  ._pt-sm-9 {
    padding-top: -9px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-10 {
    margin-top: -10px; }
  ._pt-sm-10 {
    padding-top: -10px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-11 {
    margin-top: -11px; }
  ._pt-sm-11 {
    padding-top: -11px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-12 {
    margin-top: -12px; }
  ._pt-sm-12 {
    padding-top: -12px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-13 {
    margin-top: -13px; }
  ._pt-sm-13 {
    padding-top: -13px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-14 {
    margin-top: -14px; }
  ._pt-sm-14 {
    padding-top: -14px; } }

@media only screen and (max-width: 768px) {
  ._mt-sm-15 {
    margin-top: -15px; }
  ._pt-sm-15 {
    padding-top: -15px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-0 {
    margin-bottom: 0px; }
  ._pb-sm-0 {
    padding-bottom: 0px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-1 {
    margin-bottom: -1px; }
  ._pb-sm-1 {
    padding-bottom: -1px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-2 {
    margin-bottom: -2px; }
  ._pb-sm-2 {
    padding-bottom: -2px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-3 {
    margin-bottom: -3px; }
  ._pb-sm-3 {
    padding-bottom: -3px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-4 {
    margin-bottom: -4px; }
  ._pb-sm-4 {
    padding-bottom: -4px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-5 {
    margin-bottom: -5px; }
  ._pb-sm-5 {
    padding-bottom: -5px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-6 {
    margin-bottom: -6px; }
  ._pb-sm-6 {
    padding-bottom: -6px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-7 {
    margin-bottom: -7px; }
  ._pb-sm-7 {
    padding-bottom: -7px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-8 {
    margin-bottom: -8px; }
  ._pb-sm-8 {
    padding-bottom: -8px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-9 {
    margin-bottom: -9px; }
  ._pb-sm-9 {
    padding-bottom: -9px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-10 {
    margin-bottom: -10px; }
  ._pb-sm-10 {
    padding-bottom: -10px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-11 {
    margin-bottom: -11px; }
  ._pb-sm-11 {
    padding-bottom: -11px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-12 {
    margin-bottom: -12px; }
  ._pb-sm-12 {
    padding-bottom: -12px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-13 {
    margin-bottom: -13px; }
  ._pb-sm-13 {
    padding-bottom: -13px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-14 {
    margin-bottom: -14px; }
  ._pb-sm-14 {
    padding-bottom: -14px; } }

@media only screen and (max-width: 768px) {
  ._mb-sm-15 {
    margin-bottom: -15px; }
  ._pb-sm-15 {
    padding-bottom: -15px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-0 {
    margin-left: 0px; }
  ._pl-sm-0 {
    padding-left: 0px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-1 {
    margin-left: -1px; }
  ._pl-sm-1 {
    padding-left: -1px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-2 {
    margin-left: -2px; }
  ._pl-sm-2 {
    padding-left: -2px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-3 {
    margin-left: -3px; }
  ._pl-sm-3 {
    padding-left: -3px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-4 {
    margin-left: -4px; }
  ._pl-sm-4 {
    padding-left: -4px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-5 {
    margin-left: -5px; }
  ._pl-sm-5 {
    padding-left: -5px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-6 {
    margin-left: -6px; }
  ._pl-sm-6 {
    padding-left: -6px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-7 {
    margin-left: -7px; }
  ._pl-sm-7 {
    padding-left: -7px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-8 {
    margin-left: -8px; }
  ._pl-sm-8 {
    padding-left: -8px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-9 {
    margin-left: -9px; }
  ._pl-sm-9 {
    padding-left: -9px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-10 {
    margin-left: -10px; }
  ._pl-sm-10 {
    padding-left: -10px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-11 {
    margin-left: -11px; }
  ._pl-sm-11 {
    padding-left: -11px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-12 {
    margin-left: -12px; }
  ._pl-sm-12 {
    padding-left: -12px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-13 {
    margin-left: -13px; }
  ._pl-sm-13 {
    padding-left: -13px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-14 {
    margin-left: -14px; }
  ._pl-sm-14 {
    padding-left: -14px; } }

@media only screen and (max-width: 768px) {
  ._ml-sm-15 {
    margin-left: -15px; }
  ._pl-sm-15 {
    padding-left: -15px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-0 {
    margin-right: 0px; }
  ._pr-sm-0 {
    padding-right: 0px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-1 {
    margin-right: -1px; }
  ._pr-sm-1 {
    padding-right: -1px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-2 {
    margin-right: -2px; }
  ._pr-sm-2 {
    padding-right: -2px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-3 {
    margin-right: -3px; }
  ._pr-sm-3 {
    padding-right: -3px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-4 {
    margin-right: -4px; }
  ._pr-sm-4 {
    padding-right: -4px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-5 {
    margin-right: -5px; }
  ._pr-sm-5 {
    padding-right: -5px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-6 {
    margin-right: -6px; }
  ._pr-sm-6 {
    padding-right: -6px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-7 {
    margin-right: -7px; }
  ._pr-sm-7 {
    padding-right: -7px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-8 {
    margin-right: -8px; }
  ._pr-sm-8 {
    padding-right: -8px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-9 {
    margin-right: -9px; }
  ._pr-sm-9 {
    padding-right: -9px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-10 {
    margin-right: -10px; }
  ._pr-sm-10 {
    padding-right: -10px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-11 {
    margin-right: -11px; }
  ._pr-sm-11 {
    padding-right: -11px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-12 {
    margin-right: -12px; }
  ._pr-sm-12 {
    padding-right: -12px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-13 {
    margin-right: -13px; }
  ._pr-sm-13 {
    padding-right: -13px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-14 {
    margin-right: -14px; }
  ._pr-sm-14 {
    padding-right: -14px; } }

@media only screen and (max-width: 768px) {
  ._mr-sm-15 {
    margin-right: -15px; }
  ._pr-sm-15 {
    padding-right: -15px; } }

@media only screen and (max-width: 992px) {
  ._m-md-0 {
    margin: 0px; }
  ._p-md-0 {
    padding: 0px; } }

@media only screen and (max-width: 992px) {
  ._m-md-1 {
    margin: -1px; }
  ._p-md-1 {
    padding: -1px; } }

@media only screen and (max-width: 992px) {
  ._m-md-2 {
    margin: -2px; }
  ._p-md-2 {
    padding: -2px; } }

@media only screen and (max-width: 992px) {
  ._m-md-3 {
    margin: -3px; }
  ._p-md-3 {
    padding: -3px; } }

@media only screen and (max-width: 992px) {
  ._m-md-4 {
    margin: -4px; }
  ._p-md-4 {
    padding: -4px; } }

@media only screen and (max-width: 992px) {
  ._m-md-5 {
    margin: -5px; }
  ._p-md-5 {
    padding: -5px; } }

@media only screen and (max-width: 992px) {
  ._m-md-6 {
    margin: -6px; }
  ._p-md-6 {
    padding: -6px; } }

@media only screen and (max-width: 992px) {
  ._m-md-7 {
    margin: -7px; }
  ._p-md-7 {
    padding: -7px; } }

@media only screen and (max-width: 992px) {
  ._m-md-8 {
    margin: -8px; }
  ._p-md-8 {
    padding: -8px; } }

@media only screen and (max-width: 992px) {
  ._m-md-9 {
    margin: -9px; }
  ._p-md-9 {
    padding: -9px; } }

@media only screen and (max-width: 992px) {
  ._m-md-10 {
    margin: -10px; }
  ._p-md-10 {
    padding: -10px; } }

@media only screen and (max-width: 992px) {
  ._m-md-11 {
    margin: -11px; }
  ._p-md-11 {
    padding: -11px; } }

@media only screen and (max-width: 992px) {
  ._m-md-12 {
    margin: -12px; }
  ._p-md-12 {
    padding: -12px; } }

@media only screen and (max-width: 992px) {
  ._m-md-13 {
    margin: -13px; }
  ._p-md-13 {
    padding: -13px; } }

@media only screen and (max-width: 992px) {
  ._m-md-14 {
    margin: -14px; }
  ._p-md-14 {
    padding: -14px; } }

@media only screen and (max-width: 992px) {
  ._m-md-15 {
    margin: -15px; }
  ._p-md-15 {
    padding: -15px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-0 {
    margin-top: 0px; }
  ._pt-md-0 {
    padding-top: 0px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-1 {
    margin-top: -1px; }
  ._pt-md-1 {
    padding-top: -1px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-2 {
    margin-top: -2px; }
  ._pt-md-2 {
    padding-top: -2px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-3 {
    margin-top: -3px; }
  ._pt-md-3 {
    padding-top: -3px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-4 {
    margin-top: -4px; }
  ._pt-md-4 {
    padding-top: -4px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-5 {
    margin-top: -5px; }
  ._pt-md-5 {
    padding-top: -5px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-6 {
    margin-top: -6px; }
  ._pt-md-6 {
    padding-top: -6px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-7 {
    margin-top: -7px; }
  ._pt-md-7 {
    padding-top: -7px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-8 {
    margin-top: -8px; }
  ._pt-md-8 {
    padding-top: -8px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-9 {
    margin-top: -9px; }
  ._pt-md-9 {
    padding-top: -9px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-10 {
    margin-top: -10px; }
  ._pt-md-10 {
    padding-top: -10px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-11 {
    margin-top: -11px; }
  ._pt-md-11 {
    padding-top: -11px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-12 {
    margin-top: -12px; }
  ._pt-md-12 {
    padding-top: -12px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-13 {
    margin-top: -13px; }
  ._pt-md-13 {
    padding-top: -13px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-14 {
    margin-top: -14px; }
  ._pt-md-14 {
    padding-top: -14px; } }

@media only screen and (max-width: 992px) {
  ._mt-md-15 {
    margin-top: -15px; }
  ._pt-md-15 {
    padding-top: -15px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-0 {
    margin-bottom: 0px; }
  ._pb-md-0 {
    padding-bottom: 0px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-1 {
    margin-bottom: -1px; }
  ._pb-md-1 {
    padding-bottom: -1px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-2 {
    margin-bottom: -2px; }
  ._pb-md-2 {
    padding-bottom: -2px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-3 {
    margin-bottom: -3px; }
  ._pb-md-3 {
    padding-bottom: -3px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-4 {
    margin-bottom: -4px; }
  ._pb-md-4 {
    padding-bottom: -4px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-5 {
    margin-bottom: -5px; }
  ._pb-md-5 {
    padding-bottom: -5px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-6 {
    margin-bottom: -6px; }
  ._pb-md-6 {
    padding-bottom: -6px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-7 {
    margin-bottom: -7px; }
  ._pb-md-7 {
    padding-bottom: -7px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-8 {
    margin-bottom: -8px; }
  ._pb-md-8 {
    padding-bottom: -8px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-9 {
    margin-bottom: -9px; }
  ._pb-md-9 {
    padding-bottom: -9px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-10 {
    margin-bottom: -10px; }
  ._pb-md-10 {
    padding-bottom: -10px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-11 {
    margin-bottom: -11px; }
  ._pb-md-11 {
    padding-bottom: -11px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-12 {
    margin-bottom: -12px; }
  ._pb-md-12 {
    padding-bottom: -12px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-13 {
    margin-bottom: -13px; }
  ._pb-md-13 {
    padding-bottom: -13px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-14 {
    margin-bottom: -14px; }
  ._pb-md-14 {
    padding-bottom: -14px; } }

@media only screen and (max-width: 992px) {
  ._mb-md-15 {
    margin-bottom: -15px; }
  ._pb-md-15 {
    padding-bottom: -15px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-0 {
    margin-left: 0px; }
  ._pl-md-0 {
    padding-left: 0px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-1 {
    margin-left: -1px; }
  ._pl-md-1 {
    padding-left: -1px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-2 {
    margin-left: -2px; }
  ._pl-md-2 {
    padding-left: -2px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-3 {
    margin-left: -3px; }
  ._pl-md-3 {
    padding-left: -3px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-4 {
    margin-left: -4px; }
  ._pl-md-4 {
    padding-left: -4px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-5 {
    margin-left: -5px; }
  ._pl-md-5 {
    padding-left: -5px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-6 {
    margin-left: -6px; }
  ._pl-md-6 {
    padding-left: -6px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-7 {
    margin-left: -7px; }
  ._pl-md-7 {
    padding-left: -7px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-8 {
    margin-left: -8px; }
  ._pl-md-8 {
    padding-left: -8px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-9 {
    margin-left: -9px; }
  ._pl-md-9 {
    padding-left: -9px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-10 {
    margin-left: -10px; }
  ._pl-md-10 {
    padding-left: -10px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-11 {
    margin-left: -11px; }
  ._pl-md-11 {
    padding-left: -11px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-12 {
    margin-left: -12px; }
  ._pl-md-12 {
    padding-left: -12px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-13 {
    margin-left: -13px; }
  ._pl-md-13 {
    padding-left: -13px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-14 {
    margin-left: -14px; }
  ._pl-md-14 {
    padding-left: -14px; } }

@media only screen and (max-width: 992px) {
  ._ml-md-15 {
    margin-left: -15px; }
  ._pl-md-15 {
    padding-left: -15px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-0 {
    margin-right: 0px; }
  ._pr-md-0 {
    padding-right: 0px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-1 {
    margin-right: -1px; }
  ._pr-md-1 {
    padding-right: -1px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-2 {
    margin-right: -2px; }
  ._pr-md-2 {
    padding-right: -2px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-3 {
    margin-right: -3px; }
  ._pr-md-3 {
    padding-right: -3px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-4 {
    margin-right: -4px; }
  ._pr-md-4 {
    padding-right: -4px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-5 {
    margin-right: -5px; }
  ._pr-md-5 {
    padding-right: -5px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-6 {
    margin-right: -6px; }
  ._pr-md-6 {
    padding-right: -6px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-7 {
    margin-right: -7px; }
  ._pr-md-7 {
    padding-right: -7px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-8 {
    margin-right: -8px; }
  ._pr-md-8 {
    padding-right: -8px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-9 {
    margin-right: -9px; }
  ._pr-md-9 {
    padding-right: -9px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-10 {
    margin-right: -10px; }
  ._pr-md-10 {
    padding-right: -10px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-11 {
    margin-right: -11px; }
  ._pr-md-11 {
    padding-right: -11px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-12 {
    margin-right: -12px; }
  ._pr-md-12 {
    padding-right: -12px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-13 {
    margin-right: -13px; }
  ._pr-md-13 {
    padding-right: -13px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-14 {
    margin-right: -14px; }
  ._pr-md-14 {
    padding-right: -14px; } }

@media only screen and (max-width: 992px) {
  ._mr-md-15 {
    margin-right: -15px; }
  ._pr-md-15 {
    padding-right: -15px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-0 {
    margin: 0px; }
  ._p-lg-0 {
    padding: 0px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-1 {
    margin: -1px; }
  ._p-lg-1 {
    padding: -1px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-2 {
    margin: -2px; }
  ._p-lg-2 {
    padding: -2px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-3 {
    margin: -3px; }
  ._p-lg-3 {
    padding: -3px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-4 {
    margin: -4px; }
  ._p-lg-4 {
    padding: -4px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-5 {
    margin: -5px; }
  ._p-lg-5 {
    padding: -5px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-6 {
    margin: -6px; }
  ._p-lg-6 {
    padding: -6px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-7 {
    margin: -7px; }
  ._p-lg-7 {
    padding: -7px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-8 {
    margin: -8px; }
  ._p-lg-8 {
    padding: -8px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-9 {
    margin: -9px; }
  ._p-lg-9 {
    padding: -9px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-10 {
    margin: -10px; }
  ._p-lg-10 {
    padding: -10px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-11 {
    margin: -11px; }
  ._p-lg-11 {
    padding: -11px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-12 {
    margin: -12px; }
  ._p-lg-12 {
    padding: -12px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-13 {
    margin: -13px; }
  ._p-lg-13 {
    padding: -13px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-14 {
    margin: -14px; }
  ._p-lg-14 {
    padding: -14px; } }

@media only screen and (max-width: 1200px) {
  ._m-lg-15 {
    margin: -15px; }
  ._p-lg-15 {
    padding: -15px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-0 {
    margin-top: 0px; }
  ._pt-lg-0 {
    padding-top: 0px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-1 {
    margin-top: -1px; }
  ._pt-lg-1 {
    padding-top: -1px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-2 {
    margin-top: -2px; }
  ._pt-lg-2 {
    padding-top: -2px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-3 {
    margin-top: -3px; }
  ._pt-lg-3 {
    padding-top: -3px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-4 {
    margin-top: -4px; }
  ._pt-lg-4 {
    padding-top: -4px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-5 {
    margin-top: -5px; }
  ._pt-lg-5 {
    padding-top: -5px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-6 {
    margin-top: -6px; }
  ._pt-lg-6 {
    padding-top: -6px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-7 {
    margin-top: -7px; }
  ._pt-lg-7 {
    padding-top: -7px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-8 {
    margin-top: -8px; }
  ._pt-lg-8 {
    padding-top: -8px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-9 {
    margin-top: -9px; }
  ._pt-lg-9 {
    padding-top: -9px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-10 {
    margin-top: -10px; }
  ._pt-lg-10 {
    padding-top: -10px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-11 {
    margin-top: -11px; }
  ._pt-lg-11 {
    padding-top: -11px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-12 {
    margin-top: -12px; }
  ._pt-lg-12 {
    padding-top: -12px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-13 {
    margin-top: -13px; }
  ._pt-lg-13 {
    padding-top: -13px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-14 {
    margin-top: -14px; }
  ._pt-lg-14 {
    padding-top: -14px; } }

@media only screen and (max-width: 1200px) {
  ._mt-lg-15 {
    margin-top: -15px; }
  ._pt-lg-15 {
    padding-top: -15px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-0 {
    margin-bottom: 0px; }
  ._pb-lg-0 {
    padding-bottom: 0px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-1 {
    margin-bottom: -1px; }
  ._pb-lg-1 {
    padding-bottom: -1px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-2 {
    margin-bottom: -2px; }
  ._pb-lg-2 {
    padding-bottom: -2px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-3 {
    margin-bottom: -3px; }
  ._pb-lg-3 {
    padding-bottom: -3px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-4 {
    margin-bottom: -4px; }
  ._pb-lg-4 {
    padding-bottom: -4px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-5 {
    margin-bottom: -5px; }
  ._pb-lg-5 {
    padding-bottom: -5px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-6 {
    margin-bottom: -6px; }
  ._pb-lg-6 {
    padding-bottom: -6px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-7 {
    margin-bottom: -7px; }
  ._pb-lg-7 {
    padding-bottom: -7px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-8 {
    margin-bottom: -8px; }
  ._pb-lg-8 {
    padding-bottom: -8px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-9 {
    margin-bottom: -9px; }
  ._pb-lg-9 {
    padding-bottom: -9px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-10 {
    margin-bottom: -10px; }
  ._pb-lg-10 {
    padding-bottom: -10px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-11 {
    margin-bottom: -11px; }
  ._pb-lg-11 {
    padding-bottom: -11px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-12 {
    margin-bottom: -12px; }
  ._pb-lg-12 {
    padding-bottom: -12px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-13 {
    margin-bottom: -13px; }
  ._pb-lg-13 {
    padding-bottom: -13px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-14 {
    margin-bottom: -14px; }
  ._pb-lg-14 {
    padding-bottom: -14px; } }

@media only screen and (max-width: 1200px) {
  ._mb-lg-15 {
    margin-bottom: -15px; }
  ._pb-lg-15 {
    padding-bottom: -15px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-0 {
    margin-left: 0px; }
  ._pl-lg-0 {
    padding-left: 0px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-1 {
    margin-left: -1px; }
  ._pl-lg-1 {
    padding-left: -1px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-2 {
    margin-left: -2px; }
  ._pl-lg-2 {
    padding-left: -2px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-3 {
    margin-left: -3px; }
  ._pl-lg-3 {
    padding-left: -3px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-4 {
    margin-left: -4px; }
  ._pl-lg-4 {
    padding-left: -4px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-5 {
    margin-left: -5px; }
  ._pl-lg-5 {
    padding-left: -5px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-6 {
    margin-left: -6px; }
  ._pl-lg-6 {
    padding-left: -6px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-7 {
    margin-left: -7px; }
  ._pl-lg-7 {
    padding-left: -7px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-8 {
    margin-left: -8px; }
  ._pl-lg-8 {
    padding-left: -8px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-9 {
    margin-left: -9px; }
  ._pl-lg-9 {
    padding-left: -9px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-10 {
    margin-left: -10px; }
  ._pl-lg-10 {
    padding-left: -10px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-11 {
    margin-left: -11px; }
  ._pl-lg-11 {
    padding-left: -11px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-12 {
    margin-left: -12px; }
  ._pl-lg-12 {
    padding-left: -12px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-13 {
    margin-left: -13px; }
  ._pl-lg-13 {
    padding-left: -13px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-14 {
    margin-left: -14px; }
  ._pl-lg-14 {
    padding-left: -14px; } }

@media only screen and (max-width: 1200px) {
  ._ml-lg-15 {
    margin-left: -15px; }
  ._pl-lg-15 {
    padding-left: -15px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-0 {
    margin-right: 0px; }
  ._pr-lg-0 {
    padding-right: 0px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-1 {
    margin-right: -1px; }
  ._pr-lg-1 {
    padding-right: -1px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-2 {
    margin-right: -2px; }
  ._pr-lg-2 {
    padding-right: -2px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-3 {
    margin-right: -3px; }
  ._pr-lg-3 {
    padding-right: -3px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-4 {
    margin-right: -4px; }
  ._pr-lg-4 {
    padding-right: -4px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-5 {
    margin-right: -5px; }
  ._pr-lg-5 {
    padding-right: -5px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-6 {
    margin-right: -6px; }
  ._pr-lg-6 {
    padding-right: -6px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-7 {
    margin-right: -7px; }
  ._pr-lg-7 {
    padding-right: -7px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-8 {
    margin-right: -8px; }
  ._pr-lg-8 {
    padding-right: -8px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-9 {
    margin-right: -9px; }
  ._pr-lg-9 {
    padding-right: -9px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-10 {
    margin-right: -10px; }
  ._pr-lg-10 {
    padding-right: -10px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-11 {
    margin-right: -11px; }
  ._pr-lg-11 {
    padding-right: -11px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-12 {
    margin-right: -12px; }
  ._pr-lg-12 {
    padding-right: -12px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-13 {
    margin-right: -13px; }
  ._pr-lg-13 {
    padding-right: -13px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-14 {
    margin-right: -14px; }
  ._pr-lg-14 {
    padding-right: -14px; } }

@media only screen and (max-width: 1200px) {
  ._mr-lg-15 {
    margin-right: -15px; }
  ._pr-lg-15 {
    padding-right: -15px; } }

body,
html {
  min-height: initial;
  min-width: auto;
  /*max-width: 1280px;*/
  overflow-x: hidden;
  /*margin: 0 auto;*/
  scrollbar-width: thin;
  scrollbar-color: #c4d7e9 #ebf1f7; }

.overflowauto {
  overflow: auto; }

.clear {
  clear: both; }

.font15 {
  font-size: 15px !important; }

.font16 {
  font-size: 16px !important; }

.font13 {
  font-size: 13px !important; }

.font14 {
  font-size: 14px !important; }

.font12 {
  font-size: 12px !important; }

.font18 {
  font-size: 18px !important; }

.font20 {
  font-size: 20px !important; }

.fullwidth {
  width: 100%; }

.bold {
  font-weight: bold; }

.textaligncenter {
  text-align: center; }

.textalignleft {
  text-align: left; }

.textalignright {
  text-align: right; }

.SummerSky {
  color: #49ADE6; }

.displaynone {
  display: none !important; }

.displayblock {
  display: block; }

/*sky*/
.Fiord {
  color: #4D5D68; }

.flex {
  display: flex; }

.labelcolor {
  color: #9dbcda; }

/*grey*/
.KashmirBlue {
  color: #5E7891; }

/*light blue*/
.Cinnabar {
  color: #E14733; }

/*red*/
.pink {
  background-color: #ECDAFA; }

/*solitude*/
.Solitude {
  background-color: #E3EBF3; }

/* white */
.white {
  color: #ffffff; }

/*background colours*/
.b_lightgreen {
  background-color: #B7F9EC; }

.b_pink {
  background-color: #ECDAFA; }

.b_yellow {
  background-color: #FFFCD1; }

.block {
  display: block; }

.border-red {
  border: 1px solid #c2422e; }

.bordernone {
  border: none !important; }

.rectangle {
  height: 46px;
  width: 105px;
  border: 1px solid #c9f3f5; }

u {
  text-decoration: none; }

.pointer {
  cursor: pointer; }

.subrow .tlcol-grp p {
  line-height: 22px; }

.f-right {
  float: right; }

.f-left {
  float: left; }

.p-eventnone {
  pointer-events: none; }

.relative {
  position: relative; }

.columnwidth115 {
  width: 143px;
  min-width: 143px; }

.oval {
  height: 6px;
  width: 6px;
  background-color: #df5a49;
  position: absolute;
  border-radius: 100%;
  top: -3px;
  right: -2px; }

.cell {
  width: 100%;
  display: table; }

.cell .form-label {
  display: table-cell;
  width: 71px; }

div.border-dahsed {
  border: 1px dashed #bbd7eb;
  margin: 10px 0 10px 0px; }

/*icon size*/
.icon-size-24 {
  width: 24px;
  height: 24px; }

.icon-size-22 {
  width: 22px;
  height: 22px; }

.icon-size-20 {
  width: 20px;
  height: 20px; }

.icon-size-18 {
  width: 18px;
  height: 18px; }

.icon-size-16 {
  width: 16px;
  height: 16px; }

.border-right {
  border-right: 1px solid #bbd7eb; }

.border-left {
  border-left: 1px solid #bbd7eb; }

.border-top {
  border-top: 1px solid #bbd7eb; }

.border-bottom {
  border-bottom: 1px solid #bbd7eb; }

.border-right-0 {
  border-right: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-bottom-dashed {
  border-bottom: 1px dashed #bbd7eb !important; }

.divTable.blueTable .subrow .divTableCell {
  border-bottom: 1px dashed #bbd7eb !important; }

.labeltext {
  height: 26px;
  width: 110px;
  color: #4D5D68;
  font-family: "roboto-condensed";
  font-size: 20px;
  line-height: 26px; }

.clear {
  clear: both; }

.mobileview {
  display: none; }

.btn.btn--secondary.bordernone:hover {
  color: #f78a4e; }

.w-tab-section {
  display: none; }
  .w-tab-section.active {
    display: block; }

/*scroll bar*/
.scroll-custom {
  scrollbar-width: thin;
  scrollbar-color: #c4d7e9 #ebf1f7; }

.scroll-custom::-webkit-scrollbar {
  width: 6px;
  cursor: pointer; }

.scroll-custom::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #ebf1f7; }

.scroll-custom::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c4d7e9; }

.ui-kit-label {
  display: block;
  font-size: 16px;
  line-height: 26px; }

/*angular datepicker */
.date .angular-datepicker {
  z-index: 101;
  border-radius: 5px; }
  .date .angular-datepicker .dp-day-calendar-container {
    border-radius: 5px; }
  .date .angular-datepicker .dp-picker-input {
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    color: #5e7891;
    padding: 3px 0 3px 12px;
    background: transparent;
    cursor: pointer; }
  .date .angular-datepicker .dp-calendar-nav-container {
    padding: 20px;
    border: 1px solid #9dbcda;
    border-bottom: 0;
    border-radius: 5px 5px 0px 0px; }
    .date .angular-datepicker .dp-calendar-nav-container .dp-nav-header .dp-nav-header-btn {
      background: #ebf1f7;
      font-size: 14px;
      color: #5e7891; }
    .date .angular-datepicker .dp-calendar-nav-container .dp-nav-btns-container {
      right: 14px; }
      .date .angular-datepicker .dp-calendar-nav-container .dp-nav-btns-container button {
        background: transparent; }
      .date .angular-datepicker .dp-calendar-nav-container .dp-nav-btns-container .dp-calendar-nav-left {
        background: transparent; }
        .date .angular-datepicker .dp-calendar-nav-container .dp-nav-btns-container .dp-calendar-nav-left:before {
          border-color: #49ade6;
          border-width: 1px 1px 0 0;
          height: 12px;
          width: 12px; }
      .date .angular-datepicker .dp-calendar-nav-container .dp-nav-btns-container .dp-current-location-btn {
        background: #49ade6;
        border-color: #49ade6;
        left: 3px; }
      .date .angular-datepicker .dp-calendar-nav-container .dp-nav-btns-container .dp-calendar-nav-right {
        background: transparent; }
        .date .angular-datepicker .dp-calendar-nav-container .dp-nav-btns-container .dp-calendar-nav-right:before {
          border-color: #49ade6;
          border-width: 1px 1px 0 0;
          height: 12px;
          width: 12px; }
  .date .angular-datepicker .dp-calendar-wrapper {
    border-color: #49ade6;
    border-top: 0;
    border-radius: 0px 0px 5px 5px;
    padding-bottom: 10px; }
    .date .angular-datepicker .dp-calendar-wrapper .dp-months-row .dp-calendar-month {
      background: #ebf1f7;
      color: #5e7891;
      margin: 2px;
      transition: all ease 0.3s; }
    .date .angular-datepicker .dp-calendar-wrapper .dp-months-row :hover {
      box-shadow: 0px 0px 5px 1px rgba(94, 120, 145, 0.333); }
    .date .angular-datepicker .dp-calendar-wrapper .dp-weekdays {
      border: 0 #49ade6;
      background: #ebf1f7;
      padding: 0 12px 0 12px; }
      .date .angular-datepicker .dp-calendar-wrapper .dp-weekdays .dp-calendar-weekday {
        border-left: 0 #49ade6;
        border-bottom: 0 #49ade6;
        font-size: 14px;
        width: 34px; }
    .date .angular-datepicker .dp-calendar-wrapper .dp-calendar-week {
      margin: 0 12px 0 12px; }
      .date .angular-datepicker .dp-calendar-wrapper .dp-calendar-week .dp-calendar-day {
        background: #fff;
        color: #5e7891;
        font-size: 16px;
        width: 34px;
        height: 34px; }
      .date .angular-datepicker .dp-calendar-wrapper .dp-calendar-week .dp-current-day {
        background: #6393c4;
        color: #fff;
        border-radius: 100%; }
    .date .angular-datepicker .dp-calendar-wrapper .weekhighlight .dp-calendar-day {
      background: #fff !important;
      color: #6d7c91 !important;
      border: 1px solid #6393c4;
      border-right: 0;
      border-left: 0; }
    .date .angular-datepicker .dp-calendar-wrapper .weekhighlight :first-child {
      border-top-left-radius: 15px;
      border-left: 1px solid #6393c4;
      border-bottom-left-radius: 15px; }
    .date .angular-datepicker .dp-calendar-wrapper .weekhighlight :last-child {
      border-top-right-radius: 15px;
      border-right: 1px solid #6393c4;
      border-bottom-right-radius: 15px; }
    .date .angular-datepicker .dp-calendar-wrapper .weekhighlight .dp-current-day {
      color: #f78a4e !important;
      padding: 0px;
      border: 1px solid #6393c4;
      border-radius: 0;
      border-right: 0;
      border-left: 0; }

/*popup*/
modal {
  /* modals are hidden by default */
  display: none; }
  modal .modal {
    /* modal container fixed across whole screen */
    width: calc(100% + 18px);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* z-index must be higher than .modal-background */
    z-index: 1000;
    background: rgba(94, 120, 145, 0.3);
    /* enables scrolling for tall modals */
    overflow: auto; }
    modal .modal .modal-body {
      padding: 20px;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0%);
      display: inline-block; }
  modal .modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.75;
    /* z-index must be below .modal and above everything else  */
    z-index: 900; }

body.modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden; }

.form-item-wrap--header-select .select2-container .select2-selection--single .select2-selection__arrow {
  width: 24px;
  border-left: 1px solid #49ade6; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 31px;
  position: absolute;
  top: 0px;
  right: 1px;
  width: 20px; }

.form-item-wrap--header-select
.select2-container:not(.select2-container--disabled)
.select2-selection--single:hover
.select2-selection__arrow {
  border-left-color: #9dbcda; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #9dbcda transparent transparent transparent; }

.textbox {
  scrollbar-width: thin;
  scrollbar-color: #c4d7e9 #ebf1f7; }

textarea {
  min-height: 140px;
  width: 100%;
  border: 0;
  resize: none;
  color: #5E7891;
  font-size: 16px;
  scrollbar-width: thin;
  scrollbar-color: #c4d7e9 #ebf1f7; }
  textarea:focus {
    outline: none; }

/*above for header select */
.form-item-wrap--header-select:hover {
  border-color: #9dbcda; }
  .form-item-wrap--header-select:hover .ng-arrow-wrapper {
    border-color: #9dbcda !important; }
  .form-item-wrap--header-select:hover .ng-arrow {
    border-color: #9dbcda transparent transparent !important; }

.form-item-wrap.form-item-wrap--header-select .form-item.select2 {
  padding-left: 0px;
  padding-right: 0px; }
  .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-select-container {
    min-height: 31px;
    height: 31px;
    background: transparent;
    border: 0; }
    .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-select-container .ng-input input {
      color: #fbfdfe; }
    .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-select-container .ng-value span {
      color: #fbfdfe; }
    .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-select-container .ng-clear-wrapper {
      color: transparent; }
    .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-select-container :hover .ng-clear {
      color: transparent; }
  .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-arrow-wrapper {
    border-left: 1px solid #9dbcda;
    height: 100%;
    padding-top: 5px;
    padding-left: 4px; }
    .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-arrow-wrapper .ng-arrow {
      border-color: #9dbcda transparent transparent; }
  .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-dropdown-panel {
    padding: 8px 0;
    border: 1px solid #9dbcda;
    border-radius: 5px;
    background: #fff;
    margin-top: 2px; }
    .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-dropdown-panel .ng-option {
      color: #5e7891;
      font-size: 16px;
      font-weight: normal; }
      .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-dropdown-panel .ng-option .ng-option-label {
        font-weight: normal; }
    .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-dropdown-panel .ng-option-marked {
      background-color: #ebf1f7;
      font-weight: normal; }
    .form-item-wrap.form-item-wrap--header-select .form-item.select2 .ng-dropdown-panel .ng-option-selected {
      background-color: #ebf1f7;
      font-weight: normal; }

.form-item-wrap.form-item-wrap--header-select .ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #9dbcda !important; }

/*select 2 design*/
.custom-select .ng-select {
  padding-left: 0;
  padding-right: 0; }
  .custom-select .ng-select .ng-select-container {
    border: 0;
    min-height: auto;
    height: 100%; }
    .custom-select .ng-select .ng-select-container .ng-value .ng-value-label {
      padding: 0;
      font-size: 16px;
      line-height: 22px;
      color: #5e7891; }
  .custom-select .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #9dbcda transparent transparent; }

.custom-select .ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #9dbcda;
  top: -4px; }

.custom-select .ng-dropdown-panel {
  margin-top: 4px;
  padding: 8px 0;
  border: 1px solid #9dbcda;
  border-radius: 5px;
  background: #fff;
  z-index: 2500; }
  .custom-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 8px 12px 9px;
    font-size: 16px;
    line-height: 19px;
    color: #5e7891;
    background-color: transparent; }
  .custom-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
    background-color: #ebf1f7; }
  .custom-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option-selected {
    background-color: #ebf1f7; }
  .custom-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option-marked .ng-option-label {
    color: #5e7891;
    font-weight: normal !important; }

.ui-kit-label {
  color: #5E7891;
  font-size: 15px;
  padding-bottom: 5px; }

.yellow-msg {
  color: #D7B02E;
  font-size: 15px;
  line-height: 20px;
  font-weight: normal; }

/*color shades for boxes */
.green_shade {
  background-color: #ffc1b9 !important; }

.green_shade_1 {
  background-color: #ffc7c0 !important; }

.green_shade_2 {
  background-color: #ffcdc7 !important; }

.green_shade_3 {
  background-color: #ffd3ce !important; }

.green_shade_4 {
  background-color: #ffd9d5 !important; }

.green_shade_5 {
  background-color: #ffe0dc !important; }

.green_shade_6 {
  background-color: #ffe6e3 !important; }

.green_shade_7 {
  background-color: #ffecea !important; }

.puerto_shade {
  background-color: #ffd9d4 !important; }

.puerto_shade {
  background-color: #ffdcd8 !important; }

.puerto_shade_1 {
  background-color: #ffe0dc !important; }

.puerto_shade_2 {
  background-color: #ffe4e0 !important; }

.puerto_shade_3 {
  background-color: #ffe8e5 !important; }

.puerto_shade_4 {
  background-color: #ffece9 !important; }

.puerto_shade_5 {
  background-color: #ffefed !important; }

.puerto_shade_6 {
  background-color: #fff3f2 !important; }

.puerto_shade_7 {
  background-color: #fff7f6 !important; }

.pictonblue_shade {
  background-color: #ffdeb0  !important; }

/*#5d97d1*/
.pictonblue_shade_1 {
  background-color: #ffe1b7 !important; }

.pictonblue_shade_2 {
  background-color: #ffe4bf !important; }

.pictonblue_shade_3 {
  background-color: #ffe7c7 !important; }

.pictonblue_shade_4 {
  background-color: #ffebcf !important; }

.pictonblue_shade_5 {
  background-color: #ffeed7 !important; }

.pictonblue_shade_6 {
  background-color: #fff1df !important; }

.pictonblue_shade_7 {
  background-color: #fff5e7 !important; }

.terracottared_shade {
  background-color: #fffcd1  !important; }

/*#A7473B*/
.terracottared_shade_1 {
  background-color: #fffcd5 !important; }

.terracottared_shade_2 {
  background-color: #fffcda !important; }

.terracottared_shade_3 {
  background-color: #fffcde !important; }

.terracottared_shade_4 {
  background-color: #fffde3 !important; }

.terracottared_shade_5 {
  background-color: #fffde8 !important; }

.terracottared_shade_6 {
  background-color: #fffdec !important; }

.terracottared_shade_7 {
  background-color: #fffef1 !important; }

.astral_shade {
  background-color: #d9ffd2 !important; }

.astral_shade_1 {
  background-color: #dcffd6 !important; }

.astral_shade_2 {
  background-color: #e0ffdb !important; }

.astral_shade_3 {
  background-color: #e4ffdf !important; }

.astral_shade_4 {
  background-color: #e8ffe4 !important; }

.astral_shade_5 {
  background-color: #ecffe8 !important; }

.astral_shade_6 {
  background-color: #efffed !important; }

.astral_shade_7 {
  background-color: #f3fff1 !important; }

.astral_shade_8 {
  background-color: #f7fff6 !important; }

.daisy_shade {
  background-color: #b7f9ec !important; }

.daisy_shade_1 {
  background-color: #bef9ed !important; }

.daisy_shade_2 {
  background-color: #c5faef !important; }

.daisy_shade_3 {
  background-color: #ccfaf1 !important; }

.daisy_shade_4 {
  background-color: #d3fbf3 !important; }

.daisy_shade_5 {
  background-color: #dbfcf5 !important; }

.daisy_shade_6 {
  background-color: #e2fcf7 !important; }

.daisy_shade_7 {
  background-color: #e9fdf9 !important; }

.amethyst_shade {
  background-color: #ccf2ff !important; }

.amethyst_shade_1 {
  background-color: #d1f3ff !important; }

.amethyst_shade_2 {
  background-color: #d6f4ff !important; }

.amethyst_shade_3 {
  background-color: #dbf5ff !important; }

.amethyst_shade_4 {
  background-color: #e0f7ff !important; }

.amethyst_shade_5 {
  background-color: #e5f8ff !important; }

.amethyst_shade_6 {
  background-color: #eaf9ff !important; }

.amethyst_shade_7 {
  background-color: #effbff !important; }

.flamingo_shade {
  background-color: #a7dbf7 !important; }

.flamingo_shade_1 {
  background-color: #afdef7 !important; }

.flamingo_shade_2 {
  background-color: #b8e2f8 !important; }

.flamingo_shade_3 {
  background-color: #c1e5f9 !important; }

.flamingo_shade_4 {
  background-color: #cae9fa !important; }

.flamingo_shade_5 {
  background-color: #d3edfb !important; }

.flamingo_shade_6 {
  background-color: #dbf0fb !important; }

.flamingo_shade_7 {
  background-color: #e4f4fc !important; }

.jaffa_shade {
  background-color: #c2deef !important; }

/* #E27A3F */
.jaffa_shade_1 {
  background-color: #c8e1f0 !important; }

.jaffa_shade_2 {
  background-color: #cee4f2 !important; }

.jaffa_shade_3 {
  background-color: #d4e7f3 !important; }

.jaffa_shade_4 {
  background-color: #daebf5 !important; }

.jaffa_shade_5 {
  background-color: #e0eef7 !important; }

.jaffa_shade_6 {
  background-color: #e6f1f8 !important; }

.jaffa_shade_7 {
  background-color: #ecf5fa !important; }

.festival_shade {
  background-color: #e2c0ff !important; }

.festival_shade_1 {
  background-color: #e4c6ff !important; }

.festival_shade_2 {
  background-color: #e7ccff !important; }

.festival_shade_3 {
  background-color: #ead2ff !important; }

.festival_shade_4 {
  background-color: #edd9ff !important; }

.festival_shade_5 {
  background-color: #f0dfff !important; }

.festival_shade_6 {
  background-color: #f3e5ff !important; }

.festival_shade_7 {
  background-color: #f6ecff !important; }

.gray_shade {
  background-color: #F7F6F9 !important; }

.disabled {
  opacity: 0.8;
  background-color: #bbd7eb; }

.filter-section .filter-struct .ui-kit-label {
  width: auto;
  position: absolute;
  top: 5px;
  left: 35px;
  z-index: 1;
  font-size: 16px;
  color: #5e7891; }

.filter-section .date .angular-datepicker .dp-picker-input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  color: transparent;
  padding: 3px 0 3px 12px;
  background: transparent;
  cursor: pointer; }

.ng-select .ng-clear-wrapper {
  color: transparent; }

.colortimeoff {
  color: #9688A3; }

/*toster*/
.toast-success {
  border-color: #64bf58;
  background-color: #e8f6e6;
  color: #5e7891;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="%2364BF58"><path d="M23.5 12c0 6.627-4.873 11.5-11.5 11.5S.5 18.627.5 12 5.373.5 12 .5 23.5 5.373 23.5 12z"/><path d="M4.91 11.455l5.454 5.454 8.727-8.727"/></svg>'); }

.toast-title, .toast-message {
  color: #5e7891;
  font-size: 16px;
  line-height: 19px;
  font-family: roboto-condensed;
  font-weight: 400; }

.toast-error {
  border-color: #df5a49;
  background-color: #fae7e4;
  color: #5e7891;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="%23DF5A49"><path d="M12.547 20.024a.524.524 0 1 1-1.047 0 .524.524 0 0 1 1.047 0z"/><path fill="currentColor" fill-rule="nonzero" d="M11.5 7.5h1v10h-1z"/><path stroke-linecap="round" stroke-linejoin="round" d="M11.75 0L0 23.52h23.5z"/></svg>'); }

.toast-warning {
  border-color: #efc94c;
  background-color: #fdf7e4;
  color: #5e7891;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="%23EFC94C"><path d="M23.478 11.74c0 6.482-5.256 11.738-11.739 11.738C5.256 23.478.522 18.222.522 11.74.522 5.256 5.256.522 11.739.522c6.483 0 11.74 4.734 11.74 11.217zM7.283 7.283l9.39 9.39M7.283 16.674l9.39-9.391"/></svg>'); }

.toast-info {
  background-color: #e8f6e6;
  color: #5e7891;
  background-image: url("data:image/svg + xml;charset=utf-8,%3Csvg viewBox=%220 0 24 24%22 xmlns=%22ht…z%22/%3E%3Cpath d=%22M4.91 11.455l5.454 5.454 8.727-8.727%22/%3E%3C/svg%3E"); }

/*for success msg    */
/*for scroll*/
/*Scrolling*/
.popup-quicklink::-webkit-scrollbar,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar {
  width: 4px;
  cursor: pointer; }

.popup-quicklink::-webkit-scrollbar-track,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-track {
  background-color: #ebf1f7;
  border-radius: 2px; }

.popup-quicklink::-webkit-scrollbar-thumb,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb {
  background-color: #c4d7e9;
  border-radius: 2px; }

/*tooltip*/
.tooltip {
  display: inline;
  position: relative; }

.tooltip:hover:after {
  background: #fff;
  font-size: 16px;
  border-radius: 5px;
  bottom: -44px;
  color: #4D5D68;
  content: attr(title);
  left: 20%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 200px;
  border: 1px solid #9dbcda;
  box-shadow: 0 1px 2px 0 rgba(48, 96, 145, 0.4); }

.tooltip:hover:before {
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  top: 55px;
  border-left: 1px solid #9dbcda;
  border-top: 1px solid #9dbcda;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
  display: block;
  position: absolute;
  z-index: 99;
  width: 11px;
  height: 11px;
  background: #fff; }

.mdl__body {
  padding: 20px 20px 20px; }

.popover--tooltip {
  min-width: 100px; }

.main-menu .main-menu__profile {
  position: relative;
  height: 32px;
  margin: 0 0 0 auto;
  width: 70px; }

.main-menu .main-menu__profile-menu {
  z-index: 100;
  display: none;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  margin: 0;
  max-height: 232px;
  overflow-y: auto;
  min-width: 100%;
  max-width: 280px;
  padding: 8px 0;
  border-radius: 5px;
  background-color: #fbfdfe;
  -webkit-box-shadow: 0 1px 2px 0 rgba(48, 96, 145, 0.4);
  box-shadow: 1 1px 2px 0 rgba(48, 96, 145, 0.4);
  scrollbar-width: thin;
  scrollbar-color: #c4d7e9 #ebf1f7; }

.main-menu .dropdown.active {
  display: block !important; }

.main-menu .dropdown.left {
  right: 0 !important;
  left: auto !important; }

.main-menu__profile-name + .main-menu__profile-menu-item {
  padding-left: 45px; }

.main-menu__profile-name + .main-menu__profile-menu-item .icon-svg-wrap {
  top: 4px; }

.pointer {
  cursor: pointer; }

.addshift-popup .custom-select .ng-select .ng-select-container .ng-value .ng-value-label {
  max-width: 184px;
  display: inline-flex; }

.edge .blueTable :first-child.divTableHead {
  width: 211px; }

/*.Firefox .columnwidth115 { width: 130px !important; min-width: 130px !important; }*/
/*.Firefox .divTable.blueTable .divTableHeading .divTableHead:last-child { width: 234px !important; min-width: 234px !important; }*/
.edge .divTable {
  border-collapse: separate;
  background: #BBD7EB; }

.edge .divTable.blueTable .divTableBody .divTableCell {
  background: #fff; }

/*.edge .divTable.blueTable .divTableHeading .divTableHead:last-child { width: 234px !important; min-width: 234px !important; }*/
/*.edge .columnwidth115 { width: 130px !important; min-width: 130px !important; }*/
.edge .blueTable .ml-15 {
  margin-left: 5px; }

.edge .divTable.blueTable .divTableCell,
.edge .divTable.blueTable .divTableHead {
  border: 0 !important; }

/*.Firefox .byweek td.divTableCell > div {

    min-height: 37px !important;

}*/
/* header section */
.mainmenu_right {
  display: flex;
  justify-content: flex-start;
  margin-left: auto; }
  .mainmenu_right a {
    color: #ffffff; }

/*filter section*/
.filter-section {
  background-color: #fbfdfe;
  display: inline-table;
  width: 100%;
  max-width: 100%; }
  .filter-section .ui-kit-cell {
    display: inline-block; }
  .filter-section .copyfromdrp .w-dropdown {
    width: 120px; }

/*filter section end*/
/*tooltip*/
.popover--tooltip {
  min-width: 294px; }

/*scheduled shift popup*/
.schedule-shift-popup .form-item-wrap.time.big {
  width: 180px; }

.schedule-shift-popup .form-item-wrap.time.big {
  width: 180px;
  float: left; }

.schedule-shift-popup span.timepicker-value {
  width: 57px;
  color: #5E7891;
  font-size: 16px;
  line-height: 0;
  float: right;
  padding: 8px 1px;
  text-align: right; }

.schedule-shift-popup .popup-options {
  float: left;
  width: 100%; }

.schedule-shift-popup .popup-options .div-cell {
  float: left;
  width: 74px; }

.schedule-shift-popup .popup-options .div-cell span.icon-svg-wrap {
  width: 16px;
  margin: 2px 0px 0px 0px; }

.schedule-shift-popup .popup-options .div-cell .btn-alt {
  padding: 0px 0 0px 22px;
  min-height: auto; }

.schedule-shift-popup .notes span.icon-svg-wrap {
  margin: 2px 0px 0px 0px; }

.schedule-shift-popup .div-cell.shift {
  padding: 0 0px 0px 15px; }

.schedule-shift-popup .addnotes {
  min-height: 140px;
  max-height: 120px;
  overflow: auto;
  border: 1px solid #9dbcda;
  color: #9dbcda;
  padding: 5px;
  border-radius: 5px; }

.schedule-shift-popup .mdl {
  width: 300px; }

.schedule-shift-popup .savecanceldiv {
  position: relative;
  top: -8px; }

.schedule-shift-popup .timepicker-value {
  color: #5E7891;
  float: left;
  width: 177px;
  height: 10px;
  font-size: 16px;
  line-height: initial;
  text-align: right;
  margin-top: 10px; }

.schedule-shift-popup .message {
  position: relative;
  padding: 21px 38px 20px 64px; }

.f-right.fullwidth640.newExport button.btn.btn--secondary.no-border {
  position: relative;
  left: -30px;
  background: transparent; }

.ndrp ul#dropdown-actions {
  left: 0 !important; }

.lpopup {
  z-index: 103 !important; }

.copyfromdrp-exp.copyfromdrp .w-dropdown {
  margin-left: 50px !important; }

/*media start*/
/* body_mobileview is added when width is <=414 */
.body_mobileview .mobileview .blueTable th:first-child,
.body_mobileview .mobileview .blueTable td:first-child {
  box-shadow: none; }

.body_mobileview
.mobileview
.schedule-tbl-wrpr
thead.divTableHeading
th.divTableHead
.back {
  float: left;
  position: relative;
  top: 4px; }

.body_mobileview
.mobileview
.schedule-tbl-wrpr
thead.divTableHeading
th.divTableHead
.next {
  float: right;
  position: relative;
  top: 4px; }

.body_mobileview
.mobileview
.schedule-tbl-wrpr
thead.divTableHeading
th.divTableHead
.add-plus
.icon-svg-wrap {
  position: relative;
  top: 5px;
  width: 22px;
  height: 22px; }

.body_mobileview .mobileview .divTable.blueTable .subrow .divTableCell {
  padding: 5px; }

.body_mobileview
.mobileview
.dropdown:not(.jq-selectbox):not(.multiselect-alt__menu):not(.timepicker) {
  width: 310px;
  max-width: initial; }

.body_mobileview .mobileview .footer-data {
  float: left;
  width: 50%; }

@media only screen and (max-width: 1366px) {
  .chrome
.byday
.schedule-tbl-wrpr
.divTable.blueTable
.divTableHeading
.divTableHead:last-child {
    width: 230px; }
  .safari
.byday
.schedule-tbl-wrpr
.divTable.blueTable
.divTableHeading
.divTableHead:last-child {
    width: 230px; }
  .box-white.box-height.font12 {
    font-size: 11px !important; } }

@media only screen and (max-width: 1365px) {
  /*.blueTable th:first-child, .blueTable td:first-child { box-shadow: 6px 0px 5px -1px rgba(187,215,235,0.97); }*/ }

@media (max-width: 1279px) {
  body {
    line-height: 28px; } }

@media (max-width: 959px) {
  .form-item-wrap--header-select .select2 {
    display: block !important;
    width: 100%; }
  .form-item-wrap--header-select .select2 {
    display: inline-block !important; }
  .form-item-wrap--header-select {
    margin-left: 12px;
    width: 203px; } }

@media only screen and (max-width: 890px) {
  .fullwidth640 {
    width: 100%;
    margin-top: 15px; }
  .publishdrpdwn {
    float: right; } }

@media only screen and (max-width: 414px) {
  .mobileview {
    display: block !important; }
  .desktop-view {
    display: none; }
  .footer-data {
    float: left;
    width: 50%; }
  .mobileview
.filter-section
.dropdown:not(.jq-selectbox):not(.multiselect-alt__menu):not(.timepicker) {
    width: 220px; } }
