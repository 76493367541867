.edge .blueTable :first-child.divTableHead { width: 211px; }
// .Firefox .divTable { border-collapse: separate; background: #BBD7EB; }
// .Firefox .divTable.blueTable .divTableBody .divTableCell { background: #fff; }
// .Firefox .divTable.blueTable .divTableCell,
// .Firefox .divTable.blueTable .divTableHead { border: 0 !important; }

/*.Firefox .columnwidth115 { width: 130px !important; min-width: 130px !important; }*/
// .Firefox .blueTable .tlcol-grp { min-width: 91px; }
// .Firefox .blueTable .ml-15 { margin-left: 5px; }

/*.Firefox .divTable.blueTable .divTableHeading .divTableHead:last-child { width: 234px !important; min-width: 234px !important; }*/


.edge .divTable { border-collapse: separate; background: #BBD7EB; }
.edge .divTable.blueTable .divTableBody .divTableCell { background: #fff; }
/*.edge .divTable.blueTable .divTableHeading .divTableHead:last-child { width: 234px !important; min-width: 234px !important; }*/
/*.edge .columnwidth115 { width: 130px !important; min-width: 130px !important; }*/
.edge .blueTable .ml-15 { margin-left: 5px; }   
.edge .divTable.blueTable .divTableCell, 
.edge .divTable.blueTable .divTableHead{border:0 !important;} 


/*.Firefox .byweek td.divTableCell > div {

    min-height: 37px !important;

}*/
