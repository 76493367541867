/*
    This is for margin and padding
    eg.
    .m-0 to 35{margin:0px to 35px;}  
    .p-0 to 35{padding:0px to 35px;}  

    t for top
    "": "", // for all
    t for "top",
    b for "bottom",
    l for "left",
    r for "right",

    .pt-30{padding-top: 30px;}
    .mb-30{margin-bottom: 30px;}
*/

$sides: (
"": "",
"t": "top",
"b": "bottom",
"l": "left",
"r": "right",
);

$breakpoints: (
"": "",
"xs": 576px,
"sm": 768px,
"md": 992px,
"lg": 1200px,
);

@each $breakName, $breakValue in $breakpoints {
  @each $sideName, $sideValue in $sides {
    @for $i from 0 through 50 {
      
      $property: if($sideName == '', '', -#{$sideValue});
      $space: $i * 1;
      $selector: '';

      @if $breakName != "" {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != "" {
        @media only screen and (max-width: $breakValue) {
          .m#{$selector} {
            margin#{$property}: #{$space}px;
          }
  
          .p#{$selector} {
            padding#{$property}: #{$space}px;
          }
        }
      } @else {
        .m#{$selector} {
          margin#{$property}: #{$space}px;
        }

        .p#{$selector} {
          padding#{$property}: #{$space}px;
        }
      }
    }
  }
}

/*for minus value */

$sides: (
"": "",
"t": "top",
"b": "bottom",
"l": "left",
"r": "right",
);

$breakpoints: (
"": "",
"xs": 576px,
"sm": 768px,
"md": 992px,
"lg": 1200px,
);

@each $breakName, $breakValue in $breakpoints {
  @each $sideName, $sideValue in $sides {
    @for $i from 0 through 15 {
      
      $property: if($sideName == '', '', -#{$sideValue});
      $space: $i * -1;
      $selector: '';

      @if $breakName != "" {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != "" {
        @media only screen and (max-width: $breakValue) {
          ._m#{$selector} {
            margin#{$property}: #{$space}px;
          }
  
          ._p#{$selector} {
            padding#{$property}: #{$space}px;
          }
        }
      } @else {
        ._m#{$selector} {
          margin#{$property}: #{$space}px;
        }

        ._p#{$selector} {
          padding#{$property}: #{$space}px;
        }
      }
    }
  }
}