/*font  sizes*/
$font15: 15px; 
$font16: 16px;
$font13: 13px;
$font14: 14px;     
$font12: 12px; 
$font18: 18px;     
$font20: 20px; 

/*backgroud colors*/
$background-lightgreen:#B7F9EC;
$background-pink:#ECDAFA;
$background-yellow:#FFFCD1;
$AliceBlue:#EBF1F7;


$SummerSky: #49ADE6; /*sky*/
$Fiord:#4D5D68; /*grey*/
$KashmirBlue: #5E7891; /*light blue*/
$Cinnabar: #E14733;
$white:#ffffff;
$Solitude:#E3EBF3;