/* You can add global styles to this file, and also import other style files */
@import "./variables";
@import "./mixins";
@import "./structure";
@import "./_browser";

@import "~ngx-toastr/toastr.css";

/* header section */
.mainmenu_right {
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  a {
    color: $white;
  }
}

/*filter section*/
.filter-section {
  background-color: #fbfdfe;
  display: inline-table;
  width: 100%;
  max-width: 100%;
  .ui-kit-cell {
    display: inline-block;
  }
  .copyfromdrp .w-dropdown {
    width: 120px;
  }
}
/*filter section end*/
/*tooltip*/
.popover--tooltip {
  min-width: 294px;
}
/*scheduled shift popup*/
.schedule-shift-popup {
  .form-item-wrap.time.big {
    width: 180px;
  }
  .form-item-wrap.time.big {
    width: 180px;
    float: left;
  }
  span.timepicker-value {
    width: 57px;
    color: $KashmirBlue;
    font-size: 16px;
    line-height: 0;
    float: right;
    padding: 8px 1px;
    text-align: right;
  }
  .popup-options {
    float: left;
    width: 100%;
  }
  .popup-options .div-cell {
    float: left;
    width: 74px;
  }
  .popup-options .div-cell span.icon-svg-wrap {
    width: 16px;
    margin: 2px 0px 0px 0px;
  }
  .popup-options .div-cell .btn-alt {
    padding: 0px 0 0px 22px;
    min-height: auto;
  }
  .notes span.icon-svg-wrap {
    margin: 2px 0px 0px 0px;
  }
  .div-cell.shift {
    padding: 0 0px 0px 15px;
  }
  .addnotes {
    min-height: 140px;
    max-height: 120px;
    overflow: auto;
    border: 1px solid #9dbcda;
    color: #9dbcda;
    padding: 5px;
    border-radius: 5px;
  }
  .mdl {
    width: 300px;
  }
  .savecanceldiv {
    position: relative;
    top: -8px;
  }
  .timepicker-value {
    color: $KashmirBlue;
    float: left;
    width: 177px;
    height: 10px;
    font-size: 16px;
    line-height: initial;
    text-align: right;
    margin-top: 10px;
  }
  .message {
    position: relative;
    padding: 21px 38px 20px 64px;
  }
}
.f-right.fullwidth640.newExport button.btn.btn--secondary.no-border {
  position: relative;
  left: -30px;
  background: transparent;
}
.ndrp ul#dropdown-actions {
  left: 0 !important;
}

.lpopup {
  z-index: 103 !important;
}

.copyfromdrp-exp.copyfromdrp .w-dropdown {
  margin-left: 50px !important;
}

/*media start*/
@import "./_responsive";
