﻿/* body_mobileview is added when width is <=414 */
.body_mobileview .mobileview .blueTable th:first-child,
.body_mobileview .mobileview .blueTable td:first-child {
  box-shadow: none;
}
.body_mobileview
  .mobileview
  .schedule-tbl-wrpr
  thead.divTableHeading
  th.divTableHead
  .back {
  float: left;
  position: relative;
  top: 4px;
}
.body_mobileview
  .mobileview
  .schedule-tbl-wrpr
  thead.divTableHeading
  th.divTableHead
  .next {
  float: right;
  position: relative;
  top: 4px;
}
.body_mobileview
  .mobileview
  .schedule-tbl-wrpr
  thead.divTableHeading
  th.divTableHead
  .add-plus
  .icon-svg-wrap {
  position: relative;
  top: 5px;
  width: 22px;
  height: 22px;
}
.body_mobileview .mobileview .divTable.blueTable .subrow .divTableCell {
  padding: 5px;
}
.body_mobileview
  .mobileview
  .dropdown:not(.jq-selectbox):not(.multiselect-alt__menu):not(.timepicker) {
  width: 310px;
  max-width: initial;
}
.body_mobileview .mobileview .footer-data {
  float: left;
  width: 50%;
}

@media only screen and (max-width: 1366px) {
  .chrome
    .byday
    .schedule-tbl-wrpr
    .divTable.blueTable
    .divTableHeading
    .divTableHead:last-child {
    width: 230px;
  }
  .safari
    .byday
    .schedule-tbl-wrpr
    .divTable.blueTable
    .divTableHeading
    .divTableHead:last-child {
    width: 230px;
  }
  .box-white.box-height.font12 {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 1365px) {
  /*.blueTable th:first-child, .blueTable td:first-child { box-shadow: 6px 0px 5px -1px rgba(187,215,235,0.97); }*/
}

@media (max-width: 1279px) {
  body {
    line-height: 28px;
  }
}
@media (max-width: 959px) {
  .form-item-wrap--header-select .select2 {
    display: block !important;
    width: 100%;
  }
  .form-item-wrap--header-select .select2 {
    display: inline-block !important;
  }
  .form-item-wrap--header-select {
    margin-left: 12px;
    width: 203px;
  }
}
@media only screen and (max-width: 890px) {
  .fullwidth640 {
    width: 100%;
    margin-top: 15px;
  }
  .publishdrpdwn {
    float: right;
  }
}

@media only screen and (max-width: 414px) {
  .mobileview {
    display: block !important;
  }
  .desktop-view {
    display: none;
  }
  .footer-data {
    float: left;
    width: 50%;
  }

  .mobileview
    .filter-section
    .dropdown:not(.jq-selectbox):not(.multiselect-alt__menu):not(.timepicker) {
    width: 220px;
  }
}
