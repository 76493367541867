body,
html { min-height: initial; min-width: auto; /*max-width: 1280px;*/ overflow-x: hidden; /*margin: 0 auto;*/ scrollbar-width: thin; scrollbar-color: #c4d7e9 #ebf1f7; }
.overflowauto { overflow: auto; }
.clear { clear: both; }
.font15 { font-size: $font15 !important; }
.font16 { font-size: $font16 !important; }
.font13 { font-size: $font13 !important; }
.font14 { font-size: $font14 !important; }
.font12 { font-size: $font12 !important; }
.font18 { font-size: $font18 !important; }
.font20 { font-size: $font20 !important; }

.fullwidth { width: 100%; }
.bold { font-weight: bold; }
.textaligncenter { text-align: center; }
.textalignleft { text-align: left; }
.textalignright { text-align: right; }
.SummerSky { color: $SummerSky; }
.displaynone { display: none !important; }
.displayblock { display: block; }
/*sky*/
.Fiord { color: $Fiord; }
.flex { display: flex; }
.labelcolor { color: #9dbcda; }
/*grey*/
.KashmirBlue { color: $KashmirBlue; }
/*light blue*/
.Cinnabar { color: $Cinnabar; }
/*red*/
.pink { background-color: $background-pink; }
/*solitude*/
.Solitude { background-color: $Solitude; }
/* white */

.white { color: $white; }
/*background colours*/
.b_lightgreen { background-color: $background-lightgreen; }
.b_pink { background-color: $background-pink; }
.b_yellow { background-color: $background-yellow; }

.block { display: block; }
.border-red { border: 1px solid #c2422e; }
.bordernone { border: none !important; }
.rectangle { height: 46px; width: 105px; border: 1px solid #c9f3f5; }
u { text-decoration: none; }
.pointer { cursor: pointer; }

.subrow .tlcol-grp p { line-height: 22px; }
.f-right { float: right; }
.f-left { float: left; }
.p-eventnone { pointer-events: none; }
.relative { position: relative; }
.columnwidth115 { width: 143px; min-width: 143px; }
.oval { height: 6px; width: 6px; background-color: #df5a49; position: absolute; border-radius: 100%; top: -3px; right: -2px; }
.cell { width: 100%; display: table; }
.cell .form-label { display: table-cell; width: 71px; }
div.border-dahsed { border: 1px dashed #bbd7eb; margin: 10px 0 10px 0px; }
/*icon size*/
.icon-size-24 { width: 24px; height: 24px; }
.icon-size-22 { width: 22px; height: 22px; }
.icon-size-20 { width: 20px; height: 20px; }
.icon-size-18 { width: 18px; height: 18px; }
.icon-size-16 { width: 16px; height: 16px; }
.border-right { border-right: 1px solid #bbd7eb; }
.border-left { border-left: 1px solid #bbd7eb; }
.border-top { border-top: 1px solid #bbd7eb; }
.border-bottom { border-bottom: 1px solid #bbd7eb; }

.border-right-0 { border-right: 0 !important; }
.border-left-0 { border-left: 0 !important; }
.border-top-0 { border-top: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }

.border-bottom-dashed { border-bottom: 1px dashed #bbd7eb !important; }
.divTable.blueTable .subrow .divTableCell { border-bottom: 1px dashed #bbd7eb !important; }
.labeltext { height: 26px; width: 110px; color: $Fiord; font-family: "roboto-condensed"; font-size: 20px; line-height: 26px; }
.clear { clear: both; }
.mobileview { display: none; }
.btn.btn--secondary.bordernone:hover { color: #f78a4e; }
.w-tab-section { display: none;
  &.active { display: block; }
}
/*scroll bar*/
.scroll-custom { scrollbar-width: thin; scrollbar-color: #c4d7e9 #ebf1f7; }
.scroll-custom::-webkit-scrollbar { width: 6px; cursor: pointer; }
.scroll-custom::-webkit-scrollbar-track { border-radius: 0; background-color: #ebf1f7; }
.scroll-custom::-webkit-scrollbar-thumb { border-radius: 0; background-color: #c4d7e9; }
.ui-kit-label { display: block; font-size: 16px; line-height: 26px; }

/*angular datepicker */

.date {
  .angular-datepicker { z-index: 101; border-radius: 5px;
    .dp-day-calendar-container { border-radius: 5px; }
    .dp-picker-input { width: 100%; border-radius: 5px; font-size: 16px; color: #5e7891; padding: 3px 0 3px 12px; background: transparent; cursor: pointer; }
    .dp-calendar-nav-container { padding: 20px; border: 1px solid #9dbcda; border-bottom: 0; border-radius: 5px 5px 0px 0px;
      .dp-nav-header {
        .dp-nav-header-btn { background: #ebf1f7; font-size: 14px; color: #5e7891; }
      }
      .dp-nav-btns-container { right: 14px;
        button { background: transparent; }
        .dp-calendar-nav-left { background: transparent;
          &:before { border-color: #49ade6; border-width: 1px 1px 0 0; height: 12px; width: 12px; }
        }
        .dp-current-location-btn { background: #49ade6; border-color: #49ade6; left: 3px; }
        .dp-calendar-nav-right { background: transparent;
          &:before { border-color: #49ade6; border-width: 1px 1px 0 0; height: 12px; width: 12px; }
        }
      }
    }
    .dp-calendar-wrapper { border-color: #49ade6; border-top: 0; border-radius: 0px 0px 5px 5px; padding-bottom: 10px;
      .dp-months-row {
        .dp-calendar-month { background: #ebf1f7; color: #5e7891; margin: 2px; transition: all ease 0.3s; }
        & :hover { box-shadow: 0px 0px 5px 1px rgba(94, 120, 145, 0.333); }
      }
      .dp-weekdays { border: 0 #49ade6; background: #ebf1f7; padding: 0 12px 0 12px;
        .dp-calendar-weekday { border-left: 0 #49ade6; border-bottom: 0 #49ade6; font-size: 14px; width: 34px; }
      }
      .dp-calendar-week { margin: 0 12px 0 12px;
        .dp-calendar-day { background: #fff; color: #5e7891; font-size: 16px; width: 34px; height: 34px; }
        .dp-current-day { background: #6393c4; color: #fff; border-radius: 100%; }
      }

      .weekhighlight {
        .dp-calendar-day { background: #fff !important; color: #6d7c91 !important; border: 1px solid #6393c4; border-right: 0; border-left: 0; }
        & :first-child { border-top-left-radius: 15px; border-left: 1px solid #6393c4; border-bottom-left-radius: 15px; }
        & :last-child { border-top-right-radius: 15px; border-right: 1px solid #6393c4; border-bottom-right-radius: 15px; }
        .dp-current-day { color: #f78a4e !important; padding: 0px; border: 1px solid #6393c4; border-radius: 0; border-right: 0; border-left: 0; }
      }
    }
  }
}

/*popup*/
modal { /* modals are hidden by default */ display: none;

  .modal { /* modal container fixed across whole screen */ width: calc(100% + 18px); position: fixed; top: 0; right: 0; bottom: 0; left: 0; /* z-index must be higher than .modal-background */ z-index: 1000; background: rgba(94, 120, 145, 0.3); /* enables scrolling for tall modals */ overflow: auto;

    .modal-body { padding: 20px; position: relative; left: 50%; transform: translate(-50%, 0%); display: inline-block; }
  }

  .modal-background { /* modal background fixed across whole screen */ position: fixed; top: 0; right: 0; bottom: 0; left: 0; /* semi-transparent black  */ background-color: #000; opacity: 0.75; /* z-index must be below .modal and above everything else  */ z-index: 900; }
}

body.modal-open { /* body overflow is hidden to hide main scrollbar when modal window is open */ overflow: hidden; }

.form-item-wrap--header-select .select2-container .select2-selection--single .select2-selection__arrow { width: 24px; border-left: 1px solid #49ade6; }
.select2-container--default .select2-selection--single .select2-selection__arrow { height: 31px; position: absolute; top: 0px; right: 1px; width: 20px; }
.form-item-wrap--header-select
.select2-container:not(.select2-container--disabled)
.select2-selection--single:hover
.select2-selection__arrow { border-left-color: #9dbcda; }
.select2-container--default .select2-selection--single .select2-selection__arrow b { border-color: #9dbcda transparent transparent transparent; }

.textbox { scrollbar-width: thin; scrollbar-color: #c4d7e9 #ebf1f7; }
textarea { min-height:140px; width: 100%; border: 0; resize: none; color: #5E7891; font-size: 16px; scrollbar-width: thin; scrollbar-color: #c4d7e9 #ebf1f7;
  &:focus { outline: none; }
}

/*above for header select */
@import "~@ng-select/ng-select/themes/default.theme.css";
.form-item-wrap--header-select:hover {
  .ng-arrow-wrapper { border-color: #9dbcda !important; }
  .ng-arrow { border-color: #9dbcda transparent transparent !important; }
  border-color: #9dbcda; }
.form-item-wrap.form-item-wrap--header-select {
  .form-item.select2 { padding-left: 0px; padding-right: 0px;
    .ng-select-container { min-height: 31px; height: 31px; background: transparent; border: 0;
      .ng-input {
        input { color: #fbfdfe; }
      }
      .ng-value {
        span { color: #fbfdfe; }
      }
      .ng-clear-wrapper { color: transparent; }
      & :hover .ng-clear { color: transparent; }
    }
    .ng-arrow-wrapper { border-left: 1px solid #9dbcda; height: 100%; padding-top: 5px; padding-left: 4px;
      .ng-arrow { border-color: #9dbcda transparent transparent; }
    }
    .ng-dropdown-panel { padding: 8px 0; border: 1px solid #9dbcda; border-radius: 5px; background: #fff; margin-top: 2px;
      .ng-option { color: #5e7891; font-size: 16px; font-weight: normal;
        .ng-option-label { font-weight: normal; }
      }
      & .ng-option-marked { background-color: #ebf1f7; font-weight: normal; }
      & .ng-option-selected { background-color: #ebf1f7; font-weight: normal; }
    }
  }
  & .ng-select-opened > .ng-select-container .ng-arrow { border-color: transparent transparent #9dbcda !important; }
}

/*select 2 design*/
.custom-select {
  .ng-select { padding-left: 0; padding-right: 0;
    .ng-select-container { border: 0; min-height: auto; height: 100%;

      .ng-value {
        .ng-value-label { padding: 0; font-size: 16px; line-height: 22px; color: #5e7891; }
      }
    }
    .ng-arrow-wrapper {
      .ng-arrow { border-color: #9dbcda transparent transparent; }
    }
  }
  .ng-select-opened > .ng-select-container .ng-arrow { border-color: transparent transparent #9dbcda; top: -4px; }
  .ng-dropdown-panel { margin-top: 4px; padding: 8px 0; border: 1px solid #9dbcda; border-radius: 5px; background: #fff; z-index: 2500;
    .ng-dropdown-panel-items {
      .ng-option { padding: 8px 12px 9px; font-size: 16px; line-height: 19px; color: #5e7891; background-color: transparent; }
      .ng-option:hover { background-color: #ebf1f7; }
      .ng-option-selected { background-color: #ebf1f7; }
      .ng-option-marked {
        .ng-option-label { color: #5e7891; font-weight: normal !important; }
      }
    }
  }
}


.ui-kit-label { color: #5E7891; font-size: 15px; padding-bottom: 5px; }
.yellow-msg { color: #D7B02E; font-size: 15px; line-height: 20px; font-weight: normal; }




/*color shades for boxes */
.green_shade { background-color: #ffc1b9 !important; }
.green_shade_1 { background-color: #ffc7c0 !important; }
.green_shade_2 { background-color: #ffcdc7 !important; }
.green_shade_3 { background-color: #ffd3ce!important; }
.green_shade_4 { background-color: #ffd9d5 !important; }
.green_shade_5 { background-color: #ffe0dc !important; }
.green_shade_6 { background-color: #ffe6e3 !important; }
.green_shade_7 { background-color: #ffecea !important; }



.puerto_shade { background-color: #ffd9d4 !important; }
.puerto_shade { background-color: #ffdcd8 !important; }
.puerto_shade_1 { background-color: #ffe0dc !important; }
.puerto_shade_2 { background-color: #ffe4e0 !important; }
.puerto_shade_3 { background-color: #ffe8e5 !important; }
.puerto_shade_4 { background-color: #ffece9 !important; }
.puerto_shade_5 { background-color: #ffefed !important; }
.puerto_shade_6 { background-color: #fff3f2 !important; }
.puerto_shade_7 { background-color: #fff7f6 !important; }


.pictonblue_shade {   background-color:#ffdeb0  !important; } /*#5d97d1*/
.pictonblue_shade_1 { background-color: #ffe1b7 !important; }
.pictonblue_shade_2 { background-color: #ffe4bf !important; }
.pictonblue_shade_3 { background-color: #ffe7c7 !important; }
.pictonblue_shade_4 { background-color: #ffebcf !important; }
.pictonblue_shade_5 { background-color: #ffeed7 !important; }
.pictonblue_shade_6 { background-color: #fff1df !important; }
.pictonblue_shade_7 { background-color: #fff5e7 !important; }


.terracottared_shade { background-color:#fffcd1  !important; } /*#A7473B*/
.terracottared_shade_1 { background-color: #fffcd5 !important; }
.terracottared_shade_2 { background-color: #fffcda !important; }
.terracottared_shade_3 { background-color: #fffcde !important; }
.terracottared_shade_4 { background-color: #fffde3 !important; }
.terracottared_shade_5 { background-color: #fffde8 !important; }
.terracottared_shade_6 { background-color: #fffdec !important; }
.terracottared_shade_7 { background-color: #fffef1 !important; }



.astral_shade { background-color: #d9ffd2 !important; }
.astral_shade_1 { background-color: #dcffd6 !important; }
.astral_shade_2 { background-color: #e0ffdb !important; }
.astral_shade_3 { background-color: #e4ffdf !important; }
.astral_shade_4 { background-color: #e8ffe4 !important; }
.astral_shade_5 { background-color: #ecffe8 !important; }
.astral_shade_6 { background-color: #efffed !important; }
.astral_shade_7 { background-color: #f3fff1 !important; }
.astral_shade_8 { background-color: #f7fff6 !important; }


.daisy_shade { background-color: #b7f9ec !important; }
.daisy_shade_1 { background-color: #bef9ed !important; }
.daisy_shade_2 { background-color: #c5faef !important; }
.daisy_shade_3 { background-color: #ccfaf1 !important; }
.daisy_shade_4 { background-color: #d3fbf3 !important; }
.daisy_shade_5 { background-color: #dbfcf5 !important; }
.daisy_shade_6 { background-color: #e2fcf7 !important; }
.daisy_shade_7 { background-color: #e9fdf9 !important; }


.amethyst_shade { background-color: #ccf2ff !important; }
.amethyst_shade_1 { background-color: #d1f3ff !important; }
.amethyst_shade_2 { background-color: #d6f4ff !important; }
.amethyst_shade_3 { background-color: #dbf5ff !important; }
.amethyst_shade_4 { background-color: #e0f7ff !important; }
.amethyst_shade_5 { background-color: #e5f8ff !important; }
.amethyst_shade_6 { background-color: #eaf9ff !important; }
.amethyst_shade_7 { background-color: #effbff !important; }


.flamingo_shade { background-color: #a7dbf7 !important; }
.flamingo_shade_1 { background-color: #afdef7 !important; }
.flamingo_shade_2 { background-color: #b8e2f8 !important; }
.flamingo_shade_3 { background-color: #c1e5f9 !important; }
.flamingo_shade_4 { background-color: #cae9fa !important; }
.flamingo_shade_5 { background-color: #d3edfb !important; }
.flamingo_shade_6 { background-color: #dbf0fb !important; }
.flamingo_shade_7 { background-color: #e4f4fc !important; }


.jaffa_shade { background-color:#c2deef !important; }  /* #E27A3F */
.jaffa_shade_1 { background-color: #c8e1f0 !important; }
.jaffa_shade_2 { background-color: #cee4f2 !important; }
.jaffa_shade_3 { background-color: #d4e7f3 !important; }
.jaffa_shade_4 { background-color: #daebf5 !important; }
.jaffa_shade_5 { background-color: #e0eef7 !important; }
.jaffa_shade_6 { background-color: #e6f1f8 !important; }
.jaffa_shade_7 { background-color: #ecf5fa !important; }


.festival_shade { background-color: #e2c0ff !important; }
.festival_shade_1 { background-color: #e4c6ff !important; }
.festival_shade_2 { background-color: #e7ccff !important; }
.festival_shade_3 { background-color: #ead2ff !important; }
.festival_shade_4 { background-color: #edd9ff !important; }
.festival_shade_5 { background-color: #f0dfff !important; }
.festival_shade_6 { background-color: #f3e5ff !important; }
.festival_shade_7 { background-color: #f6ecff !important; }


.gray_shade { background-color: #F7F6F9 !important; }
// .white_shade { background-color: #ffffff !important; }

.disabled{
  opacity: 0.8;
  background-color: #bbd7eb;
}
.filter-section {

  .filter-struct .ui-kit-label { width: auto; position: absolute; top: 5px; left: 35px; z-index: 1; font-size: 16px; color: #5e7891; }
  .date .angular-datepicker .dp-picker-input { width: 100%; border-radius: 5px; font-size: 16px; color: transparent; padding: 3px 0 3px 12px; background: transparent; cursor: pointer; }
}

.ng-select .ng-clear-wrapper{color:transparent;}
.colortimeoff { color: #9688A3; }


/*toster*/
.toast-success { border-color: #64bf58; background-color: #e8f6e6; color: #5e7891; background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="%2364BF58"><path d="M23.5 12c0 6.627-4.873 11.5-11.5 11.5S.5 18.627.5 12 5.373.5 12 .5 23.5 5.373 23.5 12z"/><path d="M4.91 11.455l5.454 5.454 8.727-8.727"/></svg>'); }
.toast-title, .toast-message { color: #5e7891; font-size: 16px; line-height: 19px; font-family: roboto-condensed; font-weight: 400; }

.toast-error { border-color: #df5a49; background-color: #fae7e4; color: #5e7891; background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="%23DF5A49"><path d="M12.547 20.024a.524.524 0 1 1-1.047 0 .524.524 0 0 1 1.047 0z"/><path fill="currentColor" fill-rule="nonzero" d="M11.5 7.5h1v10h-1z"/><path stroke-linecap="round" stroke-linejoin="round" d="M11.75 0L0 23.52h23.5z"/></svg>'); }
.toast-warning { border-color: #efc94c; background-color: #fdf7e4; color: #5e7891; background-image: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="%23EFC94C"><path d="M23.478 11.74c0 6.482-5.256 11.738-11.739 11.738C5.256 23.478.522 18.222.522 11.74.522 5.256 5.256.522 11.739.522c6.483 0 11.74 4.734 11.74 11.217zM7.283 7.283l9.39 9.39M7.283 16.674l9.39-9.391"/></svg>'); }
.toast-info { background-color: #e8f6e6; color: #5e7891; background-image: url('data:image/svg + xml;charset=utf-8,%3Csvg viewBox=%220 0 24 24%22 xmlns=%22ht…z%22/%3E%3Cpath d=%22M4.91 11.455l5.454 5.454 8.727-8.727%22/%3E%3C/svg%3E'); }



/*for success msg    */
/*for scroll*/
/*Scrolling*/
.popup-quicklink::-webkit-scrollbar,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}

.popup-quicklink::-webkit-scrollbar-track,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-track {
  background-color: #ebf1f7;
  border-radius: 2px;
}

.popup-quicklink::-webkit-scrollbar-thumb,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb {
  background-color: #c4d7e9;
  border-radius: 2px;
}

/*tooltip*/
.tooltip {
  display: inline;
  position: relative;
}

.tooltip:hover:after {
  background:#fff;
font-size: 16px;
  border-radius: 5px;
  bottom: -44px;
  color: #4D5D68;
  content: attr(title);
  left: 20%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 200px;
  border:1px solid #9dbcda;
  box-shadow: 0 1px 2px 0 rgba(48,96,145,0.4);
}

.tooltip:hover:before {
 
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  top: 55px;
  border-left: 1px solid #9dbcda;
  border-top: 1px solid #9dbcda;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
    display: block;
    position: absolute;
    z-index: 99;
    width: 11px;
    height: 11px;
    background: #fff;
}


.mdl__body {
  padding: 20px 20px 20px;
}
.popover--tooltip {
  min-width: 100px;
}


.main-menu .main-menu__profile {
  position: relative;
  height: 32px;
  margin: 0 0 0 auto;
  width: 70px;
}

.main-menu .main-menu__profile-menu {
  z-index: 100;
  display: none;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  margin: 0;
  max-height: 232px;
  overflow-y: auto;
  min-width: 100%;
  max-width: 280px;
  padding: 8px 0;
  border-radius: 5px;
  background-color: #fbfdfe;
  -webkit-box-shadow: 0 1px 2px 0 rgba(48,96,145,.4);
  box-shadow: 1 1px 2px 0 rgba(48,96,145,.4);
  scrollbar-width: thin;
  scrollbar-color: #c4d7e9 #ebf1f7;
}
.main-menu .dropdown.active {
  display: block!important;
}
.main-menu .dropdown.left {
  right: 0!important;
  left: auto!important;
}



.main-menu__profile-name+.main-menu__profile-menu-item{
  padding-left: 45px;
}

.main-menu__profile-name + .main-menu__profile-menu-item .icon-svg-wrap{
  top: 4px;
}

.pointer{cursor: pointer;}
.addshift-popup .custom-select .ng-select .ng-select-container .ng-value .ng-value-label{    max-width: 184px;    display: inline-flex;}
